import React, { useEffect, useState, Suspense } from 'react';
import { graphql, useQueryLoader } from 'react-relay';
import track from 'react-tracking';

import styles from './AdminCreativeLibrary.pcss';
import Creatives from './Creatives/Creatives';
import AdminCreativesFilters, { Filters } from './AdminCreativesFilters/AdminCreativesFilters';

import Page from 'Templates/Page/Page';
import Text from 'Components/ui/Text/Text';
import Spinner from 'Atoms/Spinner/Spinner';

import {
  Currency,
  AdminCreativeLibraryQuery as QueryType,
} from 'GraphTypes/AdminCreativeLibraryQuery.graphql';
import { AdminCreativeLibraryContainerQuery$data } from 'GraphTypes/AdminCreativeLibraryContainerQuery.graphql';

export const AdminCreativeLibraryQuery = graphql`
  query AdminCreativeLibraryQuery($filter: CreativesFilter!) {
    ...Creatives_adminCreatives @arguments(filter: $filter)
  }
`;

const defaultState = {
  textQuery: '',
  currencies: [],
  brandCategoryIds: [],
  adminFavoriteCreatives: null,
  adminFavoriteCampaigns: null,
  paidSocial: null,
  platform: null,
  fileType: null,
  campaignUseCases: [],
};

interface Props {
  brandCategories: AdminCreativeLibraryContainerQuery$data['brandCategories'];
  currency?: Currency;
}

const AdminCreativeLibrary: React.FC<Props> = (props) => {
  const currency = props.currency;
  const brandCategories = props.brandCategories;

  const defaultFilter = {
    ...defaultState,
    currencies: currency ? [currency] : [],
  };
  const [filter, setFilter] = useState<Filters>(defaultFilter);

  const [queryReference, loadQuery] = useQueryLoader<QueryType>(AdminCreativeLibraryQuery);

  useEffect(() => {
    loadQuery({ filter }, { fetchPolicy: 'network-only' });
  }, [filter]);

  const handleClearParams = () => {
    setFilter(defaultState);
  };

  return (
    <Page className={styles.root}>
      <div className={styles.container}>
        <Text type="d2" msg="admin_creatives_library.title" className={styles.title} />
        <AdminCreativesFilters
          filters={filter}
          brandCategories={brandCategories}
          onFiltersChange={setFilter}
          onFiltersClear={handleClearParams}
        />
        <Suspense fallback={<Spinner className={styles.preloader} />}>
          {queryReference && (
            <Creatives admin={true} filter={filter} queryReference={queryReference} />
          )}
        </Suspense>
      </div>
    </Page>
  );
};

export default track(
  {
    page: 'admin_creative_library',
  },
  { dispatchOnMount: true }
)(AdminCreativeLibrary);
