import React, { useState } from 'react';

import styles from './MetaAdsPromo.pcss';
import MetaAdsPromoContent from './MetaAdsPromoContent';

import { useIndicateInterestInUseCaseMutation } from 'Mutations/IndicateInterestInUseCase.Mutation';
import { amplitude } from 'Helpers/amplitude';
import { useCampaignActions } from 'Hooks/useCampaignActions';
import Drawer from 'Components/ui/Drawer/Drawer';
import Button from 'Components/ui/Button/Button';

interface Props {
  creatorId?: string;
}

const MetaAdsPromo: React.FC<Props> = (props) => {
  const { creatorId } = props;
  const [showBookCall, setShowBookCall] = useState(false);

  const { createNewCampaign } = useCampaignActions();

  const [indicateInterestInUseCase] = useIndicateInterestInUseCaseMutation();

  const handleLetsTryClick = () => {
    amplitude.sendEvent<450>({
      id: '450',
      category: 'meta_ads_promo_drawer',
      name: 'lets_try_click',
      param: undefined,
    });
    indicateInterestInUseCase({
      variables: {
        input: {
          source: creatorId ? 'PROJECT_COMPLETION' : 'DASHBOARD_BANNER',
          button: 'FILL_BRIEF_BUTTON',
          useCase: 'META_PARTNERSHIP_ADS',
          creatorId,
        },
      },
    });
    createNewCampaign({
      campaignData: {
        useCase: 'META_PARTNERSHIP_ADS',
        postingType: 'CREATOR_ADS',
        type: 'INFLUENCER',
        objective: 'REACH',
      },
    });
  };

  const handeBookCallClick = () => {
    amplitude.sendEvent<451>({
      id: '451',
      category: 'meta_ads_promo_drawer',
      name: 'book_call_click',
      param: undefined,
    });
    indicateInterestInUseCase({
      variables: {
        input: {
          source: creatorId ? 'PROJECT_COMPLETION' : 'DASHBOARD_BANNER',
          button: 'BOOK_CALL_BUTTON',
          useCase: 'META_PARTNERSHIP_ADS',
          creatorId,
        },
      },
    });
    setShowBookCall(true);
  };

  const drawerPostfix = creatorId ? `-${creatorId}` : '';

  const handleClose = () => {
    setShowBookCall(false);
  };

  const handleOpen = () => {
    if (creatorId) {
      amplitude.sendEvent<456>({
        id: '456',
        category: 'meta_ads_promo_drawer',
        name: 'with_creator_show',
        param: undefined,
      });
    }
  };

  return (
    <Drawer
      rootKey={`meta-ads-promo${drawerPostfix}`}
      className={styles.drawer}
      onClose={handleClose}
      onOpen={handleOpen}
    >
      <div className={styles.drawerContainer}>
        <div className={styles.content}>
          <MetaAdsPromoContent
            creatorId={creatorId}
            showBookCall={showBookCall}
            onBookCallClick={handeBookCallClick}
          />
        </div>
        <div className={styles.buttons}>
          <Button
            fluid
            color="white"
            msg="creator_ads_meta_info.lets_try"
            onClick={handleLetsTryClick}
          />
          <Button
            fluid
            color="black"
            msg="creator_ads_meta_info.book_call"
            onClick={handeBookCallClick}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default MetaAdsPromo;
