import React, { useState, useContext } from 'react';

import styles from './CancelSubscription.pcss';

import { useCancelSubscriptionMutation } from 'Mutations/CancelSubscription.Mutation';
import Drawer from 'Components/ui/Drawer/Drawer';
import Text from 'Components/ui/Text/Text';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import Button from 'Components/ui/Button/Button';

interface Props {
  subscriptionId: string;
}

const CancelSubscription: React.FC<Props> = (props) => {
  const subscriptionId = props.subscriptionId;
  const [cancelSubscription, loading] = useCancelSubscriptionMutation();

  const [error, setError] = useState('');

  const { closeDrawer } = useContext(DrawerContext);

  const handleCancelSuccess = () => {
    closeDrawer('cancel-organization-subscription');
  };

  const handleClose = () => {
    setError('');
  };

  const handleClick = () => {
    setError('');
    cancelSubscription({
      variables: {
        input: {
          subscriptionId,
        },
      },
      onCompleted: (_, errors) => {
        if (!errors) {
          handleCancelSuccess();
        }
        if (errors) {
          const errosList = errors.map((item) => item.message);
          setError(errosList.join(','));
        }
      },
      onError: () => {
        setError('Something went wrong');
      },
    });
  };

  return (
    <Drawer
      rootKey="cancel-organization-subscription"
      className={styles.drawer}
      onClose={handleClose}
    >
      <div className={styles.drawerContainer}>
        <div className={styles.content}>
          <Text type="d2" msg="cancel_subscription_modal.title" className={styles.title} />
          <Text type="md" msg="cancel_subscription_modal.descr" />
        </div>
        {error && <Text type="md" text={error} className={styles.error} />}
        <Button
          color="black"
          loading={loading}
          msg="cancel_subscription_modal.btn"
          onClick={handleClick}
        />
      </div>
    </Drawer>
  );
};

export default CancelSubscription;
