import { ControlItem } from '../components/SegmentedControls/SegmentedControls';

import { lightWebsiteUrl } from 'Util/validate';
import { ProductDetails_campaign$data } from 'GraphTypes/ProductDetails_campaign.graphql';
import { PRODUCT_SEEDING } from 'Constants/general';
import compact from 'lodash/compact';

export const getDeliveryTypesList = (data: {
  campaignId: string;
  campaignType: ProductDetails_campaign$data['type'];
}): ControlItem[] => {
  const { campaignId, campaignType } = data;

  const result: ControlItem[] = [
    {
      id: 'COLLABORATION_WILL_NOT_REQUIRE_PRODUCT_SHIPMENT',
      value: 'COLLABORATION_WILL_NOT_REQUIRE_PRODUCT_SHIPMENT',
      titleMsg: 'brief_template.field.product.delivery.not_required',
      tooltipMsg: 'brief_template.field.product.delivery.not_required.tooltip',
      dataTest: 'button:productDelivery:notRequired',
      tooltipEventData: {
        id: '74',
        category: 'brief',
        name: 'campaign_product_delivery_tooltip_show',
        param: { info: 'Not required', campaignId },
      },
    },
    {
      id: 'BRAND_WILL_HANDLE_SHIPMENT_TO_CREATORS',
      value: 'BRAND_WILL_HANDLE_SHIPMENT_TO_CREATORS',
      titleMsg: 'brief_template.field.product.delivery.shipment',
      tooltipMsg: 'brief_template.field.product.delivery.shipment.tooltip',
      dataTest: 'button:productDelivery:shipment',
      buttonTooltipMsg: 'brief_template.field.campaign_type.shipment.tooltip',
      tooltipEventData: {
        id: '74',
        category: 'brief',
        name: 'campaign_product_delivery_tooltip_show',
        param: { info: 'Shipment', campaignId },
      },
    },
  ];

  if (campaignType !== PRODUCT_SEEDING) {
    result.push({
      id: 'BRAND_WILL_REFUND_THE_PURCHASED_PRODUCT',
      value: 'BRAND_WILL_REFUND_THE_PURCHASED_PRODUCT',
      titleMsg: 'brief_template.field.product.delivery.reimbursement',
      tooltipMsg: 'brief_template.field.product.delivery.reimbursement.tooltip',
      dataTest: 'button:productDelivery:reimbursement',
      tooltipEventData: {
        id: '74',
        category: 'brief',
        name: 'campaign_product_delivery_tooltip_show',
        param: { info: 'Reimbursment', campaignId },
      },
    });
  }

  return result;
};

type ValidationParamsType = {
  campaign: ProductDetails_campaign$data;
  tiktokShopAvailable?: boolean;
};
export const checkValidation = (data: ValidationParamsType): string[] => {
  const { cover, brief, type } = data.campaign;

  const isProductUrlValid = brief?.productUrl && lightWebsiteUrl(brief.productUrl);

  const isProductPriceMandatory =
    !brief?.submitted && (type === 'PRODUCT_SEEDING' || brief?.includeReferralFee);
  const isProductPriceValid = isProductPriceMandatory ? brief?.productPrice : true;

  const errors = compact([
    cover?.transformations?.collageThumbnailUrl ? undefined : 'cover',
    brief?.productDelivery ? undefined : 'productDelivery',
    brief?.productTitle ? undefined : 'productTitle',
    brief?.productDescription ? undefined : 'productDescription',
    isProductUrlValid ? undefined : 'productUrl',
    isProductPriceValid ? undefined : 'productPrice',
  ]);

  return errors;
};

type ConditionType = {
  campaign: ProductDetails_campaign$data;
  tiktokShopAvailable?: boolean;
};

export const getViewConditions = ({ campaign, tiktokShopAvailable }: ConditionType) => {
  const needToShowTTShop = campaign.useCase === 'TIKTOK_SHOP' && tiktokShopAvailable;
  const tiktokShopVisible =
    campaign.cover && needToShowTTShop && !campaign.brief?.tiktokShop_ShopId;
  const deliveryVisible = campaign.cover;
  const productNameVisible =
    deliveryVisible &&
    campaign.brief?.productDelivery &&
    (needToShowTTShop ? campaign.brief?.tiktokShop_ShopId : true);
  const descriptionVisible = campaign.brief?.productDescription
    ? deliveryVisible && campaign.brief?.productDelivery
    : productNameVisible && campaign.brief?.productTitle;
  const productUrlVisible = descriptionVisible && campaign.brief?.productDescription;
  const productPriceVisible = productUrlVisible && campaign.brief?.productUrl;
  const feeVisible =
    productPriceVisible &&
    campaign.brief?.includeReferralFee &&
    campaign.useCase === 'TIKTOK_SHOP' &&
    tiktokShopAvailable;

  return {
    delivery: !!deliveryVisible,
    ttShop: tiktokShopVisible,
    productName: !!productNameVisible,
    description: !!descriptionVisible,
    productUrl: !!productUrlVisible,
    productPrice: !!productPriceVisible,
    fee: !!feeVisible,
  };
};
