import React, { PropsWithChildren, useContext, useState } from 'react';
import { parse, ParseResult } from 'papaparse';

import {
  InviteOutreachCreatorsContextType,
  InviteOutreachCreatorsContext,
  InviteOutreachImportCsvDataRow,
  InviteOutreachTemplateData,
  InviteOutreachImportCsvDataErrorRow,
  InviteOutreachImportCsvDataRowsMetrics,
  InviteOutreachTemplateItemData,
  InviteOutreachCreatorsError,
  InviteOutreachCreatorsSource,
  InviteOutreachCreatorsSelectedLists,
} from './InviteOutreachCreatorsContext';
import { checkEmailValid } from 'Util/validate';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { CampaignPlatform } from 'GraphTypes/CampaignOutreachContentQuery.graphql';
import { useCreateOutreachBatchMutation } from 'Api/mutations/CreateOutreachBatch.Mutation';
import { OutreachTransport } from 'GraphTypes/CreateOutreachBatchMutation.graphql';

type Props = {
  handleUploadCsv: () => void;
};

const supportedPlatformList: string[] = ['INSTAGRAM', 'TIKTOK'];

const InviteOutreachCreatorsProvider: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, handleUploadCsv } = props;
  const [createOutreachBatch, createOutreachBatchLoading] = useCreateOutreachBatchMutation();

  const { closeDrawer } = useContext(DrawerContext);

  const [source, setSource] = useState<InviteOutreachCreatorsSource | null>(
    InviteOutreachCreatorsSource.LISTS
  );
  const [rows, setRows] = useState<InviteOutreachImportCsvDataRow[]>([]);
  const [fileError, setFileError] = useState('');
  const [fileName, setFilename] = useState('');
  const [fileSize, setFileSize] = useState(0);
  const [locale, setLocale] = useState('EN');
  const [platform, setPlatform] = useState<CampaignPlatform>('INSTAGRAM');
  const [selectedLists, setSelectedLists] = useState<InviteOutreachCreatorsSelectedLists>([]);
  const [templateData, setTemplateData] = useState<InviteOutreachTemplateData>({
    id: '',
    name: '',
  });
  const [isSequenceEditing, setSequenceEditorStatus] = useState(false);
  const [sequenceId, setSequenceId] = useState<string | null>(null);
  const [templatePreview, setTemplatePreview] = useState<InviteOutreachTemplateItemData | null>(
    null
  );
  const [templateEditor, setTemplateEditorStatus] = useState(false);
  const [transport, setTransportProvider] = useState<OutreachTransport>('GMAIL');
  const [batchId, setBatchId] = useState('');
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState<InviteOutreachCreatorsError | null>(null);

  const [senderName, setSenderName] = useState('');
  const [senderActiveAuth, setSenderActiveAuth] = useState('');
  const [senderEmail, setSenderEmail] = useState('');
  const [showRowErrors, setShowRowErrors] = useState(false);
  const [rowMetrics, setRowMetrics] = useState<InviteOutreachImportCsvDataRowsMetrics | undefined>(
    undefined
  );

  const [rowErrors, setRowErrors] = useState<InviteOutreachImportCsvDataErrorRow[]>([]);

  const isUserNeedToChangePlatform = !supportedPlatformList.includes(platform as string);

  const handleParseComplete = ({ data, errors }: ParseResult<string>) => {
    if (errors.length !== 0 || data.length === 0) {
      return;
    }

    const rows: InviteOutreachImportCsvDataRow[] = [];
    const rowErrors: InviteOutreachImportCsvDataErrorRow[] = [];

    let totalCount = 0;
    let parsedCount = 0;

    data.forEach((item, index) => {
      if (item.length === 1 && item[0] === '' && data.length < 2) {
        setFileError('invite_outreach_creators_modal.file.invalid');
        return;
      }
      const itemData = item[0].split(/[,;| ]/);
      const username = itemData[0]?.trim();
      const email = itemData[1]?.trim();
      if (username && email && checkEmailValid(email)) {
        parsedCount++;
        rows.push({ username, email });
      } else {
        rowErrors.push({ index: index + 1, email, username });
      }
      totalCount++;
    });

    setRowMetrics({ totalCount, parsedCount });

    setRowErrors(rowErrors);
    setRows(rows);
  };

  const handleListChange = (files: FileList | null) => {
    if (!files) return;

    setFileError('');
    setFilename(files[0].name);
    setFileSize(files[0].size);

    parse(files[0], {
      delimiter: 'tab',
      complete: handleParseComplete,
    });
  };

  const handleFileReset = () => {
    setFilename('');
    setFileSize(0);
    setFileError('');
    setRows([]);
    setRowMetrics(undefined);
    setRowErrors([]);
    setTemplateData({
      id: '',
      name: '',
    });
    setTemplatePreview(null);
    setBatchId('');
    setProcessing(false);
    setError(null);
  };

  const handleTemplateClose = () => {
    setTemplatePreview(null);
  };

  const handleImportOutreachBatch = (campaignId: string) => {
    setError(null);
    setProcessing(true);
    if (!sequenceId) {
      setError(InviteOutreachCreatorsError.GENERAL_ERROR);
      return;
    }
    if (source === InviteOutreachCreatorsSource.LISTS) {
      const filterredLists = selectedLists.filter((item) => item !== 'favorites');
      // const isFavoritesUsed = selectedLists.includes('favorites');
      createOutreachBatch({
        variables: {
          input: {
            campaignId,
            customListId: filterredLists[0],
            activateAfterCompletion: true,
            sequenceBlueprintId: sequenceId,
            transport,
            ...(transport === 'GMAIL'
              ? {
                  gmailAuthorizationId: senderActiveAuth,
                  senderEmail,
                  senderName,
                }
              : {}),
          },
        },
        onError: () => {
          setProcessing(false);
          setError(InviteOutreachCreatorsError.GENERAL_ERROR);
        },
        onCompleted: (response) => {
          if (response.createOutreachBatch?.__typename === 'CreateOutreachBatchPayload') {
            handleUploadCsv();
          } else {
            setProcessing(false);
            setError(InviteOutreachCreatorsError.GENERAL_ERROR);
          }
        },
      });
    }
  };

  const checkFormValidation = () => {
    let firstStepValidation = false;
    const templateValidation = !!sequenceId;
    const transportValidation =
      transport === 'SENDGRID' || (senderEmail && senderName && transport === 'GMAIL');
    if (source === InviteOutreachCreatorsSource.LISTS) {
      firstStepValidation = selectedLists.length > 0;
    }
    return Boolean(
      firstStepValidation &&
        templateValidation &&
        transportValidation &&
        locale &&
        !isUserNeedToChangePlatform
    );
  };

  const isFormValid = checkFormValidation();

  const contextValue: InviteOutreachCreatorsContextType = {
    fileName,
    fileSize,
    fileError,
    locale,
    rows,
    templateData,
    transport,
    batchId,
    error,
    senderName,
    senderEmail,
    templatePreview,
    templateEditor,
    setTransportProvider,
    handleFileReset,
    handleListChange,
    handleTemplateClose,
    handleImportOutreachBatch,
    handleParseComplete,
    setTemplatePreview,
    setTemplateData,
    setSenderName,
    setSenderActiveAuth,
    setSenderEmail,
    setShowRowErrors,
    setError,
    setTemplateEditorStatus,
    isFormValid,
    processing,
    showRowErrors,
    rowMetrics,
    rowErrors,
    setSource,
    source,
    setSelectedLists,
    selectedLists,
    platform,
    setPlatform,
    supportedPlatformList,
    isUserNeedToChangePlatform,
    isSequenceEditing,
    setSequenceEditorStatus,
    sequenceId,
    setSequenceId,
    createOutreachBatchLoading,
  };
  return (
    <InviteOutreachCreatorsContext.Provider value={contextValue}>
      {children}
    </InviteOutreachCreatorsContext.Provider>
  );
};

export { InviteOutreachCreatorsProvider };
