/**
 * @generated SignedSource<<c8e90a1eef54a283f4edff0c02c572e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignPlatform = "FACEBOOK" | "INSTAGRAM" | "OTHER" | "SNAPCHAT" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type CampaignPostingType = "CREATOR_ADS" | "ORGANIC_POSTING" | "%future added value";
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CreatorsDetails_campaign$data = {
  readonly brief: {
    readonly __typename: string;
    readonly id: string;
    readonly screeningQuestion?: string | null | undefined;
    readonly submitted?: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"ScreeningQuestions_brief">;
  } | null | undefined;
  readonly id: string;
  readonly inviteCustomLists: ReadonlyArray<{
    readonly id: string;
  }> | null | undefined;
  readonly inviteFavorites: boolean | null | undefined;
  readonly organization: {
    readonly customLists: {
      readonly " $fragmentSpreads": FragmentRefs<"CustomLists_customLists">;
    } | null | undefined;
    readonly favoriteCreators: {
      readonly " $fragmentSpreads": FragmentRefs<"CustomLists_Creator">;
    } | null | undefined;
  } | null | undefined;
  readonly platform: CampaignPlatform | null | undefined;
  readonly postingType: CampaignPostingType | null | undefined;
  readonly private: boolean | null | undefined;
  readonly searchQuery: {
    readonly engagementRateFrom: number | null | undefined;
    readonly followersFrom: number | null | undefined;
    readonly id: string;
    readonly viewsMedianFrom: number | null | undefined;
  } | null | undefined;
  readonly type: CampaignType | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"CreatorsExclusion_campaign" | "Lookalikes_campaign" | "MediaplanDetails_campaign">;
  readonly " $fragmentType": "CreatorsDetails_campaign";
};
export type CreatorsDetails_campaign$key = {
  readonly " $data"?: CreatorsDetails_campaign$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreatorsDetails_campaign">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreatorsDetails_campaign",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postingType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "private",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inviteFavorites",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomList",
      "kind": "LinkedField",
      "name": "inviteCustomLists",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "brief",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ScreeningQuestions_brief"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "submitted",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "screeningQuestion",
              "storageKey": null
            }
          ],
          "type": "V2Brief",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomListConnection",
          "kind": "LinkedField",
          "name": "customLists",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CustomLists_customLists"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 3
            }
          ],
          "concreteType": "CreatorConnection",
          "kind": "LinkedField",
          "name": "favoriteCreators",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CustomLists_Creator"
            }
          ],
          "storageKey": "favoriteCreators(first:3)"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchQuery",
      "kind": "LinkedField",
      "name": "searchQuery",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "followersFrom",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "engagementRateFrom",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewsMedianFrom",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MediaplanDetails_campaign"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreatorsExclusion_campaign"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Lookalikes_campaign"
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};
})();

(node as any).hash = "3fcf6ac07589c650f530e65740d806a9";

export default node;
