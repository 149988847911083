import { CreateOutreachSequenceBlueprintMutation } from 'GraphTypes/CreateOutreachSequenceBlueprintMutation.graphql';
import { ConnectionHandler, graphql, useMutation, UseMutationConfig } from 'react-relay';
import { v4 as uuid } from 'uuid';

import type { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const CreateOutreachSequenceBlueprintMutation = graphql`
  mutation CreateOutreachSequenceBlueprintMutation($input: CreateOutreachSequenceBlueprintInput!) {
    createOutreachSequenceBlueprint(input: $input) {
      __typename
      ... on CreateOutreachSequenceBlueprintPayload {
        outreachSequenceBlueprint {
          id
          name
          steps {
            edges {
              node {
                id
                startsAtHour
                delayInDays
                projectEventType
                template {
                  id
                }
                sequence {
                  id
                }
              }
            }
          }
        }
      }
      ... on AlreadyExists {
        __typename
      }
    }
  }
`;

export const useCreateOutreachSequenceBlueprintMutation = (
  organizationId: string,
  config?: UseMutationConfig<CreateOutreachSequenceBlueprintMutation>
) => {
  const [createOutreachSequenceBlueprint, loading] =
    useMutation<CreateOutreachSequenceBlueprintMutation>(CreateOutreachSequenceBlueprintMutation);

  const createOutreachSequenceBlueprintCaller: BaseMutationExecFn<
    CreateOutreachSequenceBlueprintMutation
  > = (execConfig) => {
    if (!execConfig) return;
    const newTemplateId = uuid();
    createOutreachSequenceBlueprint({
      ...config,
      ...execConfig,
      variables: {
        input: {
          id: newTemplateId,
          ...execConfig.variables.input,
        },
      },
      updater: (store) => {
        const connection = store.get(`client:${organizationId}:outreachSequenceBlueprints`);
        const payload = store.getRootField('createOutreachSequenceBlueprint');
        const serverEdge = payload.getLinkedRecord('outreachSequenceBlueprint');
        const edgeId = serverEdge?.getValue('id');
        if (edgeId) {
          const id = store.get(edgeId as string);
          if (id && connection && serverEdge) {
            const newEdge = ConnectionHandler.createEdge(
              store,
              connection,
              serverEdge,
              'OutreachSequenceBlueprintEdge'
            );
            if (newEdge) {
              ConnectionHandler.insertEdgeBefore(connection, newEdge);
            }
          }
        }
      },
    });
  };
  return [createOutreachSequenceBlueprintCaller, loading];
};
