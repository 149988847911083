import React, { FC } from 'react';
import { usePreloadedQuery } from 'react-relay';
import { GraphQLTaggedNode } from 'relay-runtime';

import OutreachBatch from './OutreachBatch/OutreachBatch';
import OutreachHeader from './OutreachHeader/OutreachHeader';

import { OutreachBatchesQuery } from 'GraphTypes/OutreachBatchesQuery.graphql';

interface Props {
  showPercentages: boolean;
  campaignId: string;
  queryReference: any;
  query: GraphQLTaggedNode;
}

const OutreachTable: FC<Props> = ({ showPercentages, campaignId, queryReference, query }) => {
  const data = usePreloadedQuery<OutreachBatchesQuery>(query, queryReference);

  const batches = data?.campaign?.outreachBatches;
  const batchesId = batches?.edges?.map((batch) => batch?.node?.id || '') || [];

  return (
    <>
      {Number(batches?.edges?.length) > 1 && (
        <OutreachHeader
          campaignId={campaignId}
          batchesId={batchesId}
          showPercentage={showPercentages}
        />
      )}
      {batches?.edges?.map((edge) => (
        <OutreachBatch
          key={edge?.node?.id}
          batch={edge?.node}
          campaignId={campaignId}
          showPercentage={showPercentages}
        />
      ))}
    </>
  );
};

export default OutreachTable;
