/**
 * @generated SignedSource<<5c9913b3af6b9b0e5c8ae95525158612>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationsBellQuery$variables = Record<PropertyKey, never>;
export type NotificationsBellQuery$data = {
  readonly currentUser: {
    readonly id: string;
    readonly inAppNotifications: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly read: boolean;
          readonly viewed: boolean;
          readonly " $fragmentSpreads": FragmentRefs<"NotificationItem_notification">;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly unviewedCount: {
      readonly totalCount: number;
    } | null | undefined;
  } | null | undefined;
};
export type NotificationsBellQuery = {
  response: NotificationsBellQuery$data;
  variables: NotificationsBellQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v2 = {
  "alias": "unviewedCount",
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1
    },
    {
      "kind": "Literal",
      "name": "viewed",
      "value": false
    }
  ],
  "concreteType": "InAppNotificationConnection",
  "kind": "LinkedField",
  "name": "inAppNotifications",
  "plural": false,
  "selections": (v1/*: any*/),
  "storageKey": "inAppNotifications(first:1,viewed:false)"
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 3
  },
  {
    "kind": "Literal",
    "name": "read",
    "value": false
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "read",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewed",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Campaign",
  "kind": "LinkedField",
  "name": "campaign",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Project",
  "kind": "LinkedField",
  "name": "project",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
},
v10 = [
  (v9/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationsBellQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "InAppNotificationConnection",
            "kind": "LinkedField",
            "name": "inAppNotifications",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "InAppNotificationEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "NotificationItem_notification"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "inAppNotifications(first:3,read:false)"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NotificationsBellQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "InAppNotificationConnection",
            "kind": "LinkedField",
            "name": "inAppNotifications",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "InAppNotificationEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      (v0/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "kind": "TypeDiscriminator",
                        "abstractKey": "__isInAppNotification"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "icon",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ctaText",
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v8/*: any*/)
                        ],
                        "type": "InAppNotifications_CampaignActivated",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalApplicantsCount",
                            "storageKey": null
                          }
                        ],
                        "type": "InAppNotifications_DailyOfferAcceptances",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Creative",
                            "kind": "LinkedField",
                            "name": "creative",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              (v0/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "InAppNotifications_CreativeCreated",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Project",
                            "kind": "LinkedField",
                            "name": "project",
                            "plural": false,
                            "selections": [
                              (v0/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Campaign",
                                "kind": "LinkedField",
                                "name": "campaign",
                                "plural": false,
                                "selections": [
                                  (v0/*: any*/),
                                  (v7/*: any*/),
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContentReview",
                            "kind": "LinkedField",
                            "name": "contentReview",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ContentReviewAttachmentConnection",
                                "kind": "LinkedField",
                                "name": "attachments",
                                "plural": false,
                                "selections": (v1/*: any*/),
                                "storageKey": null
                              },
                              (v0/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CreativeConnection",
                            "kind": "LinkedField",
                            "name": "creatives",
                            "plural": false,
                            "selections": (v1/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "type": "InAppNotifications_ContentReviewSubmitted",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v10/*: any*/),
                        "type": "InAppNotifications_DraftDeadlineOverdue",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Project",
                            "kind": "LinkedField",
                            "name": "project",
                            "plural": false,
                            "selections": [
                              (v0/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Campaign",
                                "kind": "LinkedField",
                                "name": "campaign",
                                "plural": false,
                                "selections": [
                                  (v0/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "InAppNotifications_BcaPermissionRevoked",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v10/*: any*/),
                        "type": "InAppNotifications_AdvertiserUnresponsiveness",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "inAppNotifications(first:3,read:false)"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "63d0ff64051c15fa03ad29dcfda0790f",
    "id": null,
    "metadata": {},
    "name": "NotificationsBellQuery",
    "operationKind": "query",
    "text": "query NotificationsBellQuery {\n  currentUser {\n    id\n    unviewedCount: inAppNotifications(first: 1, viewed: false) {\n      totalCount\n    }\n    inAppNotifications(first: 3, read: false) {\n      edges {\n        node {\n          __typename\n          id\n          read\n          viewed\n          ...NotificationItem_notification\n        }\n      }\n    }\n  }\n}\n\nfragment NotificationItem_notification on InAppNotification {\n  __isInAppNotification: __typename\n  id\n  createdAt\n  icon\n  ctaText\n  read\n  viewed\n  type\n  ... on InAppNotifications_CampaignActivated {\n    campaign {\n      id\n      name\n    }\n  }\n  ... on InAppNotifications_DailyOfferAcceptances {\n    id\n    totalApplicantsCount\n  }\n  ... on InAppNotifications_CreativeCreated {\n    creative {\n      project {\n        id\n        campaign {\n          id\n          name\n        }\n      }\n      id\n    }\n  }\n  ... on InAppNotifications_ContentReviewSubmitted {\n    project {\n      id\n      campaign {\n        id\n        name\n        type\n      }\n    }\n    contentReview {\n      attachments {\n        totalCount\n      }\n      id\n    }\n    creatives {\n      totalCount\n    }\n  }\n  ... on InAppNotifications_DraftDeadlineOverdue {\n    project {\n      id\n      campaign {\n        id\n        name\n      }\n    }\n  }\n  ... on InAppNotifications_BcaPermissionRevoked {\n    project {\n      id\n      campaign {\n        id\n      }\n    }\n  }\n  ... on InAppNotifications_AdvertiserUnresponsiveness {\n    project {\n      id\n      campaign {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2d8963b02ae2139c86aef790a1279e23";

export default node;
