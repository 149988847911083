import React, { ChangeEvent, useContext, useEffect, useRef, useState, KeyboardEvent } from 'react';
import classNames from 'classnames';

import styles from './SequenceItem.pcss';

import Text from 'Components/ui/Text/Text';
import { InviteOutreachCreatorsContext } from '../../InviteOutreachCreatorsContext';
import { SequenceStep } from '../SequenceEditor';
import Icon from 'Components/ui/Icon/Icon';
import Tag from 'Components/ui/Tag/Tag';
import Input from 'Components/ui/Input/Input';

interface Props {
  onClick: () => void;
}

const SequenceNewItem: React.FC<Props> = (props) => {
  const { onClick } = props;
  return (
    <div className={styles.root} onClick={onClick}>
      <div className={styles.title}>
        <div className={styles.label}>
          <Icon name="Add-plus-circle" />
        </div>
        <div className={styles.delayEditor}>
          <Text text={'Add message'} />
        </div>
      </div>
    </div>
  );
};

export default SequenceNewItem;
