/**
 * @generated SignedSource<<0bd974a03a701c0daaed0522b9a2a31a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CampaignObjective = "APP_INSTALLS" | "BRAND_AWARENESS" | "CATALOG_SALES" | "CONVERSIONS" | "ENGAGEMENT" | "JUST_CONTENT" | "LEAD_GENERATION" | "MESSAGES" | "REACH" | "STORE_TRAFFIC" | "TRAFFIC" | "VIDEO_VIEWS" | "%future added value";
export type CampaignPlatform = "FACEBOOK" | "INSTAGRAM" | "OTHER" | "SNAPCHAT" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type CampaignPostingType = "CREATOR_ADS" | "ORGANIC_POSTING" | "%future added value";
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING" | "%future added value";
export type CampaignUseCase = "AFFILIATE_CAMPAIGN" | "INFLUENCER_POSTS" | "META_PARTNERSHIP_ADS" | "PRODUCT_SEEDING" | "TIKTOK_SHOP" | "TIKTOK_SPARK_ADS" | "USER_GENERATED_CONTENT" | "%future added value";
export type ContentCreationPlatform = "FACEBOOK" | "INSTAGRAM" | "OTHER" | "SNAPCHAT" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type ContentCreationType = "VIDEO_EDITING" | "%future added value";
export type PaymentType = "BARTER" | "MONEY" | "%future added value";
export type CreateCampaignInput = {
  brandId?: string | null | undefined;
  browseCreators?: boolean | null | undefined;
  clientMutationId?: string | null | undefined;
  contentCreationPlatform?: ContentCreationPlatform | null | undefined;
  contentCreationType?: ContentCreationType | null | undefined;
  coverId?: string | null | undefined;
  csmMarkupPercentage?: number | null | undefined;
  description?: string | null | undefined;
  id?: string | null | undefined;
  inviteCustomListIds?: ReadonlyArray<string> | null | undefined;
  inviteFavorites?: boolean | null | undefined;
  managerId?: string | null | undefined;
  name?: string | null | undefined;
  objective?: CampaignObjective | null | undefined;
  paymentType?: PaymentType | null | undefined;
  plannedAmount?: number | null | undefined;
  platform?: CampaignPlatform | null | undefined;
  postingType?: CampaignPostingType | null | undefined;
  private?: boolean | null | undefined;
  type?: CampaignType | null | undefined;
  useCase?: CampaignUseCase | null | undefined;
};
export type CreateCampaignMutation$variables = {
  input: CreateCampaignInput;
};
export type CreateCampaignMutation$data = {
  readonly createCampaign: {
    readonly campaign: {
      readonly brief: {
        readonly id: string;
      } | null | undefined;
      readonly description: string | null | undefined;
      readonly id: string;
      readonly private: boolean | null | undefined;
    };
  } | null | undefined;
};
export type CreateCampaignMutation = {
  response: CreateCampaignMutation$data;
  variables: CreateCampaignMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "private",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateCampaignMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateCampaignPayload",
        "kind": "LinkedField",
        "name": "createCampaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "brief",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateCampaignMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateCampaignPayload",
        "kind": "LinkedField",
        "name": "createCampaign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Campaign",
            "kind": "LinkedField",
            "name": "campaign",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "brief",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f264df26d98b94a2006316198b52508e",
    "id": null,
    "metadata": {},
    "name": "CreateCampaignMutation",
    "operationKind": "mutation",
    "text": "mutation CreateCampaignMutation(\n  $input: CreateCampaignInput!\n) {\n  createCampaign(input: $input) {\n    campaign {\n      id\n      private\n      description\n      brief {\n        __typename\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "939545162f87d893abaa6ca5b13e5bf9";

export default node;
