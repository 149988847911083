import React, { Suspense, useContext, useEffect, useRef } from 'react';
import { PreloadedQuery, usePreloadedQuery } from 'react-relay';

import styles from './DiscoverySearchContent.pcss';
import { createParamsData } from './utils';
import SearchForm from './SearchForm/DiscoverySearchForm';

import DiscoverySearchResultsContainer from './SearchResults/DiscoverySearchResultsContainer';
import { DiscoveryCreatorsContext } from '../../DiscoveryCreatorsContext';
import { DiscoveryQuery as QueryType } from 'GraphTypes/DiscoveryQuery.graphql';
import { DiscoveryQuery } from '../DiscoverySearchSection';
import Spinner from 'Atoms/Spinner/Spinner';
import { CreatorsSearchContext } from 'Page/advertiser/Mediaplan/SearchSection/CreatorsSearch.Context';

interface Props {
  queryReference: PreloadedQuery<QueryType, Record<string, unknown>>;
}

const DiscoverySearchContent: React.FC<Props> = (props) => {
  const { queryReference } = props;
  const controller = useRef<AbortController>();
  const { state: creatorsSearchState } = useContext(CreatorsSearchContext);

  const creatorIdsForExclusion = creatorsSearchState.creatorIdsForExclusion;
  const { updateQueryParams, setOrganizationId, setAdminStatus, setListData, counter } =
    useContext(DiscoveryCreatorsContext);
  const data = usePreloadedQuery<QueryType>(DiscoveryQuery, queryReference);

  const organizationId = data.currentUser?.organization?.id;

  useEffect(() => {
    if (organizationId) {
      setOrganizationId(data.currentUser?.organization?.id);
    }
  }, [organizationId]);

  useEffect(() => {
    setAdminStatus(data.currentUser.admin);
  }, [data.currentUser.admin]);

  useEffect(() => {
    setListData(data.customList);
  }, [data.customList]);

  const handleParamsChange = (params: any, optimize = true) => {
    if (optimize) {
      if (controller.current) {
        controller.current.abort();
      }
      controller.current = new AbortController();
    }
    const data = createParamsData(params, creatorIdsForExclusion);
    updateQueryParams(data);
  };

  const preloader = (
    <div className={styles.loadingContainer}>
      <Spinner />
    </div>
  );

  return (
    <>
      {counter.total === null ? preloader : null}
      <SearchForm onParamsChange={handleParamsChange} className={styles.searchForm} />
      <DiscoverySearchResultsContainer controller={controller} />
    </>
  );
};

export default DiscoverySearchContent;
