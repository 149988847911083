import React, { Suspense, useContext, useEffect, useState } from 'react';
import classnames from 'classnames';

import styles from './TiktokShopProductsList.pcss';

import updateBrief from 'Mutations/UpdateBrief.Mutation';
import launchProject from 'Mutations/LaunchProject.Mutation';
import Drawer from 'Components/ui/Drawer/Drawer';
import Text from 'Components/ui/Text/Text';
import { graphql, useQueryLoader } from 'react-relay';
import { TiktokShopProductsListQuery as QueryType } from 'GraphTypes/TiktokShopProductsListQuery.graphql';
import Spinner from 'Atoms/Spinner/Spinner';
import TiktokShopProductsList, { TikTokShopProductItem } from './ProductList/ProductList';
import Button from 'Components/ui/Button/Button';
import { useCreateTiktokShopCollaborationMutation } from 'Api/mutations/CreateTiktokShopCollaboration.Mutation';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

type DrawerProps = React.ComponentProps<typeof Drawer>;

interface Props {
  campaignId: string;
  briefId?: string;
  projectId?: string;
  drawerProps?: Partial<Omit<DrawerProps, 'rootKey' | 'children'>>;
}

export const TiktokShopProductsListQuery = graphql`
  query TiktokShopProductsListQuery($campaignId: ID!) {
    campaign(id: $campaignId) {
      organization {
        tiktokShopLinked
        tiktokShops {
          id
          name
          products {
            id
            shopId
            description
            isNotForSale
            status
            title
            packageDimensions {
              height
              width
            }
            mainImages {
              urls
            }
          }
        }
      }
    }
  }
`;

export type CollaborationInformation = {
  comissionRate: number | null;
  isSampleApprovalExempt: boolean;
  hasFreeSample: boolean;
};

const TiktokShopProductsListDrawer: React.FC<Props> = (props) => {
  const { campaignId, briefId, projectId, drawerProps } = props;
  const { openDrawer, closeDrawer } = useContext(DrawerContext);
  const [queryReference, loadQuery] = useQueryLoader<QueryType>(TiktokShopProductsListQuery);
  const [selectedProduct, setSelectedProduct] = useState<TikTokShopProductItem | null>(null);
  const [additionalProductInfo, setProductInfo] = useState<CollaborationInformation>({
    comissionRate: null,
    isSampleApprovalExempt: false,
    hasFreeSample: false,
  });
  const [createTiktokShopCollaboration, loading] = useCreateTiktokShopCollaborationMutation();
  useEffect(() => {
    loadQuery({
      campaignId,
    });
  }, [campaignId]);

  const handleSelectProduct = (product: TikTokShopProductItem) => {
    setSelectedProduct(product);
  };

  const changeProductInfo = (params: Partial<CollaborationInformation>) => {
    setProductInfo((info) => ({
      ...info,
      ...params,
    }));
  };

  const handleDarwerClose = () => {
    closeDrawer('tt-shop-product-list');
  };

  const handleCreateCollaborationClick = () => {
    if (!selectedProduct) return;
    if (projectId) {
      createTiktokShopCollaboration({
        variables: {
          input: {
            productId: selectedProduct.id,
            comissionRate: Number(additionalProductInfo.comissionRate) / 100,
            shopId: selectedProduct.shopId,
            isSampleApprovalExempt: additionalProductInfo.isSampleApprovalExempt,
            hasFreeSample: additionalProductInfo.hasFreeSample,
            projectId,
          },
        },
        onCompleted: (data) => {
          if (
            data.createTiktokShopCollaboration?.__typename ===
              'CreateTiktokShopCollaborationPayload' &&
            data.createTiktokShopCollaboration.collaboration?.id
          ) {
            launchProject({ projectId }, () => {
              openDrawer(`project-draft-deadline-${projectId}`);
            });
          }
        },
      });
    } else if (campaignId && briefId) {
      updateBrief(
        {
          id: briefId,
          tiktokShop_ProductId: selectedProduct.id,
          tiktokShop_ShopId: selectedProduct.shopId,
          includeReferralFee: !!additionalProductInfo.comissionRate,
          referralFeeRate: additionalProductInfo.comissionRate
            ? Number(additionalProductInfo.comissionRate)
            : undefined,
        },
        handleDarwerClose
      );
    }
  };
  return (
    <Drawer
      {...drawerProps}
      className={classnames(styles.drawer, drawerProps?.className)}
      rootKey="tt-shop-product-list"
    >
      <div className={styles.contentRoot}>
        <Text type="d2" msg="tiktok_shop_products_modal.title" className={styles.title} />
        <Text type="md" msg="tiktok_shop_products_modal.descr" className={styles.description} />
        <Suspense
          fallback={
            <div className={styles.loader}>
              <Spinner />
            </div>
          }
        >
          {queryReference && (
            <TiktokShopProductsList
              campaignId={campaignId}
              queryReference={queryReference}
              selectedProduct={selectedProduct}
              handleSelectProduct={handleSelectProduct}
              additionalProductInfo={additionalProductInfo}
              setProductInfo={changeProductInfo}
            />
          )}
        </Suspense>
        <Button
          color="black"
          msg="set_draft_deadline_modal.btn"
          disabled={!selectedProduct?.title}
          loading={loading}
          onClick={handleCreateCollaborationClick}
        />
      </div>
    </Drawer>
  );
};

export default TiktokShopProductsListDrawer;
