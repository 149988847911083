import React, {
  FC,
  useContext,
  useEffect,
  useMemo,
  ChangeEvent,
  ChangeEventHandler,
  useCallback,
} from 'react';
import { graphql, useFragment } from 'react-relay';
import { Element, scroller } from 'react-scroll';
import classnames from 'classnames';
import Text from 'Components/ui/Text/Text';
import { NavLink } from 'react-router-dom';

import { prepareCampaignValue } from '../../../utils';
import { BriefContext } from '../../../Brief.Context';
import Field from '../components/Field/Field';
import Section from '../components/Section/Section';
import SegmentedControls from '../components/SegmentedControls/SegmentedControls';
import With from '../components/With/With';

import ProductSeedingDetails from './ProductSeedingDetails/ProductSeedingDetails';
import styles from './CampaignDetails.pcss';
import {
  checkValidation,
  getCreatorsAmountList,
  getCurrentCreatorsAmountValue,
  getGoalList,
  getObjectivesList,
  getPaymentMethodsList,
  getPlatformsList,
  getTypesList,
  getUseCaseList,
  getViewConditions,
} from './util';
import BrandDetails from './BrandDetails/BrandDetails';
import SparkAdsDetails from './SparkAdsDetails/SparkAdsDetails';
import PartnershipAdsDetails from './PartnershipAdsDetails/PartnershipAdsDetails';
import PriceRangeDetails from './PriceRangeDetails/PriceRangeDetails';
import ComissionRateDetails from './ComissionRateDetails/ComissionRateDetails';

import deleteBriefCreative from 'Mutations/DeleteBriefCreative.Mutation';
import { amplitude } from 'Helpers/amplitude';
import { addHubspotScript, identifyUser } from 'Analytics/engines/Hubspot';
import updateBrief from 'Mutations/UpdateBrief.Mutation';
import updateCampaign from 'Mutations/UpdateCampaign.Mutation';
import updateBriefCreative from 'Mutations/UpdateBriefCreative.Mutation';
import { Currency, PaymentType } from 'GraphTypes/BriefTemplateContentQuery.graphql';
import {
  CampaignDetails_campaign$data,
  CampaignDetails_campaign$key,
} from 'GraphTypes/CampaignDetails_campaign.graphql';
import {
  CONTENT_CREATION,
  CREATOR_ADS,
  INFLUENCER,
  MONEY_TYPE,
  ORGANIC_POSTING,
  PRODUCT_SEEDING,
  TIKTOK,
} from 'Constants/general';
import type { UpdateCampaignInput } from 'GraphTypes/UpdateCampaignMutation.graphql';
import type { UpdateBriefInput } from 'GraphTypes/UpdateBriefMutation.graphql';
import { CampaignUseCase } from 'GraphTypes/CreateCampaignMutation.graphql';
import { FeatureFlagsContext } from 'Containers/FeatureFlags/FeatureFlags.Context';
import { CampaignType } from 'Types/enums';
import WlSlotsBanner from './WlSlotsBanner/WlSlotsBanner';
import ProgressiveInput from 'Components/ProgressiveInput/ProgressiveInput';
import debounce from 'lodash/debounce';

interface Props {
  campaign: CampaignDetails_campaign$key;
  currency?: Currency;
  maxPaidSocialActivationDays?: number;
  canUsePaidSocial?: boolean;
  paying?: boolean;
  isAdmin: boolean;
  isOwner: boolean;
  trialMonthPlanAvailable?: boolean;
  availablePaymentTypes?: readonly PaymentType[];
  showSparkAdsAwareness?: boolean;
  psLicensedCreators?: number;
}

const CampaignDetails: React.FC<Props> = (props) => {
  const [briefState, dispatchBriefValue] = useContext(BriefContext);

  const { showErrors, shownElementsWithPossibleError } = briefState;

  const { hasFeatureFlag } = useContext(FeatureFlagsContext);

  const tiktokShopAvailable = hasFeatureFlag('tiktok_shop');

  const [{ availableCampaignTypes, availableCampaignUseCases, planId }, briefDispatch] =
    useContext(BriefContext);

  const {
    currency,
    paying,
    canUsePaidSocial,
    psLicensedCreators,
    maxPaidSocialActivationDays,
    trialMonthPlanAvailable,
    showSparkAdsAwareness,
    isAdmin,
    isOwner,
    campaign,
  } = props;

  const data = useFragment(
    graphql`
      fragment CampaignDetails_campaign on Campaign {
        useCasesUnlocked
        id
        name
        type
        postingType
        platform
        paymentType
        objective
        useCase
        brand {
          id
        }
        organization {
          test
          newClient
          id
          counters {
            bcaLicensedCreators
          }
          subscription {
            planId
            effectiveLimits {
              maxLicensedCreators
            }
          }
          tiktokShopLinked
        }
        ...WlSlotsBanner_campaign
        brief {
          id
          ...PartnershipAdsDetails_brief
          ... on V2Brief {
            creatives(first: 2147483647) @connection(key: "Creatives_creatives") {
              edges {
                node {
                  id
                }
              }
            }
            includeReferralFee
            referralFeeRate
            paidSocial
            paidSocialActivationDays
            paidSocialBca
            sparkAdsActivationDays
            creatorsCountFrom
            creatorsCountTo
            priceLimitMin
            priceLimitMax
            hiringGoal
          }
        }
        ...BrandDetails_campaign
        ...PriceRangeDetails_campaign
        ...ComissionRateDetails_campaign
      }
    `,
    campaign
  );

  const campaignWithType = prepareCampaignValue(data, [
    ...availableCampaignTypes,
  ]) as CampaignDetails_campaign$data;

  const {
    name,
    id: campaignId,
    type,
    postingType,
    platform,
    brief,
    objective,
    organization,
    useCase,
  } = campaignWithType;
  const hasNewCases = hasFeatureFlag('use_cases_5389') && data?.useCasesUnlocked;

  if (!brief) return null;

  const { id: briefId, creatorsCountFrom, creatorsCountTo } = brief;

  const isCreatorAds = type === INFLUENCER && postingType === CREATOR_ADS;
  const isTTCreatorAds = isCreatorAds && platform === TIKTOK;

  const typesList = getTypesList({ campaignId, availableCampaignTypes });
  const platformsList = getPlatformsList({ currency, isCreatorAds, useCase });
  const paymentMethods = getPaymentMethodsList({ campaignId });

  const creatorsAmountList = getCreatorsAmountList();
  const objectivesList = getObjectivesList({ campaignId });
  const goalList = getGoalList({ campaignId });
  const isTestOrg = data?.organization?.test;

  const creatives = brief.creatives?.edges;

  const viewConditions = getViewConditions({
    campaign: data,
    canUsePaidSocial,
    showSparkAdsAwareness,
    canUseNewCases: hasNewCases,
    useCase: useCase,
    withComission: useCase === 'AFFILIATE_CAMPAIGN' || useCase === 'TIKTOK_SHOP',
  });

  const handleUseCaseSampleCheck = (useCase: CampaignUseCase) => {
    amplitude.sendEvent<395>({
      id: '395',
      category: 'brief',
      name: 'sample_ugc_click',
      param: {
        strategy_type: useCase,
        campaignId,
        isAdmin,
        organization_id: organization?.id,
        planId,
        isTestOrganization: !!isTestOrg,
      },
    });
  };

  const getTooltipContent: FC = () => {
    return (
      <div className={styles.upgradeTooltip}>
        <Text msg={'campaign.stategy.tooltip'} />
        <NavLink to={'/billing/plans'}>
          <Text msg={'campaign.stategy.link'} color={'pink'} />
        </NavLink>
      </div>
    );
  };

  const useCaseList = getUseCaseList(
    objective,
    availableCampaignUseCases,
    handleUseCaseSampleCheck,
    getTooltipContent,
    organization
  );

  const creatorsAmountValue = getCurrentCreatorsAmountValue(creatorsCountFrom, creatorsCountTo);

  useEffect(() => {
    if (type === CONTENT_CREATION || postingType === ORGANIC_POSTING) {
      handleBriefUpdate({
        paidSocial: false,
        paidSocialBca: false,
        paidSocialActivationDays: null,
        sparkAds: false,
        sparkAdsActivationDays: null,
      });
    }
  }, [type, postingType]);

  useEffect(() => {
    const errors = checkValidation(data, hasNewCases);
    briefDispatch({ key: 'campaignInfoErrors', value: errors });
    briefDispatch({ key: 'campaignInfoFilled', value: errors.length === 0 });
  }, [data]);

  useEffect(() => {
    if (showSparkAdsAwareness) {
      setTimeout(() => {
        scroller.scrollTo('sparkAdsAwareness', {
          duration: 500,
          smooth: true,
          offset: -100,
        });
      }, 500);
    }
  }, []);

  const handleBriefUpdate = (data: Partial<UpdateBriefInput>) => {
    briefDispatch({ key: 'briefIsSaving', value: true });
    updateBrief({ id: briefId, ...data });
  };

  const handleCampaignUpdate = (data: Partial<UpdateCampaignInput>) => {
    briefDispatch({ key: 'briefIsSaving', value: true });
    updateCampaign({ id: campaignId, ...data });
  };

  const handleFieldChange = (data: any) => {
    if (Object.prototype.hasOwnProperty.call(data, 'objective')) {
      amplitude.sendEvent<393>({
        id: '393',
        category: 'brief',
        name: 'goal_click',
        param: {
          goal_type: data.objective,
          campaignId,
          isAdmin,
          organization_id: organization?.id,
          planId,
          isTestOrganization: !!isTestOrg,
        },
      });
    }
    handleCampaignUpdate({ ...data, useCase: null });
  };
  const handleUseCaseChanged = (data: { useCase: CampaignUseCase }) => {
    if (data?.useCase) {
      amplitude.sendEvent<394>({
        id: '394',
        category: 'brief',
        name: 'strategy_ugc_click',
        param: {
          strategy_type: data.useCase,
          campaignId,
          isAdmin,
          organization_id: organization?.id,
          planId,
          isTestOrganization: !!isTestOrg,
        },
      });
      handleCampaignUpdate({ useCase: data.useCase });
      dispatchBriefValue({ key: 'useCase', value: data.useCase });
      switch (data?.useCase) {
        case 'USER_GENERATED_CONTENT': {
          handleTypeChange({ type: CampaignType.CONTENT_CREATION });
          handleBriefUpdate({ includeReferralFee: false, referralFeeRate: null });
          break;
        }
        case 'INFLUENCER_POSTS': {
          handleTypeChange({ type: ORGANIC_POSTING });
          handlePlatformChange({ platform: 'INSTAGRAM' });
          handleBriefUpdate({ includeReferralFee: false, referralFeeRate: null });
          break;
        }
        case 'PRODUCT_SEEDING': {
          handleTypeChange({ type: CampaignType.PRODUCT_SEEDING });
          handlePlatformChange({ platform: 'INSTAGRAM' });
          handleBriefUpdate({ includeReferralFee: false, referralFeeRate: null });
          break;
        }
        case 'TIKTOK_SPARK_ADS': {
          handleTypeChange({ type: CREATOR_ADS });
          handlePlatformChange({ platform: 'TIKTOK' });
          handleBriefUpdate({ includeReferralFee: false, referralFeeRate: null });
          break;
        }
        case 'META_PARTNERSHIP_ADS': {
          handleTypeChange({ type: 'CREATOR_ADS' });
          handlePlatformChange({ platform: 'INSTAGRAM' });
          handleBriefUpdate({ includeReferralFee: false, referralFeeRate: null });
          break;
        }
        case 'AFFILIATE_CAMPAIGN': {
          handleBriefUpdate({
            includeReferralFee: true,
            referralFeeRate: 10,
            priceLimitMin: null,
            priceLimitMax: null,
          });
          handleTypeChange({ type: ORGANIC_POSTING });
          break;
        }
        case 'TIKTOK_SHOP': {
          handlePlatformChange({ platform: 'TIKTOK' });
          handleBriefUpdate({
            includeReferralFee: true,
            referralFeeRate: 15,
            priceLimitMax: null,
            priceLimitMin: null,
          });
          handleTypeChange({ type: ORGANIC_POSTING });
        }
      }
    }
  };

  const handleTypeChange = (data: { type: string }) => {
    const newType = data.type;
    switch (newType) {
      case CONTENT_CREATION: {
        dispatchBriefValue({ key: 'campaignType', value: newType });
        handleCampaignUpdate({
          type: newType,
          objective: hasNewCases ? 'JUST_CONTENT' : null,
          postingType: null,
        });
        creatives?.forEach((creative) => {
          if (!creative?.node?.id) return;
          updateBriefCreative({ id: creative.node.id, publishingRequired: false });
        });
        break;
      }
      case PRODUCT_SEEDING: {
        dispatchBriefValue({
          key: 'campaignType',
          value: newType,
        });
        handleBriefUpdate({
          paidSocial: false,
          paidSocialBca: false,
          priceLimitMin: null,
          priceLimitMax: null,
          paidSocialActivationDays: null,
          sparkAds: false,
          sparkAdsActivationDays: null,
          ...(!hasNewCases && useCase !== 'AFFILIATE_CAMPAIGN' && useCase !== 'TIKTOK_SHOP'
            ? {
                referralFeeRate: null,
                includeReferralFee: false,
              }
            : {}),
        });
        handleCampaignUpdate({
          type: newType,
          paymentType: MONEY_TYPE,
          postingType: null,
        });
        creatives?.forEach((creative) => {
          if (creative?.node?.id) {
            deleteBriefCreative({ id: creative.node.id, briefId });
          }
        });
        break;
      }
      default: {
        dispatchBriefValue({ key: 'campaignType', value: INFLUENCER });
        handleCampaignUpdate({ type: INFLUENCER, postingType: newType });
        break;
      }
    }
  };

  const handleCampaignNameChange = (data: { name: string }) => {
    if (!paying && data.name && !name && currency === 'USD') {
      const callback = () => {
        identifyUser({ first_brief_status: 'started' });
      };

      addHubspotScript(callback);
    }
    handleCampaignUpdate({ name: data.name });
  };

  const handlePlatformChange = (data: { platform: string }) => {
    const newPlatform = data.platform;
    if (!(newPlatform === 'INSTAGRAM' || newPlatform === 'FACEBOOK')) {
      handleBriefUpdate({
        paidSocial: false,
        paidSocialBca: false,
        paidSocialActivationDays: null,
      });
    }
    if (newPlatform !== 'TIKTOK' && platform === 'TIKTOK') {
      handleBriefUpdate({ sparkAds: false, sparkAdsActivationDays: null });
    }

    handleCampaignUpdate({ platform: newPlatform });
  };

  const handleCreatorsAmountChange = (data: { creatorsAmount: string }) => {
    const creatorsAmount = data.creatorsAmount;
    const amountArr = creatorsAmount.split('-');
    const from = Number(amountArr[0]) || undefined;
    const to = Number(amountArr[1]) || null;
    handleBriefUpdate(
      Number(from) < 21
        ? {
            creatorsCountFrom: from,
            creatorsCountTo: to,
          }
        : { creatorsCountFrom: from, creatorsCountTo: null, hiringGoal: null }
    );
  };

  const handleCreatorAmountReset = useCallback(() => {
    handleBriefUpdate({ hiringGoal: 0 });
  }, []);

  const handleExactCreatorAmountChange: ChangeEventHandler<HTMLInputElement> = debounce((event) => {
    handleBriefUpdate({ hiringGoal: Number(event?.target?.value) });
  }, 500);

  const typeValue = useMemo(() => {
    if (type === CONTENT_CREATION || type === PRODUCT_SEEDING) return type;
    if (type === INFLUENCER && postingType) return postingType;
    return undefined;
  }, [type, postingType]);

  return (
    <Section titleMsg="brief_template.section.campaign_info">
      <Element name="brand">
        <BrandDetails campaign={data} isOwner={isOwner} isAdmin={isAdmin} />
      </Element>
      <Element name="name">
        <Field
          title="brief_template.field.campaign_name.title"
          description="brief_template.field.campaign_name.descr"
          name="name"
          element="input"
          elementData={{
            maxlength: 50,
            defaultValue: name,
            placeholderMsg: 'brief_template.field.campaign_name.placeholder',
            onBlur: handleCampaignNameChange,
            dataTest: 'input:campaignName',
            error: shownElementsWithPossibleError?.includes('name') && showErrors && !name,
          }}
          className={styles.field}
        />
      </Element>
      <With condition={!hasNewCases} name="type">
        <Field
          title="brief_template.field.campaign_type.title"
          description="brief_template.field.campaign_type.descr"
        >
          <Element name="type">
            <SegmentedControls
              id="type"
              error={shownElementsWithPossibleError?.includes('type') && showErrors && !typeValue}
              currentValue={typeValue}
              items={typesList}
              onChange={handleTypeChange}
              className={styles.types}
              itemClassName={styles.typeItem}
            />
          </Element>
        </Field>
      </With>
      <With condition={hasNewCases && viewConditions.objective} name="objective">
        <Field title="campaign.goal.title">
          <SegmentedControls
            id="objective"
            items={goalList}
            error={
              shownElementsWithPossibleError?.includes('objective') && showErrors && !objective
            }
            currentValue={objective}
            onChange={handleFieldChange}
            itemClassName={styles.goalItem}
          />
        </Field>
      </With>
      <With condition={viewConditions.newCases} name="useCases">
        <Field title="campaign.strategy.title">
          <Element name="useCases">
            <SegmentedControls
              id="useCase"
              items={useCaseList}
              error={shownElementsWithPossibleError?.includes('useCases') && showErrors && !useCase}
              onChange={handleUseCaseChanged}
              currentValue={useCase}
              itemClassName={classnames(styles.strategyControl, {
                [styles.small]: useCaseList.length === 1,
              })}
              outerLinkPosition={'bottom'}
            />
          </Element>
        </Field>
      </With>
      <With condition={viewConditions.productSeedingDetailsVisible} name="productSeedingDetails">
        <ProductSeedingDetails />
      </With>
      <With condition={viewConditions.platform && platformsList.length > 1} name="platform">
        <Field
          title="brief_template.field.campaign_platform.title"
          description="brief_template.field.campaign_platform.descr"
        >
          <Element name="platform">
            <SegmentedControls
              error={
                shownElementsWithPossibleError?.includes('platform') && showErrors && !platform
              }
              id="platform"
              currentValue={platform}
              items={platformsList}
              onChange={handlePlatformChange}
            />
          </Element>
        </Field>
      </With>
      {briefId && (
        <With condition={viewConditions.partnershipDetails} forceRerender name="creatorAdsIg">
          <Element name="creatorAdsIg">
            <PartnershipAdsDetails
              brief={brief}
              psLicensedCreators={psLicensedCreators}
              trialMonthPlanAvailable={trialMonthPlanAvailable}
              maxPaidSocialActivationDays={maxPaidSocialActivationDays}
            />
          </Element>
        </With>
      )}
      <Element name="sparkAdsAwareness">
        <With condition={viewConditions.sparkAdsDetaild} name="creatorAdsTiktok">
          <Element name="creatorAdsTiktok">
            <SparkAdsDetails
              briefId={briefId}
              sparkAdsActivationDays={brief.sparkAdsActivationDays}
              showSparkAdsAwareness={showSparkAdsAwareness}
              hideDisabled={isTTCreatorAds}
            />
          </Element>
        </With>
      </Element>
      <With condition={viewConditions.creatorsAmount} name="creatorsCount">
        <Field
          title="brief_template.field.campaign_creators_amount.title"
          description="brief_template.field.campaign_creators_amount.descr"
          className={styles.creatorsAmountContainer}
        >
          <Element name="creatorsCount">
            <SegmentedControls
              error={
                shownElementsWithPossibleError?.includes('creatorsCount') &&
                showErrors &&
                !creatorsAmountValue
              }
              id="creatorsAmount"
              currentValue={creatorsAmountValue}
              items={creatorsAmountList}
              onChange={handleCreatorsAmountChange}
            />
          </Element>
        </Field>
      </With>
      <With condition={viewConditions.exactCreatorAmountVisible} name={'hiringGoal'} forceRerender>
        <ProgressiveInput
          type="input"
          className={styles.exactAmount}
          inputProps={{
            placeholderMsg: 'brief_template.field.campaign_creators_exact_amount.placeholder',
            onChange: handleExactCreatorAmountChange,
            type: 'number',
            value: brief?.hiringGoal || undefined,
            onResetValue: handleCreatorAmountReset,
            error:
              (brief?.hiringGoal !== null && Number(brief?.hiringGoal) < 21) ||
              (shownElementsWithPossibleError?.includes('hiringGoal') &&
                showErrors &&
                !brief?.hiringGoal),
          }}
          errorMsg={
            (brief?.hiringGoal !== null && Number(brief?.hiringGoal) < 21) ||
            (shownElementsWithPossibleError?.includes('hiringGoal') && showErrors)
              ? 'brief_template.field.campaign_creators_exact_amount.error'
              : ''
          }
        />
      </With>
      <With condition={viewConditions.wlAddonsBanner}>
        <WlSlotsBanner campaign={data} />
      </With>
      <With forceRerender condition={viewConditions.priceRange} name="priceRange">
        <Element name="priceRange">
          <PriceRangeDetails
            isAdmin={isAdmin}
            campaign={data}
            onBriefChange={handleBriefUpdate}
            withComission={useCase === 'AFFILIATE_CAMPAIGN' || useCase === 'TIKTOK_SHOP'}
            handleCampaignUpdate={handleTypeChange}
          />
        </Element>
      </With>
      <With condition={!hasNewCases && viewConditions.objective}>
        <Field
          title="brief_template.field.campaign_objective.title"
          description="brief_template.field.campaign_objective.descr"
          isOptional={true}
        >
          <SegmentedControls
            id="objective"
            currentValue={objective}
            items={objectivesList}
            onChange={handleFieldChange}
          />
        </Field>
      </With>
      {!(tiktokShopAvailable && data.useCase === 'TIKTOK_SHOP') && (
        <Element name="fee">
          <ComissionRateDetails
            isAdmin={isAdmin}
            comissionAvailable={viewConditions.comissionAvailable}
            commissionInputVisible={viewConditions.commissionInputVisible}
            campaign={data}
            onBriefChange={handleBriefUpdate}
          />
        </Element>
      )}
    </Section>
  );
};

export default CampaignDetails;
