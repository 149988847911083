import React, { useContext, useMemo } from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import styles from '../InviteOutreachCreators.pcss';
import {
  InviteOutreachCreatorsContext,
  InviteOutreachCreatorsSelectedLists,
} from '../InviteOutreachCreatorsContext';

import Text from 'Components/ui/Text/Text';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { CreatorsListSelectorQuery as QueryType } from 'GraphTypes/CreatorsListSelectorQuery.graphql';
import Spinner from 'Atoms/Spinner/Spinner';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { amplitude } from 'Helpers/amplitude';
import { CREATORS_LIST_ROUTE, DISCOVERY_ROUTE } from 'Constants/general';
import { FeatureFlagsContext } from 'Containers/FeatureFlags/FeatureFlags.Context';

interface Props {}

const CreatorsListSelectorQuery = graphql`
  query CreatorsListSelectorQuery {
    currentUser {
      organization {
        id
        favoriteCreators {
          totalCount
        }
        customLists {
          edges {
            node {
              id
              name
              creators {
                totalCount
              }
            }
          }
        }
      }
    }
  }
`;

const CreatorsListSelector: React.FC<Props> = (props) => {
  const { selectedLists, setSelectedLists } = useContext(InviteOutreachCreatorsContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { hasFeatureFlag } = useContext(FeatureFlagsContext);
  const canUseOutreachTool = hasFeatureFlag('outreach_tool');

  const createdListId = location.state?.listId || null;

  const data = useLazyLoadQuery<QueryType>(
    CreatorsListSelectorQuery,
    {},
    {
      fetchKey: createdListId,
      fetchPolicy: 'store-and-network',
    }
  );

  const listOfList = useMemo(() => {
    const notEmptyLists = (data.currentUser?.organization?.customLists?.edges || []).filter(
      (item) => item?.node?.creators?.totalCount && item?.node?.creators?.totalCount > 0
    );
    return [...notEmptyLists];
  }, [data.currentUser?.organization?.customLists?.edges]);

  const selectedListsNames = useMemo(() => {
    return listOfList
      .map((list) => {
        if (typeof list?.node?.id === 'string' && selectedLists.includes(list?.node?.id)) {
          return list?.node?.name;
        }
        return null;
      })
      .filter((list) => list)
      .join(', ');
  }, [selectedLists, listOfList]);

  const selectedListsCreatorsCount = useMemo(() => {
    return listOfList.reduce((count, list) => {
      if (typeof list?.node?.id === 'string' && selectedLists.includes(list?.node?.id)) {
        return (count += list?.node?.creators?.totalCount || 0);
      }
      return count;
    }, 0);
  }, [selectedLists, listOfList]);

  const handleCreateList = () => {
    amplitude.sendEvent<440>({
      id: '440',
      category: 'campaign',
      name: 'outreach_create_list_click',
    });
    if (canUseOutreachTool) {
      const newListId = uuid();
      const discoveryUrl = generatePath(DISCOVERY_ROUTE, { listId: newListId });
      navigate(discoveryUrl, {
        state: {
          backUrl: location.pathname,
        },
      });
    } else {
      navigate(CREATORS_LIST_ROUTE, {
        state: {
          createNew: true,
        },
      });
    }
  };

  return (
    <>
      <div className={styles.attachTitle}>
        <Text type="md" msg="invite_outreach_creators_modal.step_1" />
      </div>
      {listOfList ? (
        <Dropdown
          value={
            <Text
              type="md"
              className={styles.dropdownValue}
              text={selectedListsNames}
              msg={
                listOfList.length === 0 ? 'invite_outreach_creators_modal.empty_lists' : undefined
              }
            />
          }
          className={styles.dropdownEmail}
        >
          <DropdownGroup className={styles.dropdownMenu}>
            {listOfList.map((item) => {
              if (!item?.node?.id) return null;
              const isActive = selectedLists.includes(item.node.id);

              const handleSelectList = () => {
                if (isActive) {
                  setSelectedLists((arr: InviteOutreachCreatorsSelectedLists) =>
                    arr.filter((list) => list !== item.node.id)
                  );
                } else {
                  setSelectedLists((arr: InviteOutreachCreatorsSelectedLists) => [item.node.id]);
                }
              };

              return (
                <DropdownGroupItem key={item.node.id}>
                  <AlterButton
                    text={item.node.name}
                    onClick={handleSelectList}
                    fluid
                    iconPosition="right"
                    icon={isActive ? 'Check' : null}
                  />
                </DropdownGroupItem>
              );
            })}
            <AlterButton
              msg="invite_outreach_creators_modal.create_lists"
              fluid
              iconPosition="left"
              icon="Add-plus"
              onClick={handleCreateList}
              classes={{
                buttonText: styles.addListButton,
              }}
            />
          </DropdownGroup>
        </Dropdown>
      ) : (
        <Spinner />
      )}
      {selectedListsCreatorsCount > 0 && (
        <Text
          type="md"
          msg="invite_outreach_creators_modal.creators_count"
          formatValues={{ count: selectedListsCreatorsCount }}
          className={styles.secondaryText}
        />
      )}
    </>
  );
};

export default CreatorsListSelector;
