import React from 'react';

import styles from './FileUploader.pcss';

import { FileUploaderType } from 'Types/common';

const FileUploader: React.FC<FileUploaderType> = (props) => {
  const { className, multiple, typeList = 'image/*,video/*', onChange, disabled } = props;

  // cleaning the value in order to be able to choose the same file
  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    return (target.value = '');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (onChange) {
      onChange(files);
    }
  };

  return (
    <input
      className={`${className} ${styles.root}`}
      type="file"
      multiple={multiple}
      accept={typeList}
      disabled={disabled}
      onChange={handleChange}
      onClick={handleClick}
    />
  );
};

export default FileUploader;
