import React, { useMemo, useState, useContext } from 'react';

import styles from './ProductDeliveryOption.pcss';

import Drawer from 'Components/ui/Drawer/Drawer';
import Text from 'Components/ui/Text/Text';
import { CheckboxItem } from 'Components/ui/CheckboxSelect/CheckboxSelect';
import Button from 'Components/ui/Button/Button';
import launchProject from 'Api/mutations/LaunchProject.Mutation';
import { ProductDeliveryOption } from 'GraphTypes/ProductDetails_campaign.graphql';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  projectId: string;
}

const ProductDeliveryOption: React.FC<Props> = (props) => {
  const { projectId } = props;
  const { openDrawer } = useContext(DrawerContext);

  const [option, setOption] = useState('');

  const list = useMemo(() => {
    return [
      {
        id: 'TTS_SEND_SAMPLE_TO_CREATOR',
        labelMsg: 'product_delivery_modal.free_sample',
        isChecked: option === 'TTS_SEND_SAMPLE_TO_CREATOR',
      },
      {
        id: 'TTS_SEND_SAMPLE_TO_CREATOR_WITHOUT_APPROVAL',
        labelMsg: 'product_delivery_modal.free_sample_without_approve',
        isChecked: option === 'TTS_SEND_SAMPLE_TO_CREATOR_WITHOUT_APPROVAL',
      },
      {
        id: 'TTS_OTHER',
        labelMsg: 'product_delivery_modal.other',
        isChecked: option === 'TTS_OTHER',
      },
    ];
  }, [option]);

  const handleProjectLaunch = () => {
    openDrawer(`project-draft-deadline-${projectId}`);
  };

  const handleDoneClick = () => {
    launchProject(
      { projectId, productDeliveryOption: option as ProductDeliveryOption },
      handleProjectLaunch
    );
  };

  return (
    <Drawer rootKey={`project-product-delivery-${projectId}`} className={styles.drawer}>
      <div className={styles.drawerContainer}>
        <Text type="d2" msg="product_delivery_modal.title" className={styles.title} />
        <div className={styles.content}>
          {list.map((item) => {
            const handleItemClick = () => {
              setOption(item.id);
            };
            return <CheckboxItem item={item} onClick={handleItemClick} />;
          })}
        </div>
        <Button
          disabled={!option}
          color="black"
          msg="product_delivery_modal.done"
          onClick={handleDoneClick}
        />
      </div>
    </Drawer>
  );
};

export default ProductDeliveryOption;
