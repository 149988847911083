import React, { MouseEvent, useContext } from 'react';
import { graphql, useFragment } from 'react-relay';

import styles from '../InviteOutreachCreators.pcss';
import { InviteOutreachCreatorsContext } from '../InviteOutreachCreatorsContext';

import { amplitude } from 'Helpers/amplitude';
import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import RadioButton from 'Components/ui/RadioButton/RadioButton';
import { SequenceSelectorForOutreach_list$key } from 'GraphTypes/SequenceSelectorForOutreach_list.graphql';

interface Props {
  query: SequenceSelectorForOutreach_list$key;
}

const SequenceSelector: React.FC<Props> = (props) => {
  const { query } = props;
  const { sequenceId, setSequenceEditorStatus, setSequenceId } = useContext(
    InviteOutreachCreatorsContext
  );
  const data = useFragment(
    graphql`
      fragment SequenceSelectorForOutreach_list on Campaign {
        organization {
          outreachSequenceBlueprints {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    `,
    query
  );

  const templates = data.organization?.outreachSequenceBlueprints?.edges;

  const handleSequenceClick = () => {
    setSequenceEditorStatus(true);
  };

  return (
    <>
      <div className={styles.attachTitle}>
        <Text type="md" msg="invite_outreach_creators_modal.step_2" />
      </div>
      <div className={styles.templates}>
        {templates?.map((item) => {
          if (!item?.node) return null;

          const { id, name } = item.node;

          const isActive = sequenceId === id;

          const handleTemplateSelect = () => {
            amplitude.sendEvent<411>({
              id: '411',
              category: 'campaign',
              name: 'outreach_sequence_click',
              param: { sequence_name: name },
            });
            setSequenceId(id);
          };

          return (
            <div className={styles.template} key={id} onClick={handleTemplateSelect}>
              <div className={styles.templateDetails}>
                <div>
                  <RadioButton checked={isActive} />
                </div>
                <Text type="md" text={name} />
              </div>
            </div>
          );
        })}
      </div>
      <AlterButton
        type="black"
        icon="Add-plus"
        msg="invite_outreach_creators_modal.add_sequence"
        className={styles.listCompleteButton}
        onClick={handleSequenceClick}
      />
      {sequenceId ? (
        <Text
          type="md"
          className={styles.secondaryText}
          msg="invite_outreach_creators_modal.sequence_subtext"
        />
      ) : null}
    </>
  );
};

export default SequenceSelector;
