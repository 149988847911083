import React, { useEffect, useState } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { fetchQuery } from 'relay-runtime';
import compact from 'lodash/compact';
import environment from 'Api/Environment';

import styles from './NotificationsBell.pcss';

import { amplitude } from 'Helpers/amplitude';
import { useMarkInAppNotificationsAsViewedMutation } from 'Mutations/MarkInAppNotificationsAsViewed.Mutation';
import NotificationItem from 'AdvertiserPage/NotificationCenter/NotificationsList/NotificationItem/NotificationItem';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import Icon from 'Components/ui/Icon/Icon';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import { NOTIFICATIONS_CENTER } from 'Constants/general';
import { NotificationsBellQuery as QueryType } from 'GraphTypes/NotificationsBellQuery.graphql';
import Text from 'Components/ui/Text/Text';
import NewNotification from 'Atoms/NewNotification/NewNotification';

const NotificationsBellQuery = graphql`
  query NotificationsBellQuery {
    currentUser {
      id
      unviewedCount: inAppNotifications(first: 1, viewed: false) {
        totalCount
      }
      inAppNotifications(first: 3, read: false) {
        edges {
          node {
            id
            read
            viewed
            ...NotificationItem_notification
          }
        }
      }
    }
  }
`;

const NotificationsBell = () => {
  const [opened, setOpened] = useState(false);
  const data = useLazyLoadQuery<QueryType>(NotificationsBellQuery, {});

  const [markInAppNotificationsAsViewed] = useMarkInAppNotificationsAsViewedMutation();

  const edges = data.currentUser?.inAppNotifications?.edges;

  const list = edges?.filter((item) => !item?.node?.read) || [];
  const isEmpty = !!(list.length === 0);

  const totalCount = data.currentUser?.unviewedCount?.totalCount;

  const notificationsList = list.map((item) => {
    if (!item?.node?.id) return null;

    const { id } = item.node;

    return (
      <DropdownItem key={id}>
        <NotificationItem notification={item.node} inDropdown />
      </DropdownItem>
    );
  });

  useEffect(() => {
    if (opened && edges) {
      const ids = compact(
        edges.map((item) => {
          return item?.node?.id && !item.node.viewed ? item?.node?.id : false;
        })
      );
      if (ids.length > 0) {
        markInAppNotificationsAsViewed({ variables: { input: { ids } } });
      }
    }
  }, [opened, edges]);

  const bell = (
    <Tooltip
      id={opened ? undefined : 'notification_bell'}
      place="bottom"
      tooltipMsg="header.notifications.tooltip"
      delayShow={600}
    >
      <div className={styles.iconWrap}>
        <Icon name="Bell-notifications" />
        {!!totalCount && (
          <NewNotification isActive={true} count={totalCount} className={styles.unreadMark} />
        )}
      </div>
    </Tooltip>
  );

  const handleDropdownOpen = () => {
    amplitude.sendEvent<425>({
      id: '425',
      category: 'notification_bell',
      name: 'click',
      param: { has_unread: !!totalCount, unviewed: totalCount || 0 },
    });
    setOpened(true);
  };

  const handleDropdownClose = () => {
    setOpened(false);
    fetchQuery<QueryType>(
      environment,
      NotificationsBellQuery,
      {},
      { fetchPolicy: 'network-only' }
    ).subscribe({});
  };

  return (
    <div className={styles.root}>
      <Dropdown
        value={bell}
        showArrow={false}
        bordered={opened}
        valueClassName={styles.value}
        onOpen={handleDropdownOpen}
        onClose={handleDropdownClose}
      >
        <DropdownGroup className={styles.dropdownGroup} labelMsg="header.notifications.list.label">
          <div className={styles.content}>
            {isEmpty ? (
              <Text type="md" msg="header.notifications.list.empty" className={styles.emptyTitle} />
            ) : (
              notificationsList
            )}
            <SmartLink path={NOTIFICATIONS_CENTER} className={styles.btnWrap}>
              <AlterButton type="black" msg="header.notifications.go_to" className={styles.btn} />
            </SmartLink>
          </div>
        </DropdownGroup>
      </Dropdown>
    </div>
  );
};

export default NotificationsBell;
