import { commitMutation, graphql } from 'react-relay';

import {
  RequestBcaPermissionMutation,
  RequestBcaPermissionMutation$data,
  RequestBcaPermissionInput,
} from 'GraphTypes/RequestBcaPermissionMutation.graphql';
import environment from 'Api/Environment';

const mutation = graphql`
  mutation RequestBcaPermissionMutation($input: RequestBcaPermissionInput!) {
    requestBcaPermission(input: $input) {
      bcaRequest {
        permission {
          id
          state
        }
      }
    }
  }
`;

type FnType = (data: object) => void;

export default (
  data: RequestBcaPermissionInput,
  resolve?: (response: RequestBcaPermissionMutation$data) => void,
  reject?: FnType
) => {
  const variables = {
    input: {
      ...data,
    },
  };

  commitMutation<RequestBcaPermissionMutation>(environment, {
    mutation,
    variables,
    onCompleted: (response, errors) => {
      if (errors && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};
