import { UpdateOutreachTemplateMutation } from 'GraphTypes/UpdateOutreachTemplateMutation.graphql';
import { graphql, useMutation } from 'react-relay';

import type { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const UpdateOutreachTemplateMutation = graphql`
  mutation UpdateOutreachTemplateMutation($input: UpdateOutreachTemplateInput!) {
    updateOutreachTemplate(input: $input) {
      ... on UpdateOutreachTemplatePayload {
        outreachTemplate {
          id
          htmlBody
          subject
        }
      }
    }
  }
`;

export const useUpdateOutreachTemplateMutation: BaseMutation<UpdateOutreachTemplateMutation> = (
  config
) => {
  const [updateOutreachTemplate, loading] = useMutation<UpdateOutreachTemplateMutation>(
    UpdateOutreachTemplateMutation
  );

  const updateOutreachTemplateMutationCaller: BaseMutationExecFn<UpdateOutreachTemplateMutation> = (
    execConfig
  ) => {
    updateOutreachTemplate({
      ...config,
      ...execConfig,
    });
  };
  return [updateOutreachTemplateMutationCaller, loading];
};
