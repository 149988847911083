import { graphql, useMutation } from 'react-relay';

import { MarkInAppNotificationsAsViewedMutation } from 'GraphTypes/MarkInAppNotificationsAsViewedMutation.graphql';
import type { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const MarkInAppNotificationsAsViewedMutation = graphql`
  mutation MarkInAppNotificationsAsViewedMutation(
    $input: Notifications_MarkInAppNotificationsAsViewedInput!
  ) {
    notifications {
      markInAppNotificationsAsViewed(input: $input) {
        clientMutationId
      }
    }
  }
`;

export const useMarkInAppNotificationsAsViewedMutation: BaseMutation<
  MarkInAppNotificationsAsViewedMutation
> = (config) => {
  const [markInAppNotificationsAsViewedQuery, loading] =
    useMutation<MarkInAppNotificationsAsViewedMutation>(MarkInAppNotificationsAsViewedMutation);

  const markInAppNotificationsAsViewedCaller: BaseMutationExecFn<
    MarkInAppNotificationsAsViewedMutation
  > = (execConfig) => {
    markInAppNotificationsAsViewedQuery({
      ...config,
      ...execConfig,
    });
  };
  return [markInAppNotificationsAsViewedCaller, loading];
};
