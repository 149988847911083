import React, { Suspense, useContext, useEffect, useMemo } from 'react';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useLazyLoadQuery, graphql } from 'react-relay';
import classNames from 'classnames';
import styles from './InviteOutreachCreators.pcss';

import { amplitude } from 'Helpers/amplitude';
import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { InviteOutreachCreatorsQuery as QueryType } from 'GraphTypes/InviteOutreachCreatorsQuery.graphql';

import CreatorsCsvUploader from './CreatorsCsvUploader/CreatorsCsvUploader';
import {
  InviteOutreachCreatorsContext,
  InviteOutreachCreatorsSource,
  InviteOutreachTemplateItemData,
} from './InviteOutreachCreatorsContext';
import TemplateSelector from './SequenceSelector/SequenceSelector';
import GmailSettings from './GmailSettings/GmailSettings';
import RowErrors from './RowErrors/RowErrors';
import Page from 'Templates/Page/Page';
import TemplatePreview from './TemplatePreview/TemplatePreview';
import CreatorsManualUploader from './CreatorsManualUploader/CreatorsManualUploader';
import CreatorsListSelector from './CreatorsListSelector/CreatorsListSelector';
import CreatorsDiscoverySelection from './CreatorsDiscoverySelection/CreatorsDiscoverySelection';
import { OUTREACH_LIST_ROUTE } from 'Constants/general';
import SequenceEditor from './SequenceEditor/SequenceEditor';

const InviteOutreachCreatorsQuery = graphql`
  query InviteOutreachCreatorsQuery($campaignId: ID!) {
    currentUser {
      organization {
        id
      }
    }
    campaign(id: $campaignId) {
      ...GmailSettingsForOutreach_campaign
      ...SequenceSelectorForOutreach_list
      platform
      sampleOutreachTemplateVariables {
        name
        value
      }
    }
  }
`;

const InviteOutreachCreators: React.FC = () => {
  const { id: campaignId = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();

  const fromDiscovery = location?.state?.fromDiscovery;
  const {
    source,
    selectedLists,
    fileError,
    isFormValid,
    rows,
    error,
    showRowErrors,
    locale,
    transport,
    processing,
    templateData,
    templatePreview,
    setTemplatePreview,
    setSource,
    setPlatform,
    handleImportOutreachBatch,
    sequenceId,
    isSequenceEditing,
    setSequenceEditorStatus,
    createOutreachBatchLoading,
  } = useContext(InviteOutreachCreatorsContext);

  const queryData = useLazyLoadQuery<QueryType>(InviteOutreachCreatorsQuery, { campaignId });
  const platform = queryData.campaign?.platform;
  const organizationId = queryData.currentUser?.organization?.id;
  const sampleOutreachTemplateVariables = queryData.campaign?.sampleOutreachTemplateVariables;

  const templateVars = useMemo(() => {
    return sampleOutreachTemplateVariables?.reduce((acc, item) => {
      return { ...acc, [item.name]: item.value };
    }, {}) as TemplateSpecification;
  }, [sampleOutreachTemplateVariables]);

  useEffect(() => {
    if (platform) {
      setPlatform(platform);
    }
  }, [platform]);

  useEffect(() => {
    if (fromDiscovery) {
      setSource(InviteOutreachCreatorsSource.LISTS);
    }
  }, [fromDiscovery]);

  const handleSendClick = () => {
    amplitude.sendEvent<412>({
      id: '412',
      category: 'campaign',
      name: 'outreach_send_click',
      param: { sequence_id: sequenceId },
    });
    handleImportOutreachBatch(campaignId);
  };

  const handlePreviewClick = () => {
    setTemplatePreview(templateData as InviteOutreachTemplateItemData);
  };

  const handleBackToBatches = () => {
    const outreachUrl = generatePath(OUTREACH_LIST_ROUTE, { id: campaignId });
    navigate(outreachUrl);
  };

  /*const locales = [
    { id: 'EN', msg: 'invite_outreach_creators_modal.locale.en' },
    { id: 'ES', msg: 'invite_outreach_creators_modal.locale.es' },
  ];

  const dropdownLabel = locale
    ? { msg: `invite_outreach_creators_modal.locale.${locale.toLowerCase()}` }
    : { msg: 'invite_outreach_creators_modal.locale.placeholder' };*/

  if (!queryData.campaign) return null;
  const isAllowToShowTemplates = Boolean(
    (rows?.length > 0 &&
      locale &&
      !fileError &&
      (source === InviteOutreachCreatorsSource.CSV_FILE ||
        source === InviteOutreachCreatorsSource.MANUALLY)) ||
      (source === InviteOutreachCreatorsSource.LISTS && selectedLists.length)
  );

  return (
    <Page className={styles.root}>
      {showRowErrors ? (
        <RowErrors />
      ) : (
        <>
          <div className={styles.content}>
            <div className={styles.titleContainer}>
              <div className={styles.backContainer}>
                <AlterButton
                  icon="Arrow-big-left"
                  className={styles.resetTemplate}
                  onClick={handleBackToBatches}
                />
                <Text
                  type="d2"
                  msg="invite_outreach_creators_modal.title"
                  className={styles.title}
                />
              </div>
              <div className={styles.backContainer}>
                <AlterButton
                  type="white"
                  icon={'Eye'}
                  disabled={!templateData.id}
                  msg="invite_outreach_creators_modal.preview"
                  onClick={handlePreviewClick}
                />
                <AlterButton
                  type="black"
                  icon={'Rocket'}
                  loading={createOutreachBatchLoading || processing}
                  disabled={!isFormValid}
                  msg="invite_outreach_creators_modal.send"
                  onClick={handleSendClick}
                />
              </div>
            </div>

            <div className={classNames(styles.content, styles.inviteFields)}>
              {source === InviteOutreachCreatorsSource.DISCOVERY && (
                <CreatorsDiscoverySelection organizationId={organizationId} />
              )}
              {source === InviteOutreachCreatorsSource.LISTS && (
                <Suspense fallback={null}>
                  <CreatorsListSelector />
                </Suspense>
              )}
              {source === InviteOutreachCreatorsSource.CSV_FILE && <CreatorsCsvUploader />}
              {source === InviteOutreachCreatorsSource.MANUALLY && <CreatorsManualUploader />}
              {isAllowToShowTemplates ? <TemplateSelector query={queryData.campaign} /> : null}

              {Boolean(sequenceId && transport === 'GMAIL') && (
                <GmailSettings query={queryData.campaign} />
              )}
              {Boolean(error) && (
                <Text
                  type="md"
                  msg={`invite_outreach_creators_modal.title.error.${error}`}
                  className={styles.commonError}
                />
              )}
            </div>
          </div>
          {isSequenceEditing && (
            <SequenceEditor variables={templateVars} organizationId={organizationId} />
          )}
          {templatePreview && <TemplatePreview variables={templateVars} />}
        </>
      )}
    </Page>
  );
};

export default InviteOutreachCreators;
