/**
 * @generated SignedSource<<43c48bdcab3c7ed785211c3afad66222>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OutreachBatchState = "ACTIVE" | "INITIAL" | "%future added value";
export type OutreachTransport = "GMAIL" | "SENDGRID" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OutreachBatch_outreachBatch$data = {
  readonly createdAt: any;
  readonly gmailAuthorization: {
    readonly id: string;
    readonly reauthenticationRequired: boolean;
  } | null | undefined;
  readonly id: string;
  readonly sequenceBlueprint: {
    readonly name: string;
    readonly steps: {
      readonly totalCount: number;
    } | null | undefined;
  } | null | undefined;
  readonly sequences: {
    readonly totalCount: number;
  };
  readonly state: OutreachBatchState;
  readonly transport: OutreachTransport;
  readonly " $fragmentType": "OutreachBatch_outreachBatch";
};
export type OutreachBatch_outreachBatch$key = {
  readonly " $data"?: OutreachBatch_outreachBatch$data;
  readonly " $fragmentSpreads": FragmentRefs<"OutreachBatch_outreachBatch">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OutreachBatch_outreachBatch",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transport",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GmailAuthorization",
      "kind": "LinkedField",
      "name": "gmailAuthorization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reauthenticationRequired",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OutreachSequenceConnection",
      "kind": "LinkedField",
      "name": "sequences",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OutreachSequenceBlueprint",
      "kind": "LinkedField",
      "name": "sequenceBlueprint",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OutreachStepBlueprintConnection",
          "kind": "LinkedField",
          "name": "steps",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OutreachBatch",
  "abstractKey": null
};
})();

(node as any).hash = "3e02df3d19fa5cce25bc6aa3c1c94abf";

export default node;
