/**
 * @generated SignedSource<<bedb740f948912fe941cc93e35eedb6c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CampaignPlatform = "FACEBOOK" | "INSTAGRAM" | "OTHER" | "SNAPCHAT" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING" | "%future added value";
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
export type ProductDeliveryOption = "BRAND_WILL_HANDLE_SHIPMENT_TO_CREATORS" | "BRAND_WILL_REFUND_THE_PURCHASED_PRODUCT" | "COLLABORATION_WILL_NOT_REQUIRE_PRODUCT_SHIPMENT" | "TTS_OTHER" | "TTS_SEND_SAMPLE_TO_CREATOR" | "TTS_SEND_SAMPLE_TO_CREATOR_WITHOUT_APPROVAL" | "%future added value";
export type CampaignTabsQuery$variables = {
  id: string;
};
export type CampaignTabsQuery$data = {
  readonly campaign: {
    readonly applicants: {
      readonly totalCount: number;
    };
    readonly brief: {
      readonly productDelivery?: ProductDeliveryOption | null | undefined;
    } | null | undefined;
    readonly completed: {
      readonly totalCount: number;
    };
    readonly completion: {
      readonly id: string;
    } | null | undefined;
    readonly hired: {
      readonly totalCount: number;
    };
    readonly id: string;
    readonly organization: {
      readonly paymentAccount: {
        readonly currency: Currency;
      } | null | undefined;
      readonly shopifyAuthorization: {
        readonly id: string;
      } | null | undefined;
      readonly shopifyAuthorizations: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
    } | null | undefined;
    readonly platform: CampaignPlatform | null | undefined;
    readonly private: boolean | null | undefined;
    readonly publications: {
      readonly totalCount: number;
    };
    readonly shopifyAuthorization: {
      readonly id: string;
    } | null | undefined;
    readonly showShipmentTab: boolean;
    readonly type: CampaignType | null | undefined;
  } | null | undefined;
};
export type CampaignTabsQuery = {
  response: CampaignTabsQuery$data;
  variables: CampaignTabsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "private",
  "storageKey": null
},
v4 = [
  (v2/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "CampaignCompletion",
  "kind": "LinkedField",
  "name": "completion",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ShopifyAuthorization",
  "kind": "LinkedField",
  "name": "shopifyAuthorization",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showShipmentTab",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "ShopifyAuthorizationConnection",
  "kind": "LinkedField",
  "name": "shopifyAuthorizations",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ShopifyAuthorizationEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ShopifyAuthorization",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productDelivery",
      "storageKey": null
    }
  ],
  "type": "V2Brief",
  "abstractKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "platform",
  "storageKey": null
},
v13 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v14 = {
  "alias": "publications",
  "args": null,
  "concreteType": "CreativeConnection",
  "kind": "LinkedField",
  "name": "creatives",
  "plural": false,
  "selections": (v13/*: any*/),
  "storageKey": null
},
v15 = {
  "kind": "Literal",
  "name": "archived",
  "value": false
},
v16 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": "LAST_ADDED"
},
v17 = {
  "kind": "Literal",
  "name": "withUnreadEvents",
  "value": true
},
v18 = {
  "alias": "applicants",
  "args": [
    (v15/*: any*/),
    (v16/*: any*/),
    {
      "kind": "Literal",
      "name": "stages",
      "value": [
        "ACCEPTED"
      ]
    },
    (v17/*: any*/)
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v13/*: any*/),
  "storageKey": "projects(archived:false,orderBy:\"LAST_ADDED\",stages:[\"ACCEPTED\"],withUnreadEvents:true)"
},
v19 = {
  "alias": "hired",
  "args": [
    (v15/*: any*/),
    (v16/*: any*/),
    {
      "kind": "Literal",
      "name": "stages",
      "value": [
        "LAUNCHED",
        "CONTENT_SUBMITTED",
        "PUBLISHING_STARTED"
      ]
    },
    (v17/*: any*/)
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v13/*: any*/),
  "storageKey": "projects(archived:false,orderBy:\"LAST_ADDED\",stages:[\"LAUNCHED\",\"CONTENT_SUBMITTED\",\"PUBLISHING_STARTED\"],withUnreadEvents:true)"
},
v20 = {
  "alias": "completed",
  "args": [
    (v15/*: any*/),
    (v16/*: any*/),
    {
      "kind": "Literal",
      "name": "stages",
      "value": [
        "COMPLETED"
      ]
    },
    (v17/*: any*/)
  ],
  "concreteType": "ProjectConnection",
  "kind": "LinkedField",
  "name": "projects",
  "plural": false,
  "selections": (v13/*: any*/),
  "storageKey": "projects(archived:false,orderBy:\"LAST_ADDED\",stages:[\"COMPLETED\"],withUnreadEvents:true)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CampaignTabsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Payment_Account",
                "kind": "LinkedField",
                "name": "paymentAccount",
                "plural": false,
                "selections": [
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "brief",
            "plural": false,
            "selections": [
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          (v12/*: any*/),
          (v14/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CampaignTabsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Campaign",
        "kind": "LinkedField",
        "name": "campaign",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Payment_Account",
                "kind": "LinkedField",
                "name": "paymentAccount",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              (v10/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "brief",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v11/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v12/*: any*/),
          (v14/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fce48e51ec1819023dbf4005aaa5dbea",
    "id": null,
    "metadata": {},
    "name": "CampaignTabsQuery",
    "operationKind": "query",
    "text": "query CampaignTabsQuery(\n  $id: ID!\n) {\n  campaign(id: $id) {\n    id\n    private\n    completion {\n      id\n    }\n    type\n    shopifyAuthorization {\n      id\n    }\n    showShipmentTab\n    organization {\n      paymentAccount {\n        currency\n        id\n      }\n      shopifyAuthorization {\n        id\n      }\n      shopifyAuthorizations {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n      id\n    }\n    brief {\n      __typename\n      ... on V2Brief {\n        productDelivery\n      }\n      id\n    }\n    platform\n    publications: creatives {\n      totalCount\n    }\n    applicants: projects(stages: [ACCEPTED], archived: false, withUnreadEvents: true, orderBy: LAST_ADDED) {\n      totalCount\n    }\n    hired: projects(stages: [LAUNCHED, CONTENT_SUBMITTED, PUBLISHING_STARTED], archived: false, withUnreadEvents: true, orderBy: LAST_ADDED) {\n      totalCount\n    }\n    completed: projects(stages: [COMPLETED], archived: false, withUnreadEvents: true, orderBy: LAST_ADDED) {\n      totalCount\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "64d4887299041caf35baeaa72f26a015";

export default node;
