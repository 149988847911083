import { useIntl } from 'react-intl';
import { capitalizeText } from 'Util/textFormatter';

import { Filters } from './AdminCreativesFilters';

import { RUB, USD, CAD, INSTAGRAM, FACEBOOK, OTHER, TIKTOK } from 'Constants/general';
import {
  CampaignPlatform,
  CampaignUseCase,
  FileType,
} from 'GraphTypes/AdminCreativeLibraryQuery.graphql';

export const getFileType = (fileType?: FileType | null) => {
  return [
    {
      id: 'IMAGE',
      isChecked: fileType === 'IMAGE',
      value: capitalizeText('IMAGE'),
    },
    {
      id: 'VIDEO',
      isChecked: fileType === 'VIDEO',
      value: capitalizeText('VIDEO'),
    },
  ];
};

export const getPlatformList = (platform?: CampaignPlatform | null) => {
  return [
    {
      id: INSTAGRAM,
      isChecked: platform === INSTAGRAM,
      value: capitalizeText(INSTAGRAM),
    },
    {
      id: FACEBOOK,
      isChecked: platform === FACEBOOK,
      value: capitalizeText(FACEBOOK),
    },
    {
      id: TIKTOK,
      isChecked: platform === TIKTOK,
      value: capitalizeText(TIKTOK),
    },
    {
      id: OTHER,
      isChecked: platform === OTHER,
      value: capitalizeText(OTHER),
    },
  ];
};

export const getCampaignUsecases = (useCases?: CampaignUseCase[] | null) => {
  const useCasesList = useCases || [];
  return [
    {
      id: 'AFFILIATE_CAMPAIGN',
      isChecked: useCasesList.includes('AFFILIATE_CAMPAIGN'),
      value: 'AFFILIATE_CAMPAIGN',
      labelMsg: 'campaign.preview.type.affiliate_campaign',
    },
    {
      id: 'INFLUENCER_POSTS',
      isChecked: useCasesList.includes('INFLUENCER_POSTS'),
      value: 'INFLUENCER_POSTS',
      labelMsg: 'campaign.preview.type.influencer',
    },
    {
      id: 'META_PARTNERSHIP_ADS',
      isChecked: useCasesList.includes('META_PARTNERSHIP_ADS'),
      value: 'META_PARTNERSHIP_ADS',
      labelMsg: 'campaign.preview.type.meta_partnership_ads',
    },
    {
      id: 'PRODUCT_SEEDING',
      isChecked: useCasesList.includes('PRODUCT_SEEDING'),
      value: 'PRODUCT_SEEDING',
      labelMsg: 'campaign.preview.type.product_seeding',
    },
    {
      id: 'TIKTOK_SHOP',
      isChecked: useCasesList.includes('TIKTOK_SHOP'),
      value: 'TIKTOK_SHOP',
      labelMsg: 'campaign.preview.type.tiktok_shop',
    },
    {
      id: 'TIKTOK_SPARK_ADS',
      isChecked: useCasesList.includes('TIKTOK_SPARK_ADS'),
      value: 'TIKTOK_SPARK_ADS',
      labelMsg: 'campaign.preview.type.tiktok_spark_ads',
    },
    {
      id: 'USER_GENERATED_CONTENT',
      isChecked: useCasesList.includes('USER_GENERATED_CONTENT'),
      value: 'USER_GENERATED_CONTENT',
      labelMsg: 'campaign.preview.type.user_generated_content',
    },
  ];
};

export const getFiltersData = (filters: Filters) => {
  const { currencies = [], adminFavoriteCreatives, adminFavoriteCampaigns, paidSocial } = filters;

  const intl = useIntl();

  return {
    currencies: [
      {
        id: USD,
        isChecked: currencies.includes(USD),
        value: USD,
        label: USD,
      },
      {
        id: CAD,
        isChecked: currencies.includes(CAD),
        value: CAD,
        label: CAD,
      },
      {
        id: RUB,
        isChecked: currencies.includes(RUB),
        value: RUB,
        label: RUB,
      },
    ],
    additional: [
      {
        id: 'adminFavoriteCreatives',
        isChecked: !!adminFavoriteCreatives,
        value: intl.formatMessage({
          id: 'search_section.form.adminFavoriteCreatives',
        }),
        labelMsg: 'search_section.form.adminFavoriteCreatives',
      },
      {
        id: 'adminFavoriteCampaigns',
        isChecked: !!adminFavoriteCampaigns,
        value: intl.formatMessage({
          id: 'search_section.form.adminFavoriteCampaigns',
        }),
        labelMsg: 'search_section.form.adminFavoriteCampaigns',
      },
      {
        id: 'paidSocial',
        isChecked: !!paidSocial,
        value: intl.formatMessage({
          id: 'search_section.form.paidSocial',
        }),
        labelMsg: 'search_section.form.paidSocial',
      },
    ],
  };
};
