/**
 * @generated SignedSource<<98413ed4bf6e781f7d43dbc2ffbd7284>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CreatorType = "CONTENT" | "INSTAGRAM" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type CreatorForNewUseCaseQuery$variables = {
  id: string;
};
export type CreatorForNewUseCaseQuery$data = {
  readonly creator: {
    readonly completedProjectsCount: number;
    readonly id: string;
    readonly profile: {
      readonly badges: ReadonlyArray<{
        readonly name: string;
      }> | null | undefined;
      readonly profilePictureFile: {
        readonly transformations?: {
          readonly collageThumbnailUrl: string;
        } | null | undefined;
        readonly url: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
    readonly type: CreatorType;
    readonly username: string;
  } | null | undefined;
};
export type CreatorForNewUseCaseQuery = {
  response: CreatorForNewUseCaseQuery$data;
  variables: CreatorForNewUseCaseQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completedProjectsCount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v8 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ImageTransformation",
      "kind": "LinkedField",
      "name": "transformations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "collageThumbnailUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Image",
  "abstractKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreatorForNewUseCaseQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "creator",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CreatorProfile",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Badge",
                "kind": "LinkedField",
                "name": "badges",
                "plural": true,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "profilePictureFile",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreatorForNewUseCaseQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "creator",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CreatorProfile",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Badge",
                "kind": "LinkedField",
                "name": "badges",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "profilePictureFile",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ebe64453edf5ce5113ef34862d6d287d",
    "id": null,
    "metadata": {},
    "name": "CreatorForNewUseCaseQuery",
    "operationKind": "query",
    "text": "query CreatorForNewUseCaseQuery(\n  $id: ID!\n) {\n  creator(id: $id) {\n    __typename\n    id\n    username\n    type\n    completedProjectsCount\n    profile {\n      badges {\n        name\n        id\n      }\n      profilePictureFile {\n        __typename\n        url\n        ... on Image {\n          transformations {\n            collageThumbnailUrl\n          }\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5ca40dde4961d9bcd525b76a39dd7bce";

export default node;
