/**
 * @generated SignedSource<<f218e27eff82a058ceec7700764c3292>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AdvertiserProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "READ" | "REJECTED" | "UNREAD" | "UNSENT" | "%future added value";
export type CreatorType = "CONTENT" | "INSTAGRAM" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
export type AddCreatorsToCustomListBySearchInput = {
  clientMutationId?: string | null | undefined;
  customListId: string;
  id?: string | null | undefined;
  limit?: number | null | undefined;
  searchQuery: SearchQueryInput;
};
export type SearchQueryInput = {
  acceptsBarter?: boolean | null | undefined;
  active?: boolean | null | undefined;
  ageRangeIds?: ReadonlyArray<string> | null | undefined;
  audienceAgeRangePercentage?: number | null | undefined;
  audienceCitiesIds?: ReadonlyArray<string> | null | undefined;
  audienceCityPercentage?: number | null | undefined;
  audienceCountryPercentage?: number | null | undefined;
  audienceEthnicityIds?: ReadonlyArray<string> | null | undefined;
  audienceGenderId?: string | null | undefined;
  audienceGenderPercentage?: number | null | undefined;
  audienceInterestPercentage?: number | null | undefined;
  businessAccountEnabled?: boolean | null | undefined;
  campaignId?: string | null | undefined;
  campaignsCompletionsCountFrom?: number | null | undefined;
  campaignsCompletionsCountTo?: number | null | undefined;
  categoryIds?: ReadonlyArray<string> | null | undefined;
  cityIds?: ReadonlyArray<string> | null | undefined;
  contentCreator?: boolean | null | undefined;
  countryIds?: ReadonlyArray<string> | null | undefined;
  creatorAnimalIds?: ReadonlyArray<string> | null | undefined;
  creatorBadgeIds?: ReadonlyArray<string> | null | undefined;
  creatorBirthdateFrom?: any | null | undefined;
  creatorBirthdateTo?: any | null | undefined;
  creatorBodyTypeIds?: ReadonlyArray<string> | null | undefined;
  creatorCountryIds?: ReadonlyArray<string> | null | undefined;
  creatorEthnicityIds?: ReadonlyArray<string> | null | undefined;
  creatorGenderId?: string | null | undefined;
  creatorHairTypeIds?: ReadonlyArray<string> | null | undefined;
  creatorIdsToExclude?: ReadonlyArray<string> | null | undefined;
  creatorLanguageIds?: ReadonlyArray<string> | null | undefined;
  creatorTagIds?: ReadonlyArray<string> | null | undefined;
  creatorTypes?: ReadonlyArray<CreatorType> | null | undefined;
  currency?: Currency | null | undefined;
  customListIds?: ReadonlyArray<string> | null | undefined;
  employed?: boolean | null | undefined;
  engagementRateFrom?: number | null | undefined;
  engagementRateTo?: number | null | undefined;
  excludeBadges?: boolean | null | undefined;
  excludeCreatorsWorkedWith?: boolean | null | undefined;
  excludeHiredMoreThanOnce?: boolean | null | undefined;
  exclusions?: ReadonlyArray<SearchQueryExclusionParams> | null | undefined;
  experienced?: boolean | null | undefined;
  favorite?: boolean | null | undefined;
  followersFrom?: number | null | undefined;
  followersTo?: number | null | undefined;
  hashtags?: ReadonlyArray<string> | null | undefined;
  highAverageRating?: boolean | null | undefined;
  includeAmazonStorefrontLink?: boolean | null | undefined;
  includeHidden?: boolean | null | undefined;
  interestIds?: ReadonlyArray<string> | null | undefined;
  limit?: number | null | undefined;
  onlyCreatorsWorkedWith?: boolean | null | undefined;
  owned?: boolean | null | undefined;
  paidSocialEnabled?: boolean | null | undefined;
  postPriceFrom?: number | null | undefined;
  postPriceTo?: number | null | undefined;
  postsReachFrom?: number | null | undefined;
  postsReachTo?: number | null | undefined;
  realFollowersFrom?: number | null | undefined;
  realFollowersTo?: number | null | undefined;
  reelsPlaysMedianFrom?: number | null | undefined;
  reelsPlaysMedianTo?: number | null | undefined;
  reliable?: boolean | null | undefined;
  reviewedAtFrom?: any | null | undefined;
  reviewedAtTo?: any | null | undefined;
  searchableForOutreach?: boolean | null | undefined;
  storiesReachFrom?: number | null | undefined;
  storiesReachTo?: number | null | undefined;
  strictlyByPrimaryCategory?: boolean | null | undefined;
  textQuery?: string | null | undefined;
  viewsMedianFrom?: number | null | undefined;
  viewsMedianTo?: number | null | undefined;
  viewsPerPostFrom?: number | null | undefined;
  viewsPerPostTo?: number | null | undefined;
  withTiktokShop?: boolean | null | undefined;
};
export type SearchQueryExclusionParams = {
  advertiserProjectStages?: ReadonlyArray<AdvertiserProjectStage> | null | undefined;
  campaignId: string;
};
export type AddCreatorsToCustomListBySearchMutation$variables = {
  input: AddCreatorsToCustomListBySearchInput;
};
export type AddCreatorsToCustomListBySearchMutation$data = {
  readonly addCreatorsToCustomListBySearch: {
    readonly __typename: "AddCreatorsToCustomListPayload";
    readonly customList: {
      readonly creators: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly customLists: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly id: string;
                  readonly name: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly id: string;
            readonly username: string;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type AddCreatorsToCustomListBySearchMutation = {
  response: AddCreatorsToCustomListBySearchMutation$data;
  variables: AddCreatorsToCustomListBySearchMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "CustomListConnection",
  "kind": "LinkedField",
  "name": "customLists",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomListEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomList",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddCreatorsToCustomListBySearchMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "addCreatorsToCustomListBySearch",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomList",
                "kind": "LinkedField",
                "name": "customList",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CreatorConnection",
                    "kind": "LinkedField",
                    "name": "creators",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CreatorEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "AddCreatorsToCustomListPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddCreatorsToCustomListBySearchMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "addCreatorsToCustomListBySearch",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomList",
                "kind": "LinkedField",
                "name": "customList",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CreatorConnection",
                    "kind": "LinkedField",
                    "name": "creators",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CreatorEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "AddCreatorsToCustomListPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7b283f9b6a088001d3b386dd09d1be73",
    "id": null,
    "metadata": {},
    "name": "AddCreatorsToCustomListBySearchMutation",
    "operationKind": "mutation",
    "text": "mutation AddCreatorsToCustomListBySearchMutation(\n  $input: AddCreatorsToCustomListBySearchInput!\n) {\n  addCreatorsToCustomListBySearch(input: $input) {\n    __typename\n    ... on AddCreatorsToCustomListPayload {\n      customList {\n        creators {\n          edges {\n            node {\n              __typename\n              id\n              username\n              customLists {\n                edges {\n                  node {\n                    id\n                    name\n                  }\n                }\n              }\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1687c84c0d9ebf9d404c54b9da3e0c74";

export default node;
