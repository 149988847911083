import { graphql, useMutation, UseMutationConfig } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';

import { AddCreatorsToCustomListBySearchMutation } from 'GraphTypes/AddCreatorsToCustomListBySearchMutation.graphql';
import { BaseMutationExecFn } from 'Types/utils';

const mutation = graphql`
  mutation AddCreatorsToCustomListBySearchMutation($input: AddCreatorsToCustomListBySearchInput!) {
    addCreatorsToCustomListBySearch(input: $input) {
      __typename
      ... on AddCreatorsToCustomListPayload {
        customList {
          creators {
            edges {
              node {
                id
                username
                customLists {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useAddCreatorsToCustomListBySearchMutation = (
  organizationId?: string,
  config?: UseMutationConfig<AddCreatorsToCustomListBySearchMutation>
): [BaseMutationExecFn<AddCreatorsToCustomListBySearchMutation>, boolean] => {
  const [addCreatorsToCustomListBySearch, loading] =
    useMutation<AddCreatorsToCustomListBySearchMutation>(mutation);

  const addCreatorsToCustomListBySearchCaller: BaseMutationExecFn<
    AddCreatorsToCustomListBySearchMutation
  > = (execConfig) => {
    const { customListId } = execConfig.variables.input;
    if (!organizationId) return;
    addCreatorsToCustomListBySearch({
      ...config,
      ...execConfig,
    });
  };
  return [addCreatorsToCustomListBySearchCaller, loading];
};
