import React, { ChangeEvent, useContext, useEffect, useRef, useState, KeyboardEvent } from 'react';
import { v4 as uuid } from 'uuid';

import styles from './SequenceEditor.pcss';

import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import {
  InviteOutreachCreatorsContext,
  InviteOutreachCreatorsTemplate,
} from '../InviteOutreachCreatorsContext';
import SequenceItem from './SequenceItem/SequenceItem';
import TemplateEditor from '../TemplateEditor/TemplateEditor';
import SequenceNewItem from './SequenceItem/SequenceNewItem';
import CreateSequenceDrawer from './CreateSequenceDrawer/CreateSequenceDrawer';
import {
  CreateOutreachSequenceBlueprintMutation$data,
  OutreachStepBlueprint_ProjectEventType,
} from 'GraphTypes/CreateOutreachSequenceBlueprintMutation.graphql';
import { Locale } from 'GraphTypes/CreateOutreachTemplateMutation.graphql';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';

interface Props {
  organizationId: string;
  variables: TemplateSpecification;
  template?: InviteOutreachCreatorsTemplate;
}

export interface SequenceStep {
  id?: string;
  delayInDays?: number;
  projectEventType: OutreachStepBlueprint_ProjectEventType;
  startsAtHour: Date;
  validTemplate: boolean;
  template: InviteOutreachCreatorsTemplate;
}

const defaultDate = new Date();
defaultDate.setHours(12);
const emptyStep = {
  delayInDays: undefined,
  projectEventType: 'MESSAGE' as OutreachStepBlueprint_ProjectEventType,
  startsAtHour: defaultDate,
  validTemplate: true,
  template: {
    id: uuid(),
    htmlBody: '',
    subject: '',
    locale: 'EN' as Locale,
  },
};

const SequenceEditor: React.FC<Props> = (props) => {
  const { variables, organizationId } = props;
  const { openDrawer } = useContext(DrawerContext);
  const [sequenceList, setSequenceList] = useState<SequenceStep[]>([emptyStep]);
  const [activeStep, setActiveStep] = useState(0);
  const [stepsWithError, setStepsWithErrorList] = useState<number[]>([]);

  const { setSequenceEditorStatus, setSequenceId } = useContext(InviteOutreachCreatorsContext);

  const handleEditorTemplateClose = () => {
    setSequenceEditorStatus(false);
  };

  const addToSequenceNewElement = () => {
    setActiveStep(sequenceList.length);
    setSequenceList((list) => {
      return [...list, emptyStep];
    });
  };

  const handleAddEmailClick = () => {
    addToSequenceNewElement();
  };

  const handleStepSelect = (index: number) => {
    setActiveStep(index);
  };

  const handleStepRemove = (index: number) => {
    setSequenceList((list) => {
      return list.filter((_, idx) => idx !== activeStep);
    });
    if (index === 0) {
      setActiveStep(0);
    } else {
      setActiveStep(index - 1);
    }
  };

  const handleActiveElementChange = (data: Partial<SequenceStep>) => {
    setSequenceList((list) => {
      return list.map((item, idx) => {
        if (idx === activeStep) {
          return { ...item, ...data };
        }
        return item;
      });
    });
    setStepsWithErrorList((steps) => steps.filter((_, idx) => idx !== activeStep));
  };

  const handleSaveSequenceClick = () => {
    const invalidSteps = checkStepsValidation();
    if (invalidSteps.length) {
      setStepsWithErrorList(invalidSteps);
      return;
    }
    openDrawer('sequence-create');
  };

  const handleCreateSequenceComplete = (response: CreateOutreachSequenceBlueprintMutation$data) => {
    if (
      response.createOutreachSequenceBlueprint?.__typename ===
      'CreateOutreachSequenceBlueprintPayload'
    ) {
      const sequenceData = response.createOutreachSequenceBlueprint.outreachSequenceBlueprint;
      setSequenceId(sequenceData.id);
      setSequenceEditorStatus(false);
    }
  };

  const checkStepsValidation = () => {
    const stepsWithError: number[] = [];
    sequenceList.forEach((step, idx) => {
      if (!step.template?.subject || !step.validTemplate) {
        stepsWithError.push(idx);
      }
    });
    return stepsWithError;
  };

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.titleContainer}>
          <div className={styles.backContainer}>
            <AlterButton
              icon="Arrow-big-left"
              className={styles.resetTemplate}
              onClick={handleEditorTemplateClose}
            />
            <Text
              type="d2"
              className={styles.title}
              msg="invite_outreach_creators_modal.sequence_editor.title"
            />
          </div>
          <AlterButton
            type="black"
            msg="invite_outreach_creators_modal.editor.save"
            onClick={handleSaveSequenceClick}
          />
        </div>
        <div className={styles.editor}>
          <div className={styles.sequenceList}>
            {sequenceList.map((item, idx) => (
              <SequenceItem
                key={item.id || `${item.template?.id}_${idx}`}
                data={item}
                index={idx}
                error={stepsWithError.includes(idx)}
                isActive={idx === activeStep}
                isLastInList={sequenceList.length === 1}
                onSelect={handleStepSelect}
                onRemove={handleStepRemove}
              />
            ))}
            <SequenceNewItem onClick={handleAddEmailClick} />
          </div>

          <div className={styles.template}>
            {sequenceList.map((item, idx) => (
              <TemplateEditor
                index={idx}
                variables={variables}
                isActive={idx === activeStep}
                key={item.id || `${item.template?.id}_${idx}`}
                template={sequenceList[idx].template}
                onChangeData={handleActiveElementChange}
              />
            ))}
          </div>
        </div>
      </div>
      <CreateSequenceDrawer
        organizationId={organizationId}
        onCreateCompleted={handleCreateSequenceComplete}
        sequenceSteps={sequenceList}
      />
    </div>
  );
};

export default SequenceEditor;
