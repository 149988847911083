import React, { useEffect } from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import { useParams, useNavigate } from 'react-router-dom';

import CampaignContainer from './CampaignContainer/CampaignContainer';

import environment from 'Api/Environment';
import { getCampaignTypeLink } from 'Util/links';
import Page from 'Templates/Page/Page';
import Spinner from 'Atoms/Spinner/Spinner';
import { ERROR_ROUTE, BRIEF, CREATORS, APPLICANTS, COMPLETED } from 'Constants/general';
import { CampaignQuery as QueryType } from 'GraphTypes/CampaignQuery.graphql';
import { trackTime as custifyTrackTime } from 'Util/custify';

const CampaignQuery = graphql`
  query CampaignQuery($id: ID!) {
    currentUser {
      id
      admin
      permissions
      email
      organization {
        id
        paymentAccount {
          currency
        }
        subscription {
          planId
          effectiveLimits {
            maxBcaConnections
          }
          canCreateFacebookAds
          canBrowseCreators
          canUsePaidSocial
          canViewCreatorPrices
          canChatWithCreators
          canCreateMultipleDeals
          canFilterCreatorsByBadge
          canUseApprovalTool
          canManageLonglists
        }
      }
    }
    campaigns {
      totalCount
    }
    campaign(id: $id) {
      id
      name
      type
      platform
      preferredContentType
      preferredCreatorCategory
      publishingRequired
      briefSubmission {
        acceptance {
          id
        }
      }
      counters {
        launchedProjects
      }
      completion {
        id
      }
      organization {
        id
      }
      creator {
        id
        admin
        permissions
        organization {
          paymentAccount {
            currency
          }
          subscription {
            planId
            effectiveLimits {
              maxBcaConnections
            }
            canCreateFacebookAds
            canBrowseCreators
            canUsePaidSocial
            canViewCreatorPrices
            canChatWithCreators
            canCreateMultipleDeals
            canFilterCreatorsByBadge
            canUseApprovalTool
            canManageLonglists
          }
        }
      }
      ...InviteCreators_campaign
      ...CampaignInfo_campaign
    }
  }
`;

const Campaign: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    custifyTrackTime('Campaigns');
    return () => {
      _ctrack.stopTrackTime();
    };
  }, []);

  if (!id) return null;

  return (
    <Page>
      <QueryRenderer<QueryType>
        environment={environment}
        query={CampaignQuery}
        variables={{ id }}
        render={({ props: queryProps }) => {
          if (!queryProps) return <Spinner style={{ margin: '40px auto' }} />;

          const campaign = queryProps.campaign;

          if (!campaign) {
            navigate(ERROR_ROUTE);
          }

          const admin = queryProps.currentUser?.admin;

          const user = queryProps.currentUser;
          const creator = admin ? queryProps.campaign?.creator : queryProps.currentUser;
          const campaignsCount = queryProps.campaigns?.totalCount;
          const email = user?.email;

          const campaignId = campaign?.id;
          const publishingRequired = campaign?.publishingRequired;
          const briefSubmission = campaign?.briefSubmission;
          const launchedProjects = campaign?.counters?.launchedProjects;
          const isCompleted = campaign?.completion?.id;

          const organizationId = campaign?.organization?.id;

          const defaultType = isCompleted ? COMPLETED : launchedProjects ? CREATORS : APPLICANTS;

          if (!briefSubmission && campaignId) {
            const link = getCampaignTypeLink({ campaignId, path: BRIEF });
            navigate(link, { replace: true });

            return null;
          }

          return (
            <CampaignContainer
              campaign={campaign}
              admin={!!admin}
              user={user}
              defaultType={defaultType}
              creator={creator}
              email={email}
              campaignsCount={campaignsCount}
              publishingRequired={publishingRequired}
              organizationId={organizationId}
            />
          );
        }}
      />
    </Page>
  );
};

export default Campaign;
