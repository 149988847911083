/**
 * @generated SignedSource<<0ff20c793b430301b5418e537909651d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InAppNotificationCreatedSubscription$variables = Record<PropertyKey, never>;
export type InAppNotificationCreatedSubscription$data = {
  readonly inAppNotificationCreated: {
    readonly inAppNotification: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"NotificationItem_notification">;
    } | null | undefined;
    readonly user: {
      readonly inAppNotifications: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly read: boolean;
            readonly viewed: boolean;
            readonly " $fragmentSpreads": FragmentRefs<"NotificationItem_notification">;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly unreadCount: {
        readonly totalCount: number;
      } | null | undefined;
    } | null | undefined;
  };
};
export type InAppNotificationCreatedSubscription = {
  response: InAppNotificationCreatedSubscription$data;
  variables: InAppNotificationCreatedSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "NotificationItem_notification"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v3 = {
  "alias": "unreadCount",
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1
    },
    {
      "kind": "Literal",
      "name": "viewed",
      "value": false
    }
  ],
  "concreteType": "InAppNotificationConnection",
  "kind": "LinkedField",
  "name": "inAppNotifications",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": "inAppNotifications(first:1,viewed:false)"
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 3
  },
  {
    "kind": "Literal",
    "name": "read",
    "value": false
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "read",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewed",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "kind": "TypeDiscriminator",
  "abstractKey": "__isInAppNotification"
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ctaText",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Campaign",
  "kind": "LinkedField",
  "name": "campaign",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v13/*: any*/)
  ],
  "storageKey": null
},
v15 = {
  "kind": "InlineFragment",
  "selections": [
    (v14/*: any*/)
  ],
  "type": "InAppNotifications_CampaignActivated",
  "abstractKey": null
},
v16 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalApplicantsCount",
      "storageKey": null
    }
  ],
  "type": "InAppNotifications_DailyOfferAcceptances",
  "abstractKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "Project",
  "kind": "LinkedField",
  "name": "project",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v14/*: any*/)
  ],
  "storageKey": null
},
v18 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Creative",
      "kind": "LinkedField",
      "name": "creative",
      "plural": false,
      "selections": [
        (v17/*: any*/),
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "InAppNotifications_CreativeCreated",
  "abstractKey": null
},
v19 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Project",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Campaign",
          "kind": "LinkedField",
          "name": "campaign",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v13/*: any*/),
            (v12/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContentReview",
      "kind": "LinkedField",
      "name": "contentReview",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentReviewAttachmentConnection",
          "kind": "LinkedField",
          "name": "attachments",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CreativeConnection",
      "kind": "LinkedField",
      "name": "creatives",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    }
  ],
  "type": "InAppNotifications_ContentReviewSubmitted",
  "abstractKey": null
},
v20 = [
  (v17/*: any*/)
],
v21 = {
  "kind": "InlineFragment",
  "selections": (v20/*: any*/),
  "type": "InAppNotifications_DraftDeadlineOverdue",
  "abstractKey": null
},
v22 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Project",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Campaign",
          "kind": "LinkedField",
          "name": "campaign",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "InAppNotifications_BcaPermissionRevoked",
  "abstractKey": null
},
v23 = {
  "kind": "InlineFragment",
  "selections": (v20/*: any*/),
  "type": "InAppNotifications_AdvertiserUnresponsiveness",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "InAppNotificationCreatedSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InAppNotificationCreatedPayload",
        "kind": "LinkedField",
        "name": "inAppNotificationCreated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "inAppNotification",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "InAppNotificationConnection",
                "kind": "LinkedField",
                "name": "inAppNotifications",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InAppNotificationEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "inAppNotifications(first:3,read:false)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "InAppNotificationCreatedSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InAppNotificationCreatedPayload",
        "kind": "LinkedField",
        "name": "inAppNotificationCreated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "inAppNotification",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v0/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v12/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "InAppNotificationConnection",
                "kind": "LinkedField",
                "name": "inAppNotifications",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InAppNotificationEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v0/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v15/*: any*/),
                          (v16/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v21/*: any*/),
                          (v22/*: any*/),
                          (v23/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "inAppNotifications(first:3,read:false)"
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "878808408998f4dc768918c7a6be571d",
    "id": null,
    "metadata": {},
    "name": "InAppNotificationCreatedSubscription",
    "operationKind": "subscription",
    "text": "subscription InAppNotificationCreatedSubscription {\n  inAppNotificationCreated {\n    inAppNotification {\n      __typename\n      id\n      ...NotificationItem_notification\n    }\n    user {\n      unreadCount: inAppNotifications(first: 1, viewed: false) {\n        totalCount\n      }\n      inAppNotifications(first: 3, read: false) {\n        edges {\n          node {\n            __typename\n            id\n            read\n            viewed\n            ...NotificationItem_notification\n          }\n        }\n      }\n      id\n    }\n  }\n}\n\nfragment NotificationItem_notification on InAppNotification {\n  __isInAppNotification: __typename\n  id\n  createdAt\n  icon\n  ctaText\n  read\n  viewed\n  type\n  ... on InAppNotifications_CampaignActivated {\n    campaign {\n      id\n      name\n    }\n  }\n  ... on InAppNotifications_DailyOfferAcceptances {\n    id\n    totalApplicantsCount\n  }\n  ... on InAppNotifications_CreativeCreated {\n    creative {\n      project {\n        id\n        campaign {\n          id\n          name\n        }\n      }\n      id\n    }\n  }\n  ... on InAppNotifications_ContentReviewSubmitted {\n    project {\n      id\n      campaign {\n        id\n        name\n        type\n      }\n    }\n    contentReview {\n      attachments {\n        totalCount\n      }\n      id\n    }\n    creatives {\n      totalCount\n    }\n  }\n  ... on InAppNotifications_DraftDeadlineOverdue {\n    project {\n      id\n      campaign {\n        id\n        name\n      }\n    }\n  }\n  ... on InAppNotifications_BcaPermissionRevoked {\n    project {\n      id\n      campaign {\n        id\n      }\n    }\n  }\n  ... on InAppNotifications_AdvertiserUnresponsiveness {\n    project {\n      id\n      campaign {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "da3fa13d8d0cc2d8b991f705e8b2f020";

export default node;
