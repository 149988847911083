import React, { useState, useEffect, useMemo, useContext } from 'react';
import track, { useTracking } from 'react-tracking';
import { fetchQuery, graphql } from 'relay-runtime';
import { useLocation } from 'react-router-dom';

import styles from './SearchForm.pcss';
import {
  resettableParams,
  youtubeResettableParams,
  tiktokResettableParams,
  defaultParams,
  aminDefaultParams,
  onlyYoutubeParams,
  createDropDownItems,
  getData,
  paramsKit,
  getCreatorTypesList,
  isOnlyInstagramPlatformFilterAvailable,
  isOnlyTiktokPlatformFilterAvailable,
} from './data';

import Environment from 'Api/Environment';
import { getAges } from 'Util/dateCreator';
import icons from 'Icons';
import SearchParams from 'Components/SearchParams/SearchParams';
import DropDown from 'Molecules/DropDown/DropDown';
import Button from 'Atoms/Button/Button';
import Field from 'Molecules/Field/Field';
import CreatorExclusion from 'Organisms/CreatorExclusion/CreatorExclusion';
import { YOUTUBE, INSTAGRAM, TIKTOK } from 'Constants/general';
import { amplitude } from 'Helpers/amplitude';
import {
  SearchFiltersDataContext,
  Types as SearchFiltersDataTypes,
} from 'Containers/SearchFiltersData/SearchFiltersData.Context';

const SearchFormQuery = graphql`
  query SearchFormQuery($currency: Currency) {
    ageRanges {
      id
      name
    }
    interests {
      id
      name
    }
    countries {
      id
      name
    }
    genders(currency: RUB) {
      id
      name
    }
    ethnicities(visible: true) {
      id
      name
    }
    categories {
      id
      name
    }
    tags(currency: $currency) {
      id
      name
    }
    animals {
      id
      name
    }
    hairTypes {
      id
      name
    }
    languages {
      id
      name
    }
    bodyTypes {
      id
      name
    }
    badges {
      id
      name
    }
    currentUser {
      organization {
        customLists {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const SearchForm = (props) => {
  const {
    admin,
    className,
    currency,
    isYoutube,
    hasContentCreation,
    onParamsChange,
    organizationId,
    campaignId,
    campaignPlatform,
    campaignType,
    queryParams,
  } = props;

  const location = useLocation();

  const { state: searchFilterDataState, dispatch: searchFilterDataDispatch } =
    useContext(SearchFiltersDataContext);

  const defaultRouteData = location.state;

  const percantageFormattedQueryParams = {
    ...queryParams,
    engagementRateFrom: queryParams?.engagementRateFrom
      ? queryParams?.engagementRateFrom * 100
      : null,
    engagementRateTo: queryParams?.engagementRateTo ? queryParams?.engagementRateTo * 100 : null,
    realFollowersFrom: queryParams?.realFollowersFrom ? queryParams?.realFollowersFrom * 100 : null,
    realFollowersTo: queryParams?.realFollowersTo ? queryParams?.realFollowersTo * 100 : null,
  };

  const routeData = {
    ...defaultRouteData,
    creatorTags: defaultRouteData?.tags || [],
    engagementRateFrom:
      defaultRouteData && defaultRouteData.engagementRateFrom
        ? defaultRouteData.engagementRateFrom * 100
        : null,
    engagementRateTo:
      defaultRouteData && defaultRouteData.engagementRateTo
        ? defaultRouteData.engagementRateTo * 100
        : null,
    realFollowersFrom:
      defaultRouteData && defaultRouteData.realFollowersFrom
        ? defaultRouteData.realFollowersFrom * 100
        : null,
    realFollowersTo:
      defaultRouteData && defaultRouteData.realFollowersTo
        ? defaultRouteData.realFollowersTo * 100
        : null,
    creatorBirthdateFrom:
      defaultRouteData && defaultRouteData.creatorBirthdateFrom
        ? getAges(defaultRouteData.creatorBirthdateFrom) - 1
        : null,
    creatorBirthdateTo:
      defaultRouteData && defaultRouteData.creatorBirthdateTo
        ? getAges(defaultRouteData.creatorBirthdateTo)
        : null,
    excludeHiredMoreThanOnce: defaultRouteData?.excludeHiredMoreThanOnce || null,
    withTiktokShopValue: defaultRouteData?.withTiktokShop
      ? [
          {
            id: 'TIKTOK_SHOP',
            primaryMsg: 'search_section.form.withTiktokShop',
            msg: 'search_section.form.withTiktokShop.item',
          },
        ]
      : [],
  };

  const defaultExclusions =
    defaultRouteData && defaultRouteData.defaultExclusions
      ? defaultRouteData.defaultExclusions
      : { exclusions: [], excludeHiredMoreThanOnce: false };

  const adminParams = admin ? aminDefaultParams : {};
  const defaultParamsData = {
    ...defaultParams,
    ...adminParams,
  };

  const [searchParams, setParams] = useState({
    ...defaultParamsData,
    ...routeData,
    ...percantageFormattedQueryParams,
  });
  const searchData = searchFilterDataState.searchParamsData;
  const tracking = useTracking();

  useEffect(() => {
    if (!searchData) {
      fetchQuery(Environment, SearchFormQuery, { currency }).subscribe({
        next: (result) => {
          const searchDictionaries = { ...result };
          searchDictionaries.customLists =
            searchDictionaries.currentUser.organization.customLists.edges.map((list) => list.node);
          delete searchDictionaries.currentUser;
          searchFilterDataDispatch({
            type: SearchFiltersDataTypes.ADD_SEARCH_PARAMS_DATA,
            payload: { searchParamsData: searchDictionaries },
          });
        },
      });
    }
  }, []);

  const {
    creatorTypes,
    followersFrom,
    followersTo,
    realFollowersFrom,
    realFollowersTo,
    postPriceFrom,
    postPriceTo,
    engagementRateFrom,
    engagementRateTo,
    viewsPerPostTo,
    viewsPerPostFrom,
    viewsMedianTo,
    viewsMedianFrom,
    storiesReachFrom,
    storiesReachTo,
    postsReachFrom,
    postsReachTo,
    reelsPlaysMedianFrom,
    reelsPlaysMedianTo,
    creatorBirthdateFrom,
    creatorBirthdateTo,
  } = searchParams;

  const creatorType = creatorTypes && creatorTypes.length === 1 ? creatorTypes[0] : null;

  const handleChange = (data) => {
    const newParams = { ...searchParams, ...data };
    setParams(newParams);
    const filtersForAmplitude = Object.keys(newParams)
      .filter((filterName) =>
        Array.isArray(data[filterName]) ? data[filterName].length : data[filterName]
      )
      .reduce((filters, filterName) => {
        filters[filterName] = data[filterName];
        return filters;
      }, {});
    amplitude.sendEvent({
      id: '231',
      category: 'marketplace',
      name: 'use_filters',
      param: { applied_filters: filtersForAmplitude },
    });
    onParamsChange(newParams);
  };

  const handleParamsClear = () => {
    setParams(defaultParamsData);
    onParamsChange(defaultParamsData);
  };

  const handleExclusionChange = (items) => {
    setParams({ ...searchParams, ...items });
    onParamsChange({ ...searchParams, ...items }, false);
  };

  const handleCreatorTypeChange = ({ creatorType: newCreatorType }) => {
    let additional = {};
    if (!window.isApproval) {
      if (newCreatorType !== undefined && newCreatorType !== creatorType) {
        const type = newCreatorType;
        if (newCreatorType === INSTAGRAM) {
          additional = { contentCreator: null, ...onlyYoutubeParams };
        } else if (type === null) {
          additional = { ...resettableParams, ...onlyYoutubeParams };
        } else if (type === YOUTUBE) {
          const youtubeParams = youtubeResettableParams({ admin });
          additional = { ...youtubeParams };
        } else if (type === TIKTOK) {
          const tiktokParams = tiktokResettableParams({ admin });
          additional = { ...tiktokParams };
        }
      }
    } else {
      additional = { creatorType: { id: INSTAGRAM } };
    }
    const newSearchParams = { ...searchParams, creatorTypes: [newCreatorType], ...additional };
    setParams(newSearchParams);
    onParamsChange(newSearchParams);
  };

  const _isOnlyInstagramPlatformFilterAvailable = useMemo(() => {
    return isOnlyInstagramPlatformFilterAvailable({ campaignPlatform, campaignType });
  }, [campaignPlatform, campaignType]);

  const _isOnlyTiktokPlatformFilterAvailable = useMemo(() => {
    return isOnlyTiktokPlatformFilterAvailable({ campaignPlatform, campaignType });
  }, [campaignPlatform, campaignType]);

  useEffect(() => {
    if (_isOnlyInstagramPlatformFilterAvailable) {
      handleCreatorTypeChange({ creatorType: INSTAGRAM });
    }
    if (_isOnlyTiktokPlatformFilterAvailable) {
      handleCreatorTypeChange({ creatorType: TIKTOK });
    }
  }, [_isOnlyInstagramPlatformFilterAvailable, _isOnlyTiktokPlatformFilterAvailable]);

  if (!searchData) return null;

  const isYoutubeValue = isYoutube || creatorType === YOUTUBE;

  const data = getData({
    admin,
    currency,
    hasContentCreation,
    followersFrom,
    followersTo,
    realFollowersFrom,
    realFollowersTo,
    postPriceFrom,
    postPriceTo,
    engagementRateFrom,
    engagementRateTo,
    viewsPerPostTo,
    viewsPerPostFrom,
    viewsMedianTo,
    viewsMedianFrom,
    storiesReachFrom,
    storiesReachTo,
    postsReachFrom,
    postsReachTo,
    reelsPlaysMedianFrom,
    reelsPlaysMedianTo,
    // interestIds,
    // audienceInterestPercentage,
    creatorBirthdateFrom,
    creatorBirthdateTo,
    creatorTypes,
    isYoutube: isYoutubeValue,
    ...searchData,
  });

  const formatDropDownItems = () => {
    const items = createDropDownItems({ admin, ...searchParams });

    return items.map((item) => {
      const { posibleValue, name } = item;
      const value = searchParams[name];
      const onClick = () => {
        const newValue = value !== posibleValue ? posibleValue : null;
        const newParams = { ...searchParams, [name]: newValue };
        setParams(newParams);
        onParamsChange(newParams);
        tracking.trackEvent({ event: 'selected', payload: { name, value: newValue } });
      };

      return { ...item, onClick };
    });
  };

  const dropDownItems = formatDropDownItems();
  const dropDownButton = <Button color="normal" icon={icons.more} />;
  const dropDown = !window.isApproval ? (
    <div>
      <DropDown anchor={dropDownButton} list={dropDownItems} position="bottomLeft" />
    </div>
  ) : null;

  const getCreatorTypeValue = () => {
    if (_isOnlyTiktokPlatformFilterAvailable) {
      return TIKTOK;
    }
    if (_isOnlyInstagramPlatformFilterAvailable) {
      return INSTAGRAM;
    }
    return creatorType || 'ALL';
  };

  const creatorTypeEl = !window.isApproval ? (
    <Field
      name="creatorType"
      element="select"
      elementData={{
        placeholderMsg: 'create_campaign.campaign_platform.placeholder',
        disabled: _isOnlyInstagramPlatformFilterAvailable || _isOnlyTiktokPlatformFilterAvailable,
        options: getCreatorTypesList({ currency, campaignPlatform, campaignType }),
        value: getCreatorTypeValue(),
        onChange: handleCreatorTypeChange,
      }}
      className={styles.platform}
    />
  ) : undefined;

  const formattedParams =
    creatorType === 'ALL' ? { ...searchParams, creatorType: null } : searchParams;

  return (
    <div className={className}>
      <SearchParams
        data={data}
        content={dropDown}
        element={creatorTypeEl}
        searchParams={formattedParams}
        hasTextQuery={true}
        hasHashTag={true}
        hashTagStyles={styles.hashtags}
        hashTagDisabled={creatorType === YOUTUBE}
        paramsKit={paramsKit}
        queryStyles={{ width: '260px' }}
        showClearButton={true}
        formStyles={admin ? styles.formSearch : undefined}
        onChange={handleChange}
        onRemove={handleChange}
        onClearParams={handleParamsClear}
      />
      <CreatorExclusion
        organizationId={organizationId}
        admin={admin}
        defaultExclusions={defaultExclusions}
        onChange={handleExclusionChange}
        defaultCampaignId={campaignId}
      />
    </div>
  );
};

export default track({ element: 'params' })(SearchForm);
