import FileUploader from 'Atoms/FileUploader/FileUploader';
import { parse, ParseResult } from 'papaparse';
import React, { PropsWithChildren, useState } from 'react';
import { checkEmailValid } from 'Util/validate';

import styles from './ImportCsvFile.pcss';

interface Props {
  onCompleted: (
    rows: CreateListImportCsvDataRow[],
    errors: CreateListImportCsvDataErrorRow[],
    exceptions: string[]
  ) => void;
  disabled?: boolean;
}

const ImportCsvFile: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, onCompleted, disabled } = props;
  const [error, setError] = useState<string | null>(null);
  const handlImportCsvClick = (files: FileList | null) => {
    if (!files) return;

    parse(files[0], {
      delimiter: 'tab',
      complete: handleParseComplete,
    });
  };

  const handleParseComplete = ({ data, errors }: ParseResult<string>) => {
    if (errors.length !== 0 || data.length === 0) {
      return;
    }

    const rows: CreateListImportCsvDataRow[] = [];
    const rowErrors: CreateListImportCsvDataErrorRow[] = [];
    const exceptions: string[] = [];

    let totalCount = 0;
    let parsedCount = 0;

    data.forEach((item, index) => {
      if (item.length === 1 && item[0] === '' && data.length < 2) {
        exceptions.push('invite_outreach_creators_modal.file.invalid');
      }
      const itemData = item[0].split(/[,;| ]/);
      const username = itemData[0]?.trim();
      const email = itemData[1]?.trim();
      if (username && email && checkEmailValid(email)) {
        parsedCount++;
        rows.push({ username, email });
      } else {
        rowErrors.push({ index: index + 1, email, username });
      }
      totalCount++;
    });
    if (rows.length === 0) {
      exceptions.push('invite_outreach_creators_modal.file.invalid');
    }
    onCompleted(rows, rowErrors, exceptions);
  };
  return (
    <>
      <div className={styles.uploaderWrapper}>
        {children}
        <FileUploader
          disabled={disabled}
          typeList=".csv"
          className={styles.uploader}
          onChange={handlImportCsvClick}
        />
      </div>
    </>
  );
};

export default ImportCsvFile;

export type CreateListImportCsvDataRow = {
  username: string;
  email: string;
};

export type CreateListImportCsvDataErrorRow = {
  index: number;
  username?: string;
  email?: string;
};
