import React, { useState } from 'react';
import { createPaginationContainer, graphql, RelayPaginationProp } from 'react-relay';

import styles from './BlocklistItems.pcss';
import BlockedCreator from './BlockedCreator/BlockedCreator';

import UnlockCreator from 'Molecules/UnlockCreator/UnlockCreator';
import Text from 'Components/ui/Text/Text';
import IconNew from 'Components/ui/Icon/Icon';
import LoaderHandler from 'Organisms/LoaderHandler/LoaderHandler';
import type { BlocklistItems_organization } from 'GraphTypes/BlocklistItems_organization.graphql';
import classNames from 'classnames';

const ITEMS_COUNT = 20;
const FOOTER_OFFSET = 279;

interface Props {
  relay: RelayPaginationProp;
  organizationId?: string;
  isBlockedView?: boolean;
  organization: BlocklistItems_organization;
  isPlanPaused?: boolean;
}

const BlocklistItems: React.FC<Props> = (props) => {
  const { organization, organizationId, relay, isBlockedView, isPlanPaused } = props;

  const [unlockVisible, setUnlockVisible] = useState(false);

  const blockedCreators = organization.blockedCreators?.edges;

  if (!blockedCreators?.length) {
    return (
      <div className={styles.emptyWrap}>
        <IconNew name="Blocklist" />
        <Text msg="creators.dashboard.blocklist.empty_list" type="md" />
      </div>
    );
  }

  const handleVisibilityChange = (value: boolean) => {
    setUnlockVisible(value);
  };

  const handleUnlockCreatorClick = () => {
    setUnlockVisible(true);
  };

  const list = blockedCreators.map((item) => {
    if (!item?.node) return null;
    return (
      <BlockedCreator
        key={item.node.id}
        isBlockedView={isBlockedView}
        creator={item.node}
        showUnlockCreatorBanner={handleUnlockCreatorClick}
        organizationId={organizationId}
      />
    );
  });

  return (
    <>
      <div className={styles.listHeader}>
        <Text
          type="md"
          className={styles.listHeaderText}
          msg="creators.dashboard.lists.header.username"
        />
        <Text
          type="md"
          className={styles.listHeaderText}
          msg="creators.dashboard.lists.header.categories"
        />
        <Text
          type="md"
          className={styles.listHeaderText}
          msg="creators.dashboard.lists.header.followers"
        />
        <Text
          type="md"
          className={styles.listHeaderText}
          msg="creators.dashboard.lists.header.er"
        />
        <Text
          type="md"
          className={classNames(styles.listHeaderText, styles.profile)}
          msg="creators.dashboard.lists.header.profile"
        />
        <div />
      </div>
      <LoaderHandler relay={relay} count={ITEMS_COUNT} offset={FOOTER_OFFSET}>
        <div className={styles.list}>{list}</div>
      </LoaderHandler>
      {isBlockedView && (
        <UnlockCreator
          visible={unlockVisible}
          onVisibilityChange={handleVisibilityChange}
          className={styles.unlockBanner}
          paused={isPlanPaused}
        />
      )}
    </>
  );
};

export default createPaginationContainer(
  BlocklistItems,
  {
    organization: graphql`
      fragment BlocklistItems_organization on Organization
      @argumentDefinitions(count: { type: "Int", defaultValue: 18 }, cursor: { type: "String" }) {
        blockedCreators(first: $count, after: $cursor)
          @connection(key: "Organization_blockedCreators", filters: []) {
          edges {
            node {
              id
              ...BlockedCreator_creator
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.organization.blockedCreators;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
      };
    },
    query: graphql`
      query BlocklistItemsPaginationQuery($count: Int!, $cursor: String) {
        currentUser {
          organization {
            subscription {
              paused
            }
            ...BlocklistItems_organization @arguments(count: $count, cursor: $cursor)
          }
        }
      }
    `,
  }
);
