/**
 * @generated SignedSource<<a4177ed7e7d8c78a0a275ebe5ed5e0e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING" | "%future added value";
export type InAppNotificationIcon = "INSENSE_LOGO" | "ROCKET" | "WARNING" | "%future added value";
export type InAppNotifications_Type = "ADVERTISER_UNRESPONSIVENESS" | "BCA_PERMISSION_REVOKED" | "CAMPAIGN_ACTIVATED" | "CONTENT_REVIEW_SUBMITTED" | "CREATIVE_CREATED" | "DAILY_OFFER_ACCEPTANCES" | "DRAFT_DEADLINE_OVERDUE" | "NEW_MESSAGES" | "PLAIN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NotificationItem_notification$data = {
  readonly campaign?: {
    readonly id: string;
    readonly name: string | null | undefined;
  } | null | undefined;
  readonly contentReview?: {
    readonly attachments: {
      readonly totalCount: number;
    };
  } | null | undefined;
  readonly createdAt: any;
  readonly creative?: {
    readonly project: {
      readonly campaign: {
        readonly id: string;
        readonly name: string | null | undefined;
      };
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
  readonly creatives?: {
    readonly totalCount: number;
  } | null | undefined;
  readonly ctaText: string | null | undefined;
  readonly icon: InAppNotificationIcon | null | undefined;
  readonly id: string;
  readonly project?: {
    readonly campaign: {
      readonly id: string;
      readonly name: string | null | undefined;
      readonly type: CampaignType | null | undefined;
    };
    readonly id: string;
  } | null | undefined;
  readonly read: boolean;
  readonly totalApplicantsCount?: number;
  readonly type: InAppNotifications_Type | null | undefined;
  readonly viewed: boolean;
  readonly " $fragmentType": "NotificationItem_notification";
};
export type NotificationItem_notification$key = {
  readonly " $data"?: NotificationItem_notification$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationItem_notification">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Campaign",
  "kind": "LinkedField",
  "name": "campaign",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v2/*: any*/)
  ],
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Project",
    "kind": "LinkedField",
    "name": "project",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "storageKey": null
  }
],
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationItem_notification",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "icon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctaText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "read",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewed",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        (v3/*: any*/)
      ],
      "type": "InAppNotifications_CampaignActivated",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalApplicantsCount",
          "storageKey": null
        }
      ],
      "type": "InAppNotifications_DailyOfferAcceptances",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Creative",
          "kind": "LinkedField",
          "name": "creative",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        }
      ],
      "type": "InAppNotifications_CreativeCreated",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Project",
          "kind": "LinkedField",
          "name": "project",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Campaign",
              "kind": "LinkedField",
              "name": "campaign",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentReview",
          "kind": "LinkedField",
          "name": "contentReview",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentReviewAttachmentConnection",
              "kind": "LinkedField",
              "name": "attachments",
              "plural": false,
              "selections": (v5/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CreativeConnection",
          "kind": "LinkedField",
          "name": "creatives",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        }
      ],
      "type": "InAppNotifications_ContentReviewSubmitted",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v4/*: any*/),
      "type": "InAppNotifications_DraftDeadlineOverdue",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Project",
          "kind": "LinkedField",
          "name": "project",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Campaign",
              "kind": "LinkedField",
              "name": "campaign",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "InAppNotifications_BcaPermissionRevoked",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v4/*: any*/),
      "type": "InAppNotifications_AdvertiserUnresponsiveness",
      "abstractKey": null
    }
  ],
  "type": "InAppNotification",
  "abstractKey": "__isInAppNotification"
};
})();

(node as any).hash = "b797e1f286e430a1c28756338fa1e1d7";

export default node;
