import { commitMutation, graphql } from 'react-relay';
import includes from 'lodash/includes';
import { Primitive } from 'relay-runtime/lib/store/RelayStoreTypes';

import environment from 'Api/Environment';
import {
  UpdateBriefInput,
  UpdateBriefMutation$data,
  UpdateBriefMutation,
} from 'GraphTypes/UpdateBriefMutation.graphql';

const mutation = graphql`
  mutation UpdateBriefMutation($input: UpdateBriefInput!) {
    updateBrief(input: $input) {
      campaign {
        id
        brief {
          ... on V2Brief {
            includeReferralFee
            referralFeeRate
            priceCap
            additionalInfo
            brandAccountName
            hashtags
            unlimitedDuration
            creatorsCountFrom
            creatorsCountTo
            sampleProvision
            sampleDescription
            productUrl
            productTitle
            productPrice
            productDescription
            eventAttendance
            eventDescription
            startDate
            endDate
            paidSocial
            paidSocialActivationDays
            paidSocialBca
            productShipmentRequired
            productDelivery
            screeningQuestion
            sparkAds
            sparkAdsActivationDays
            taskDescription
            contentLicensingType
            contentLicensingTerritory
            contentLicensingStartDate
            contentLicensingEndDate
            contentLicensingUsageOptions
            contentLicensingPeriod
            priceLimitMin
            priceLimitMax
            hiringGoal
            tiktokShop_ShopId
            tiktokShop_ProductId
          }
        }
      }
    }
  }
`;

type fnType = (data: object) => void;

export default (
  data: UpdateBriefInput,
  resolve?: (response: UpdateBriefMutation$data) => void,
  reject?: fnType
) => {
  const {
    id,
    paidSocialActivationDays,
    paidSocial,
    paidSocialBca,
    sparkAds,
    sparkAdsActivationDays,
    productShipmentRequired,
    productDelivery,
    productUrl,
    creatorsCountFrom,
    creatorsCountTo,
    screeningQuestion,
    taskDescription,
    contentLicensingType,
    contentLicensingTerritory,
    contentLicensingUsageOptions,
    priceLimitMin,
    priceLimitMax,
    includeReferralFee,
    referralFeeRate,
    productPrice,
    productDescription,
    tiktokShop_ProductId,
    tiktokShop_ShopId,
    hiringGoal,
  } = data;

  const creatorsCountFromValue = creatorsCountFrom ? Number(creatorsCountFrom) : undefined;
  const creatorsCountToValue = creatorsCountTo ? Number(creatorsCountTo) : null;

  const variables = {
    input: {
      ...data,
      creatorsCountTo: creatorsCountToValue,
      creatorsCountFrom: creatorsCountFromValue,
    },
  };
  commitMutation<UpdateBriefMutation>(environment, {
    mutation,
    variables,
    optimisticUpdater: (store) => {
      const brief = store.get(id);
      if (!brief) return;

      const keys = Object.keys(data);

      if (includes(keys, 'creatorsCountTo')) {
        brief.setValue(creatorsCountToValue, 'creatorsCountTo');
      }

      if (includes(keys, 'creatorsCountFrom')) {
        brief.setValue(creatorsCountFromValue, 'creatorsCountFrom');
      }

      if (includes(keys, 'screeningQuestion')) {
        brief.setValue(screeningQuestion, 'screeningQuestion');
      }

      if (includes(keys, 'contentLicensingType')) {
        brief.setValue(contentLicensingType, 'contentLicensingType');
      }

      if (includes(keys, 'contentLicensingTerritory')) {
        brief.setValue(contentLicensingTerritory, 'contentLicensingTerritory');
      }

      if (includes(keys, 'contentLicensingUsageOptions')) {
        brief.setValue(contentLicensingUsageOptions as Primitive[], 'contentLicensingUsageOptions');
      }

      if (includes(keys, 'paidSocialBca') && paidSocialBca !== undefined) {
        brief.setValue(paidSocialBca, 'paidSocialBca');
      }

      if (includes(keys, 'paidSocial')) {
        brief.setValue(paidSocial, 'paidSocial');
      }

      if (includes(keys, 'paidSocialActivationDays')) {
        brief.setValue(paidSocialActivationDays, 'paidSocialActivationDays');
      }

      if (includes(keys, 'sparkAds')) {
        brief.setValue(sparkAds, 'sparkAds');
      }

      if (includes(keys, 'sparkAdsActivationDays')) {
        brief.setValue(sparkAdsActivationDays, 'sparkAdsActivationDays');
      }

      if (includes(keys, 'productShipmentRequired')) {
        brief.setValue(productShipmentRequired, 'productShipmentRequired');
      }

      if (includes(keys, 'taskDescription')) {
        brief.setValue(taskDescription, 'taskDescription');
      }
      if (includes(keys, 'productDelivery')) {
        brief.setValue(productDelivery, 'productDelivery');
      }
      if (includes(keys, 'productUrl')) {
        brief.setValue(productUrl, 'productUrl');
      }
      if (includes(keys, 'productDescription')) {
        brief.setValue(productDescription, 'productDescription');
      }

      if (includes(keys, 'priceLimitMin')) {
        brief.setValue(priceLimitMin, 'priceLimitMin');
      }

      if (includes(keys, 'priceLimitMax')) {
        brief.setValue(priceLimitMax, 'priceLimitMax');
      }
      if (includes(keys, 'includeReferralFee')) {
        brief.setValue(includeReferralFee, 'includeReferralFee');
      }
      if (includes(keys, 'referralFeeRate')) {
        brief.setValue(referralFeeRate, 'referralFeeRate');
      }
      if (includes(keys, 'productPrice')) {
        brief.setValue(productPrice, 'productPrice');
      }
      if (includes(keys, 'hiringGoal')) {
        brief.setValue(hiringGoal, 'hiringGoal');
      }
      if (includes(keys, 'tiktokShop_ProductId')) {
        brief.setValue(tiktokShop_ProductId, 'tiktokShop_ProductId');
      }
      if (includes(keys, 'tiktokShop_ShopId')) {
        brief.setValue(tiktokShop_ShopId, 'tiktokShop_ShopId');
      }
    },
    onCompleted: (response, errors) => {
      if (errors && errors.length > 0 && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};
