import React, { useState } from 'react';

import styles from './TiktokAdsPromo.pcss';
import TiktokAdsPromoContent from './TiktokAdsPromoContent';

import { useIndicateInterestInUseCaseMutation } from 'Mutations/IndicateInterestInUseCase.Mutation';
import { amplitude } from 'Helpers/amplitude';
import { useCampaignActions } from 'Hooks/useCampaignActions';
import Drawer from 'Components/ui/Drawer/Drawer';
import Button from 'Components/ui/Button/Button';

interface Props {
  creatorId?: string;
}

const TiktokAdsPromo: React.FC<Props> = (props) => {
  const { creatorId } = props;

  const [showBookCall, setShowBookCall] = useState(false);

  const { createNewCampaign } = useCampaignActions();

  const [indicateInterestInUseCase] = useIndicateInterestInUseCaseMutation();

  const handleLetsTryClick = () => {
    amplitude.sendEvent<452>({
      id: '452',
      category: 'tiktok_spark_ads_promo_drawer',
      name: 'lets_try_click',
      param: undefined,
    });
    indicateInterestInUseCase({
      variables: {
        input: {
          source: creatorId ? 'PROJECT_COMPLETION' : 'DASHBOARD_BANNER',
          button: 'FILL_BRIEF_BUTTON',
          useCase: 'TIKTOK_SPARK_ADS',
          creatorId,
        },
      },
    });
    createNewCampaign({
      campaignData: {
        useCase: 'TIKTOK_SPARK_ADS',
        postingType: 'CREATOR_ADS',
        type: 'INFLUENCER',
        objective: 'REACH',
      },
    });
  };

  const handeBookCallClick = () => {
    amplitude.sendEvent<453>({
      id: '453',
      category: 'tiktok_spark_ads_promo_drawer',
      name: 'book_call_click',
      param: undefined,
    });
    indicateInterestInUseCase({
      variables: {
        input: {
          source: creatorId ? 'PROJECT_COMPLETION' : 'DASHBOARD_BANNER',
          button: 'BOOK_CALL_BUTTON',
          useCase: 'TIKTOK_SPARK_ADS',
          creatorId,
        },
      },
    });
    setShowBookCall(true);
  };

  const handleClose = () => {
    setShowBookCall(false);
  };

  const handleOpen = () => {
    if (creatorId) {
      amplitude.sendEvent<457>({
        id: '457',
        category: 'tiktok_spark_ads_promo_drawer',
        name: 'with_creator_show',
        param: undefined,
      });
    }
  };

  const drawerPostfix = creatorId ? `-${creatorId}` : '';

  return (
    <Drawer
      rootKey={`tiktok-spark-ads-promo${drawerPostfix}`}
      className={styles.drawer}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <div className={styles.drawerContainer}>
        <div className={styles.content}>
          <TiktokAdsPromoContent
            creatorId={creatorId}
            showBookCall={showBookCall}
            onBookCallClick={handeBookCallClick}
          />
        </div>
        <div className={styles.buttons}>
          <Button
            fluid
            color="white"
            msg="creator_ads_tiktok_info.lets_try"
            onClick={handleLetsTryClick}
          />
          <Button
            fluid
            color="black"
            msg="creator_ads_tiktok_info.book_call"
            onClick={handeBookCallClick}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default TiktokAdsPromo;
