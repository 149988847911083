import React, { useState, useEffect, useMemo, useCallback, useContext } from 'react';
import { graphql } from 'relay-runtime';
import { useLazyLoadQuery } from 'react-relay';
import xor from 'lodash/xor';
import findIndex from 'lodash/findIndex';

import styles from './ProjectCreatorRating.pcss';
import { textualRating, getCharacteristic } from './data';

import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import rateCreator from 'Mutations/RateCreator.Mutation';
import Text from 'Components/ui/Text/Text';
import Button from 'Components/ui/Button/Button';
import Drawer from 'Components/ui/Drawer';
import Tag from 'Components/ui/Tag/Tag';
import Textarea from 'Components/ui/Textarea/Textarea';
import Icon from 'Components/ui/Icon/Icon';
import { CreatorCharacteristic } from 'GraphTypes/RateCreatorMutation.graphql';
import { ProjectCreatorRatingQuery as QueryType } from 'GraphTypes/ProjectCreatorRatingQuery.graphql';

const HIGH_SCORE_MIN = 4;

const ProjectCreatorRatingQuery = graphql`
  query ProjectCreatorRatingQuery($creatorId: ID!) {
    creator(id: $creatorId) {
      id
      type
      perfectForAdsCampaigns
    }
    currentUser {
      organization {
        counters {
          activatedCreatorAdsCampaigns
          activatedUgcInstagramCampaigns
          activatedUgcTiktokCampaigns
        }
      }
    }
  }
`;

interface Props {
  projectId: string;
  creatorId: string;
  defaultScore?: number;
}

const ProjectCreatorRating: React.FC<Props> = (props) => {
  const { openDrawer, closeDrawer } = useContext(DrawerContext);

  const { creatorId, projectId, defaultScore: defaultScoreValue } = props;

  const data = useLazyLoadQuery<QueryType>(ProjectCreatorRatingQuery, {
    creatorId,
  });

  const shouldShowPromo = data.creator?.perfectForAdsCampaigns;
  const activatedCreatorAdsCampaigns =
    data.currentUser?.organization?.counters?.activatedCreatorAdsCampaigns || 0;
  const activatedUgcInstagramCampaigns =
    data.currentUser?.organization?.counters?.activatedUgcInstagramCampaigns || 0;
  const activatedUgcTiktokCampaigns =
    data.currentUser?.organization?.counters?.activatedUgcTiktokCampaigns || 0;
  const activatedUgcCampaigns = activatedUgcInstagramCampaigns + activatedUgcTiktokCampaigns;

  const creatorType = data.creator?.type;

  const defaultScore = defaultScoreValue
    ? { 1: 1, 2: 1, 3: 4, 4: 4, 5: 5 }[defaultScoreValue]
    : undefined;

  const defaultIsHigh = defaultScore && defaultScore >= HIGH_SCORE_MIN;

  const [currentScore, setCurrentScore] = useState<undefined | number>(defaultScore);
  const [comment, setComment] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isHignScore, setIsHighScore] = useState(defaultIsHigh);
  const [reviewSent, setReviewSent] = useState(false);
  const [currentCarechteristics, setCarechteristics] = useState<CreatorCharacteristic[]>([]);

  useEffect(() => {
    const newIsHigh = currentScore && currentScore >= HIGH_SCORE_MIN;

    if (newIsHigh !== isHignScore) {
      setIsHighScore(newIsHigh);
      setCarechteristics([]);
    }
  }, [currentScore]);

  useEffect(() => {
    setError(false);
  }, [currentScore, comment, currentCarechteristics]);

  const handleClick = () => {
    if (currentScore) {
      setLoading(true);
      rateCreator(
        {
          projectId,
          score: currentScore,
          comment,
          side: 'AGENCY',
          characteristics: currentCarechteristics,
        },
        handleRatingDone
      );
    }
  };

  const handleCommentChange = (text: string) => {
    setComment(text);
  };

  const handleRatingDone = useCallback(() => {
    if (
      shouldShowPromo &&
      currentScore === 5 &&
      activatedCreatorAdsCampaigns === 0 &&
      activatedUgcCampaigns >= 2
    ) {
      const adsPromoModalName =
        creatorType === 'TIKTOK' ? 'tiktok-spark-ads-promo-' : 'meta-ads-promo-';
      openDrawer(`${adsPromoModalName}${creatorId}`);
    } else {
      setReviewSent(true);
    }
  }, [shouldShowPromo, currentScore, activatedUgcCampaigns]);

  const handleClose = () => {
    closeDrawer(`project-creator-rating-${projectId}`);
  };

  const carechteristicList = getCharacteristic(isHignScore) as CreatorCharacteristic[];

  const isValid =
    (isHignScore && currentScore) ||
    (currentScore && !isHignScore && (currentCarechteristics.length > 0 || comment));

  const handleSubmitClick = () => {
    if (currentScore && !isHignScore && currentCarechteristics.length === 0 && !comment) {
      setError(true);
    }
  };

  const stars = (
    <div className={styles.ratingWrapper}>
      <div className={styles.textualRating}>
        {textualRating.map((item) => {
          const { score } = item;

          const onClick = () => {
            setCurrentScore(score);
          };

          const deactive = score !== currentScore;

          return (
            <div key={score} onClick={onClick}>
              <Tag
                needMargin={false}
                color={deactive ? 'grey' : 'stroke'}
                msg={`project_creator_rating_modal.textual_rating.${score}`}
              />
            </div>
          );
        })}
      </div>
    </div>
  );

  const wouldCreatorRecommendedText = useMemo(() => {
    if (error) return null;
    switch (currentScore) {
      case 1: {
        return (
          <Text
            type="md"
            msg="project_creator_rating_modal.creator_would_not_be_recommended"
            className={`${styles.wouldCreatorRecommended} ${styles.error}`}
          />
        );
      }
      case 5: {
        return (
          <Text
            type="md"
            msg="project_creator_rating_modal.creator_would_be_recommended"
            className={`${styles.wouldCreatorRecommended} ${styles.recommendation}`}
          />
        );
      }
      default: {
        return null;
      }
    }
  }, [error, currentScore]);

  if (reviewSent) {
    return (
      <Drawer rootKey={`project-creator-rating-${projectId}`} className={styles.drawer}>
        <div className={styles.container}>
          <Text
            type="d2"
            msg="project_creator_rating_modal.review_sent.title"
            className={styles.title}
          />
          <Text type="md" msg="project_creator_rating_modal.review_sent.descr" />
          <div className={styles.resultContent}>
            <div className={styles.result}>
              <Icon name="Check" size={120} className={styles.resultIcon} />
            </div>
          </div>
          <Button
            color="black"
            msg="project_creator_rating_modal.review_sent.close"
            onClick={handleClose}
          />
        </div>
      </Drawer>
    );
  }

  return (
    <Drawer
      rootKey={`project-creator-rating-${projectId}`}
      needCloseButton={false}
      className={styles.drawer}
    >
      <div className={styles.container}>
        <Text
          type="d2"
          msg="project_creator_rating_modal.deal_rating_text.title"
          className={styles.title}
        />
        <Text type="md" msg="project_creator_rating_modal.deal_rating_text.descr" />
        <div className={styles.content}>
          {stars}
          {currentScore && (
            <>
              <Text
                type="h2"
                msg={
                  isHignScore
                    ? 'project_creator_rating_modal.what_was_good'
                    : 'project_creator_rating_modal.what_was_bad'
                }
              />
              <div className={styles.carechteristics}>
                {carechteristicList.map((item) => {
                  const handleCarechteristicClick = () => {
                    const newList = xor(currentCarechteristics, [item]);
                    setCarechteristics(newList);
                  };

                  const isActive =
                    findIndex(currentCarechteristics, (value) => value === item) !== -1;

                  return (
                    <div key={item} onClick={handleCarechteristicClick}>
                      <Tag
                        needMargin={false}
                        color={isActive ? 'stroke' : 'grey'}
                        msg={`rating.characteristic.${item.toLowerCase()}`}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {currentScore && (
            <>
              <Text
                type="h2"
                msg="project_creator_rating_modal.write_review"
                className={styles.subtitle}
              />
              <Textarea
                bordered
                placeholderMsg="project_creator_rating_modal.comment.placeholder"
                handleChange={handleCommentChange}
              />
            </>
          )}
        </div>
        {error && <Text msg="project_creator_rating_modal.error" className={styles.error} />}
        {wouldCreatorRecommendedText}
        <div onClick={handleSubmitClick}>
          <Button
            color="black"
            disabled={!isValid}
            loading={loading}
            msg="project_creator_rating_modal.submit"
            onClick={handleClick}
            className={styles.submit}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default ProjectCreatorRating;
