import { graphql, useMutation } from 'react-relay';

import { ImportCreatorsToCustomListMutation } from 'GraphTypes/ImportCreatorsToCustomListMutation.graphql';
import type { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const importCreatorsToCustomListMutation = graphql`
  mutation ImportCreatorsToCustomListMutation($input: ImportCreatorsToCustomListInput!) {
    importCreatorsToCustomList(input: $input) {
      __typename
      ... on ValidationError {
        type
        message
      }

      ... on ImportCreatorsToCustomListPayload {
        creatorImport {
          completed
        }
      }
    }
  }
`;

export const useImportCreatorsToCustomListMutation: BaseMutation<
  ImportCreatorsToCustomListMutation
> = (config) => {
  const [importCreatorsToCustomList, loading] = useMutation<ImportCreatorsToCustomListMutation>(
    importCreatorsToCustomListMutation
  );

  const importCreatorsToCustomListCaller: BaseMutationExecFn<ImportCreatorsToCustomListMutation> = (
    execConfig
  ) => {
    importCreatorsToCustomList({
      ...config,
      ...execConfig,
    });
  };
  return [importCreatorsToCustomListCaller, loading];
};
