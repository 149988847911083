/**
 * @generated SignedSource<<036fdf6e28a2f7bafb48118b6b9df6fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type OutreachBatchQuery$variables = {
  batchId: string;
};
export type OutreachBatchQuery$data = {
  readonly outreachBatch: {
    readonly appliedCount: {
      readonly totalCount: number;
    };
    readonly clickedCount: {
      readonly totalCount: number;
    };
    readonly hiredCount: {
      readonly totalCount: number;
    };
    readonly openedCount: {
      readonly totalCount: number;
    };
    readonly reachedCount: {
      readonly totalCount: number;
    };
    readonly repliedCount: {
      readonly totalCount: number;
    };
  } | null | undefined;
};
export type OutreachBatchQuery = {
  response: OutreachBatchQuery$data;
  variables: OutreachBatchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "batchId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "batchId"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v3 = {
  "alias": "reachedCount",
  "args": [
    {
      "kind": "Literal",
      "name": "outreached",
      "value": true
    }
  ],
  "concreteType": "OutreachSequenceConnection",
  "kind": "LinkedField",
  "name": "sequences",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": "sequences(outreached:true)"
},
v4 = {
  "alias": "openedCount",
  "args": [
    {
      "kind": "Literal",
      "name": "opened",
      "value": true
    }
  ],
  "concreteType": "OutreachSequenceConnection",
  "kind": "LinkedField",
  "name": "sequences",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": "sequences(opened:true)"
},
v5 = {
  "alias": "clickedCount",
  "args": [
    {
      "kind": "Literal",
      "name": "clicked",
      "value": true
    }
  ],
  "concreteType": "OutreachSequenceConnection",
  "kind": "LinkedField",
  "name": "sequences",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": "sequences(clicked:true)"
},
v6 = {
  "alias": "repliedCount",
  "args": [
    {
      "kind": "Literal",
      "name": "replied",
      "value": true
    }
  ],
  "concreteType": "OutreachSequenceConnection",
  "kind": "LinkedField",
  "name": "sequences",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": "sequences(replied:true)"
},
v7 = {
  "alias": "appliedCount",
  "args": [
    {
      "kind": "Literal",
      "name": "applied",
      "value": true
    }
  ],
  "concreteType": "OutreachSequenceConnection",
  "kind": "LinkedField",
  "name": "sequences",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": "sequences(applied:true)"
},
v8 = {
  "alias": "hiredCount",
  "args": [
    {
      "kind": "Literal",
      "name": "hired",
      "value": true
    }
  ],
  "concreteType": "OutreachSequenceConnection",
  "kind": "LinkedField",
  "name": "sequences",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": "sequences(hired:true)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OutreachBatchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OutreachBatch",
        "kind": "LinkedField",
        "name": "outreachBatch",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OutreachBatchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OutreachBatch",
        "kind": "LinkedField",
        "name": "outreachBatch",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2372d6157e1e5dffa15812ef760ddab6",
    "id": null,
    "metadata": {},
    "name": "OutreachBatchQuery",
    "operationKind": "query",
    "text": "query OutreachBatchQuery(\n  $batchId: ID!\n) {\n  outreachBatch(id: $batchId) {\n    reachedCount: sequences(outreached: true) {\n      totalCount\n    }\n    openedCount: sequences(opened: true) {\n      totalCount\n    }\n    clickedCount: sequences(clicked: true) {\n      totalCount\n    }\n    repliedCount: sequences(replied: true) {\n      totalCount\n    }\n    appliedCount: sequences(applied: true) {\n      totalCount\n    }\n    hiredCount: sequences(hired: true) {\n      totalCount\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "fa8504180010c850e0ec6226ad7faef1";

export default node;
