import { CreateOutreachTemplateMutation } from 'GraphTypes/CreateOutreachTemplateMutation.graphql';
import { ConnectionHandler, graphql, useMutation } from 'react-relay';
import { v4 as uuid } from 'uuid';

import type { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const CreateOutreachTemplateMutation = graphql`
  mutation CreateOutreachTemplateMutation($input: CreateOutreachTemplateInput!) {
    createOutreachTemplate(input: $input) {
      __typename
      ... on CreateOutreachTemplatePayload {
        outreachTemplate {
          id
          locale
          htmlBody
          subject
        }
      }
      ... on AlreadyExists {
        __typename
      }
    }
  }
`;

export const useCreateOutreachTemplateMutation: BaseMutation<CreateOutreachTemplateMutation> = (
  config
) => {
  const [createOutreachTemplate, loading] = useMutation<CreateOutreachTemplateMutation>(
    CreateOutreachTemplateMutation
  );

  const createOutreachTemplateMutationCaller: BaseMutationExecFn<CreateOutreachTemplateMutation> = (
    execConfig
  ) => {
    if (!execConfig) return;
    const newTemplateId = uuid();
    createOutreachTemplate({
      ...config,
      ...execConfig,
      variables: {
        input: {
          id: newTemplateId,
          ...execConfig.variables.input,
        },
      },
      updater: (store) => {
        const connection = store.get('client:root:outreachTemplates');
        const payload = store.getRootField('createOutreachTemplate');
        const serverEdge = payload.getLinkedRecord('outreachTemplate');
        const edgeId = serverEdge?.getValue('id');
        if (edgeId) {
          const id = store.get(edgeId as string);
          if (id && connection && serverEdge) {
            const newEdge = ConnectionHandler.createEdge(
              store,
              connection,
              serverEdge,
              'OutreachTemplageEdge'
            );
            if (newEdge) {
              ConnectionHandler.insertEdgeBefore(connection, newEdge);
            }
          }
        }
      },
    });
  };
  return [createOutreachTemplateMutationCaller, loading];
};
