import React, { useRef, useState } from 'react';
import { createPaginationContainer, graphql, RelayPaginationProp } from 'react-relay';

import Skeleton from '../../../components/Skeleton/Skeleton';
import EmptyListMessage from '../../../components/EmptyListMessage/EmptyListMessage';

import styles from './CustomListCreators.pcss';
import CustomListCreatorItem from './CustomListCreatorItem/CustomListCreatorItem';

import UnlockCreator from 'Molecules/UnlockCreator/UnlockCreator';
import EmptySearchRequest from 'Molecules/EmptySearchRequest/EmptySearchRequest';
import LoaderHandler from 'Organisms/LoaderHandler/LoaderHandler';
import type { CustomListCreators_customList$data } from 'GraphTypes/CustomListCreators_customList.graphql';
import Text from 'Components/ui/Text/Text';
import classNames from 'classnames';
import Spinner from 'Atoms/Spinner/Spinner';

interface Props {
  id: string;
  relay: RelayPaginationProp;
  customList: CustomListCreators_customList$data;
  textQuery?: string;
  isBlockedView?: boolean;
  onParamsClear: () => void;
  isPlanPaused?: boolean;
  organizationId?: string;
}

const CustomListCreators: React.FC<Props> = (props) => {
  const {
    customList,
    relay,
    textQuery,
    isBlockedView,
    onParamsClear,
    isPlanPaused,
    organizationId,
  } = props;

  const listContainer = useRef<HTMLDivElement | null>(null);

  const [unlockVisible, setUnlockVisible] = useState(false);

  const creators = customList?.creators?.edges || [];
  const customListId = customList.id || '';
  const customListName = customList.name || '';

  const isRequestEmpty = !textQuery;

  if (!creators?.length && !isRequestEmpty) {
    return <EmptySearchRequest onParamsClear={onParamsClear} />;
  }

  if (!creators?.length) {
    return (
      <EmptyListMessage
        iconProps={{ name: 'Save-list' }}
        textProps={{ msg: 'creators.dashboard.lists.empty_list' }}
      />
    );
  }

  const handleVisibilityChange = (value: boolean) => {
    setUnlockVisible(value);
  };

  const handleUnlockCreatorClick = () => {
    setUnlockVisible(true);
  };

  const list = creators.map((item, key) => {
    if (!item?.node) return null;
    return (
      <CustomListCreatorItem
        key={key}
        creator={item.node}
        customListId={customListId}
        customListName={customListName}
        isBlockedView={isBlockedView}
        organizationId={organizationId}
        showUnlockCreatorBanner={handleUnlockCreatorClick}
      />
    );
  });

  return (
    <>
      <div className={styles.listHeader}>
        <Text
          type="md"
          className={styles.listHeaderText}
          msg="creators.dashboard.lists.header.username"
        />
        <Text
          type="md"
          className={styles.listHeaderText}
          msg="creators.dashboard.lists.header.categories"
        />
        <Text
          type="md"
          className={styles.listHeaderText}
          msg="creators.dashboard.lists.header.followers"
        />
        <Text
          type="md"
          className={styles.listHeaderText}
          msg="creators.dashboard.lists.header.er"
        />
        <Text
          type="md"
          className={classNames(styles.listHeaderText, styles.profile)}
          msg="creators.dashboard.lists.header.profile"
        />
        <div />
      </div>
      <div className={styles.list} ref={listContainer}>
        <LoaderHandler relay={relay} count={20} containerRef={listContainer}>
          {list}
        </LoaderHandler>
      </div>
      {isBlockedView && (
        <UnlockCreator
          visible={unlockVisible}
          onVisibilityChange={handleVisibilityChange}
          className={styles.unlockBanner}
          paused={isPlanPaused}
        />
      )}
    </>
  );
};

export default createPaginationContainer(
  CustomListCreators,
  {
    customList: graphql`
      fragment CustomListCreators_customList on CustomList
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 18 }
        cursor: { type: "String" }
        textQuery: { type: "String" }
      ) {
        id
        name
        creators(first: $count, after: $cursor, textQuery: $textQuery)
          @connection(key: "CustomList_creators", filters: []) {
          edges {
            node {
              ...CustomListCreatorItem_creator
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.customList.creators;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
        ...props,
      };
    },
    query: graphql`
      query CustomListCreatorsPaginationQuery(
        $id: ID!
        $count: Int!
        $cursor: String
        $textQuery: String
      ) {
        customList(id: $id) {
          ...CustomListCreators_customList
            @arguments(count: $count, cursor: $cursor, textQuery: $textQuery)
        }
      }
    `,
  }
);
