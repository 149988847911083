import React, { useContext } from 'react';
import AlterButton from 'Components/ui/AlterButton/AlterButton';

import { amplitude } from 'Helpers/amplitude';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { getCampaignOutreachBatchLink } from 'Util/links';
import { useNavigate, useParams } from 'react-router-dom';
import Icon from 'Components/ui/Icon/Icon';
import Text from 'Components/ui/Text/Text';

interface Props {
  className?: string;
  isAllowToStartOutreach: boolean;
}

const InviteOutreachCreators: React.FC<Props> = (props) => {
  const { className, isAllowToStartOutreach } = props;
  const { id: campaignId = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { openDrawer } = useContext(DrawerContext);

  const handleInviteClick = () => {
    amplitude.sendEvent<409>({
      id: '409',
      category: 'campaign',
      name: 'outreach_button_click',
      param: undefined,
    });
    navigate(getCampaignOutreachBatchLink({ campaignId }));
  };

  return (
    <div className={className}>
      <Icon name="Search-loop" size={120} />
      <Text
        type="md"
        msg={
          isAllowToStartOutreach
            ? 'campaign.outreach.invite.empty_placeholder'
            : 'campaign.outreach.invite.empty_placeholder.invalid'
        }
      />
      {isAllowToStartOutreach && (
        <AlterButton msg="campaign.outreach.invite" type="grey" onClick={handleInviteClick} />
      )}
    </div>
  );
};

export default InviteOutreachCreators;
