import { getBirthdateByAge } from 'Util/dateCreator';

export const formatExclusionData = (exclusions) => {
  return (
    exclusions?.map((item) => {
      if (item.campaignId) {
        return item;
      }
      const { campaign, advertiserProjectStages } = item;

      return {
        campaignId: campaign.id,
        advertiserProjectStages,
      };
    }) || []
  );
};

export const createParamsData = (params, creatorIdsForExclusion = []) => {
  const creatorType =
    params.creatorTypes && params.creatorTypes.length === 1 ? params.creatorTypes[0] : null;
  let creatorTypeValue = null;

  if (creatorType === 'ALL' || creatorType === null) {
    creatorTypeValue = ['INSTAGRAM', 'TIKTOK', 'CONTENT'];
  } else if (creatorType) {
    creatorTypeValue = [creatorType];
  }
  return {
    textQuery: params.textQuery,
    interestIds: params.interests.map((a) => a.id),
    audienceEthnicityIds: params.audienceEthnicities.map((a) => a.id),
    creatorEthnicityIds: params.creatorEthnicities.map((a) => a.id),
    ageRangeIds: params.ageRanges.map((a) => a.id),
    countryIds: params.countries.map((a) => a.id),
    creatorCountryIds: params.creatorCountries.map((a) => a.id),
    cityIds: params.cities.map((a) => a.id),
    audienceCitiesIds: params.audienceCities.map((a) => a.id),
    audienceGenderId: params.audienceGender ? params.audienceGender.id : null,
    creatorGenderId: params.creatorGender ? params.creatorGender.id : null,
    creatorLanguageIds: params.creatorLanguages.map((a) => a.id) || [],
    creatorAnimalIds: params.creatorAnimals.map((a) => a.id) || [],
    creatorHairTypeIds: params.creatorHairTypes.map((a) => a.id) || [],
    creatorBodyTypeIds: params.creatorBodyTypes.map((a) => a.id) || [],
    creatorTagIds: params.creatorTags.map((a) => a.id) || [],
    categoryIds: params.categories.map((a) => a.id) || [],
    followersFrom: params.followersFrom,
    followersTo: params.followersTo,
    realFollowersFrom: params.realFollowersFrom ? params.realFollowersFrom / 100 : null,
    realFollowersTo: params.realFollowersTo ? params.realFollowersTo / 100 : null,
    postPriceFrom: params.postPriceFrom,
    postPriceTo: params.postPriceTo,
    reviewedAtFrom: params.reviewedAtFrom,
    reviewedAtTo: params.reviewedAtTo,
    campaignsCompletionsCountFrom: params.campaignsCompletionsCountFrom,
    campaignsCompletionsCountTo: params.campaignsCompletionsCountTo,
    exclusions: formatExclusionData(params.exclusions),
    excludeHiredMoreThanOnce: params.excludeHiredMoreThanOnce,
    contentCreator: params.contentCreator,
    audienceGenderPercentage: params.audienceGenderPercentage,
    audienceAgeRangePercentage: params.audienceAgeRangePercentage,
    audienceCountryPercentage: params.audienceCountryPercentage,
    audienceCityPercentage: params.audienceCityPercentage,
    audienceInterestPercentage: params.audienceInterestPercentage || null,
    owned: params.owned ? params.owned.id : null,
    active: params.active,
    paidSocialEnabled: params.paidSocialEnabled,
    includeHidden: params.includeHidden,
    acceptsBarter: params.acceptsBarter,
    excludeBadges: params.excludeBadges,
    strictlyByPrimaryCategory: params.strictlyByPrimaryCategory,
    includeAmazonStorefrontLink:
      params.includeAmazonStorefrontLink && params.includeAmazonStorefrontLink.length > 0
        ? true
        : null,
    withTiktokShop:
      params.withTiktokShopValue && params.withTiktokShopValue.length > 0 ? true : null,
    experienced: params.experienced && params.experienced.length > 0 ? true : null,
    highAverageRating:
      params.highAverageRating && params.highAverageRating.length > 0 ? true : null,
    reliable: params.reliable && params.reliable.length > 0 ? true : null,
    onlyCreatorsWorkedWith:
      params.onlyCreatorsWorkedWith && params.onlyCreatorsWorkedWith.length > 0 ? true : null,
    favorite: params.favorite && params.favorite.length > 0 ? true : null,
    employed: params.employed,
    experienced: params.experienced && params.experienced.length > 0 ? true : null,
    highAverageRating:
      params.highAverageRating && params.highAverageRating.length > 0 ? true : null,
    reliable: params.reliable && params.reliable.length > 0 ? true : null,
    creatorTypes: creatorTypeValue ? creatorTypeValue : null,
    engagementRateFrom: params.engagementRateFrom ? params.engagementRateFrom / 100 : null,
    engagementRateTo: params.engagementRateTo ? params.engagementRateTo / 100 : null,
    viewsPerPostTo: params.viewsPerPostTo || null,
    viewsPerPostFrom: params.viewsPerPostFrom || null,
    viewsMedianFrom: params.viewsMedianFrom || null,
    viewsMedianTo: params.viewsMedianTo || null,
    storiesReachFrom: params.storiesReachFrom || null,
    storiesReachTo: params.storiesReachTo || null,
    postsReachFrom: params.postsReachFrom || null,
    postsReachTo: params.postsReachTo || null,
    reelsPlaysMedianFrom: params.reelsPlaysMedianFrom || null,
    reelsPlaysMedianTo: params.reelsPlaysMedianTo || null,
    hashtags: params.hashtags,
    creatorBirthdateFrom: params.creatorBirthdateFrom
      ? getBirthdateByAge(params.creatorBirthdateFrom + 1)
      : null,
    creatorBirthdateTo: params.creatorBirthdateTo
      ? getBirthdateByAge(params.creatorBirthdateTo)
      : null,
    creatorBadgeIds: params.badges.map((a) => a.id) || [],
    creatorIdsToExclude: creatorIdsForExclusion.map((item) => item.creatorId),
    customListIds: params.customLists.map((a) => a.id) || [],
    currency: 'USD',
  };
};
