import React, { useState } from 'react';
import { createPaginationContainer, graphql, RelayPaginationProp } from 'react-relay';

import Skeleton from '../../../components/Skeleton/Skeleton';
import EmptyListMessage from '../../../components/EmptyListMessage/EmptyListMessage';

import styles from './FavoriteCreators.pcss';
import Creator from './Creator/Creator';

import UnlockCreator from 'Molecules/UnlockCreator/UnlockCreator';
import EmptySearchRequest from 'Molecules/EmptySearchRequest/EmptySearchRequest';
import LoaderHandler from 'Organisms/LoaderHandler/LoaderHandler';
import { FavoriteCreators_organization$data } from 'GraphTypes/FavoriteCreators_organization.graphql';
import Text from 'Components/ui/Text/Text';
import classNames from 'classnames';

interface Props {
  relay: RelayPaginationProp;
  organization: FavoriteCreators_organization$data;
  textQuery?: string;
  isBlockedView?: boolean;
  onParamsClear: () => void;
  isPlanPaused?: boolean;
}

const FavoriteCreators: React.FC<Props> = (props) => {
  const { organization, relay, textQuery, onParamsClear, isBlockedView, isPlanPaused } = props;
  const [unlockVisible, setUnlockVisible] = useState(false);

  const favoriteCreators = organization?.favoriteCreators?.edges;
  const organizationId = organization?.id;

  const isRequestEmpty = !textQuery;

  const handleVisibilityChange = (value: boolean) => {
    setUnlockVisible(value);
  };

  const handleUnlockCreatorClick = () => {
    setUnlockVisible(true);
  };

  if (!favoriteCreators?.length && !isRequestEmpty) {
    return <EmptySearchRequest onParamsClear={onParamsClear} />;
  }

  if (!favoriteCreators?.length) {
    return (
      <EmptyListMessage
        iconProps={{ name: 'Heart' }}
        textProps={{ msg: 'creators.dashboard.favorites.empty_list' }}
      />
    );
  }

  const list = favoriteCreators.map((item, key) => {
    if (!item?.node) return null;
    return (
      <Creator
        key={key}
        creator={item.node}
        organizationId={organizationId}
        isBlockedView={isBlockedView}
        showUnlockCreatorBanner={handleUnlockCreatorClick}
      />
    );
  });

  return (
    <>
      <div className={styles.listHeader}>
        <Text
          type="md"
          className={styles.listHeaderText}
          msg="creators.dashboard.lists.header.username"
        />
        <Text
          type="md"
          className={styles.listHeaderText}
          msg="creators.dashboard.lists.header.categories"
        />
        <Text
          type="md"
          className={styles.listHeaderText}
          msg="creators.dashboard.lists.header.followers"
        />
        <Text
          type="md"
          className={styles.listHeaderText}
          msg="creators.dashboard.lists.header.er"
        />
        <Text
          type="md"
          className={classNames(styles.listHeaderText, styles.profile)}
          msg="creators.dashboard.lists.header.profile"
        />
        <div />
      </div>
      <LoaderHandler relay={relay} count={20} offset={279} preloader={<Skeleton />}>
        <div className={styles.list}>{list}</div>
      </LoaderHandler>
      {isBlockedView && (
        <UnlockCreator
          visible={unlockVisible}
          onVisibilityChange={handleVisibilityChange}
          className={styles.unlockBanner}
          paused={isPlanPaused}
        />
      )}
    </>
  );
};

export default createPaginationContainer(
  FavoriteCreators,
  {
    organization: graphql`
      fragment FavoriteCreators_organization on Organization
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 18 }
        cursor: { type: "String" }
        textQuery: { type: "String" }
      ) {
        id
        favoriteCreators(first: $count, after: $cursor, textQuery: $textQuery)
          @connection(key: "Organization_favoriteCreators", filters: []) {
          edges {
            node {
              ...Creator_creator
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.organization.favoriteCreators;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
        ...props,
      };
    },
    query: graphql`
      query FavoriteCreatorsPaginationQuery($count: Int!, $cursor: String, $textQuery: String) {
        currentUser {
          organization {
            ...FavoriteCreators_organization
              @arguments(count: $count, cursor: $cursor, textQuery: $textQuery)
          }
        }
      }
    `,
  }
);
