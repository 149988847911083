/**
 * @generated SignedSource<<1eb417a7cd08a5dc24247efed058fb16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreatorImportPlatform = "INSTAGRAM" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type ErrorType = "ALREADY_EXIST_ERROR" | "CREATOR_ALREADY_OWNED_ERROR" | "NOT_CLAIMED" | "NO_CREATOR" | "SIGNIN_ERROR" | "SIGNUP_ERROR" | "VALIDATION_ERROR" | "%future added value";
export type ImportCreatorsToCustomListInput = {
  clientMutationId?: string | null | undefined;
  csvFilename: string;
  customListId: string;
  id?: string | null | undefined;
  platform: CreatorImportPlatform;
  rows: ReadonlyArray<CreatorImportRowInput>;
};
export type CreatorImportRowInput = {
  email: string;
  username: string;
};
export type ImportCreatorsToCustomListMutation$variables = {
  input: ImportCreatorsToCustomListInput;
};
export type ImportCreatorsToCustomListMutation$data = {
  readonly importCreatorsToCustomList: {
    readonly __typename: "ImportCreatorsToCustomListPayload";
    readonly creatorImport: {
      readonly completed: boolean;
    };
  } | {
    readonly __typename: "ValidationError";
    readonly message: string;
    readonly type: ErrorType;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type ImportCreatorsToCustomListMutation = {
  response: ImportCreatorsToCustomListMutation$data;
  variables: ImportCreatorsToCustomListMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "ValidationError",
  "abstractKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completed",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ImportCreatorsToCustomListMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "importCreatorsToCustomList",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CreatorImport",
                "kind": "LinkedField",
                "name": "creatorImport",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "ImportCreatorsToCustomListPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ImportCreatorsToCustomListMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "importCreatorsToCustomList",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CreatorImport",
                "kind": "LinkedField",
                "name": "creatorImport",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "ImportCreatorsToCustomListPayload",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "65a7f093634c92cd5fe061c745c18635",
    "id": null,
    "metadata": {},
    "name": "ImportCreatorsToCustomListMutation",
    "operationKind": "mutation",
    "text": "mutation ImportCreatorsToCustomListMutation(\n  $input: ImportCreatorsToCustomListInput!\n) {\n  importCreatorsToCustomList(input: $input) {\n    __typename\n    ... on ValidationError {\n      type\n      message\n    }\n    ... on ImportCreatorsToCustomListPayload {\n      creatorImport {\n        completed\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0af5134a7dfe32a683036d86afa9c254";

export default node;
