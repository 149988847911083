import React, { useState, useCallback, useContext, ChangeEvent, useEffect } from 'react';
import classnames from 'classnames';

import styles from './CustomListUploadCSV.pcss';

import Text from 'Components/ui/Text/Text';
import Drawer from 'Components/ui/Drawer/Drawer';
import Input from 'Components/ui/Input/Input';
import Button from 'Components/ui/Button/Button';
import ButtonPreset from 'Components/ui/ButtonPreset/ButtonPreset';
import InputPreset from 'Components/ui/InputPreset/InputPreset';
import TextPreset from 'Components/ui/TextPreset/TextPreset';
import { useCreateCustomListMutation } from 'Mutations/CreateCustomList.Mutation';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { CreateCustomListMutation$data } from 'GraphTypes/CreateCustomListMutation.graphql';
import { amplitude } from 'Helpers/amplitude';
import Textarea from 'Components/ui/Textarea/Textarea';
import ImportCsvFile, {
  CreateListImportCsvDataErrorRow,
  CreateListImportCsvDataRow,
} from 'Page/advertiser/Creators/tabs/Lists/ImportCsvFile/ImportCsvFile';
import exampleImg from 'Images/campaign/outreach_example.png';
import example2xImg from 'Images/campaign/outreach_example_2x.png';
import { useImportCreatorsToCustomListMutation } from 'Api/mutations/ImportCreatorsToCustomList.Mutation';
import CustomListUploadPlatformSelect from './CustomListUploadPlatformSelect/CustomListUploadPlatformSelect';
import { CreatorImportPlatform } from 'GraphTypes/ImportCreatorsToCustomListMutation.graphql';

interface Props {
  drawerProps?: Partial<Omit<DrawerProps, 'rootKey' | 'children'>>;
  listId?: string;
  organizationId: string;
  onCustomListSuccessfulCreated?: (response: CreateCustomListMutation$data) => void;
  onImportComplete?: (() => void | null) | undefined | null;
}

const supportedPlatformList = ['INSTAGRAM', 'TIKTOK'] as CreatorImportPlatform[];

const CustomListUploadCSVDrawer: React.FC<Props> = (props) => {
  const { drawerProps, organizationId, onCustomListSuccessfulCreated, onImportComplete, listId } =
    props;
  const { closeDrawer } = useContext(DrawerContext);
  const [inputValue, setInputValue] = useState<string>('');
  const [descriptionValue, setDescriptionValue] = useState<string>('');
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [hasDataError, setHasDataError] = useState<boolean>(false);
  const [hasFileError, setHasFileError] = useState<boolean>(false);
  const [hasPlatformError, setHasPlatformError] = useState<boolean>(false);
  const [platform, setPlatform] = useState<CreatorImportPlatform | null>(null);
  const [createCustomList] = useCreateCustomListMutation();
  const [importCreatorsToCustomList] = useImportCreatorsToCustomListMutation();

  const isFormValid = Boolean((!listId && inputValue && platform) || (listId && platform));

  const handleInputValueChange = useCallback<HandleInputValueChange>((e) => {
    const value = e.target.value;
    setInputValue(value);
    setHasError(false);
  }, []);

  const handleInputValueReset = useCallback<HandleInputValueReset>(() => {
    setInputValue('');
    setHasError(false);
  }, []);

  const handleDescriptionValueChange = useCallback((text: string) => {
    setDescriptionValue(text);
    setHasError(false);
  }, []);

  useEffect(() => {
    if (platform) {
      setHasPlatformError(false);
    }
  }, [platform]);

  const handleCompleteParse = useCallback(
    (
      rows: CreateListImportCsvDataRow[],
      rowErrors: CreateListImportCsvDataErrorRow[],
      exceptions: string[]
    ) => {
      if (isSubmitLoading) return;
      setHasDataError(false);
      setHasFileError(false);
      const customListName = inputValue.trim();
      const customListDescription = descriptionValue.trim();
      if (!customListName && !listId) {
        setHasError(true);
        setInputValue(customListName);
        return;
      }
      if (exceptions.length > 0 || rowErrors.length === rows.length) {
        setHasDataError(true);
        return;
      }
      if (!platform) {
        setHasPlatformError(true);
        return;
      }
      setIsSubmitLoading(true);
      if (listId) {
        importCreatorsToCustomList({
          variables: {
            input: {
              customListId: listId || '',
              csvFilename: customListName,
              platform,
              rows,
            },
          },
          onCompleted: (importResponse) => {
            const typename = importResponse.importCreatorsToCustomList?.__typename;
            switch (typename) {
              case 'ImportCreatorsToCustomListPayload': {
                closeDrawer('custom-list-create-via-csv');
                if (onImportComplete) {
                  setTimeout(() => {
                    onImportComplete();
                  }, 1000);
                }
                break;
              }
              default: {
                setHasFileError(true);
                break;
              }
            }
          },
        });
      } else {
        createCustomList({
          variables: {
            input: {
              id: listId,
              organizationId,
              name: customListName,
              description: customListDescription,
            },
          },
          onCompleted: (response) => {
            const typename = response.createCustomList?.__typename;
            switch (typename) {
              case 'CreateCustomListPayload': {
                const listName = response.createCustomList?.customList?.name || '';
                amplitude.sendEvent<283>({
                  id: '283',
                  category: 'creator_lists',
                  name: 'list_created',
                  param: {
                    list_name: listName,
                  },
                });
                if (response.createCustomList?.customList?.id) {
                  importCreatorsToCustomList({
                    variables: {
                      input: {
                        customListId: response.createCustomList.customList?.id || '',
                        csvFilename: listName,
                        platform,
                        rows,
                      },
                    },
                    onCompleted: (importResponse) => {
                      const typename = importResponse.importCreatorsToCustomList?.__typename;
                      switch (typename) {
                        case 'ImportCreatorsToCustomListPayload': {
                          onCustomListSuccessfulCreated?.(response);
                          setIsSubmitLoading(false);
                          closeDrawer('custom-list-create-via-csv');
                          break;
                        }
                        default: {
                          setHasFileError(true);
                          break;
                        }
                      }
                    },
                    onError: () => {
                      setIsSubmitLoading(false);
                    },
                  });
                }
                break;
              }
              default: {
                setHasFileError(true);
                break;
              }
            }
          },
          onError: () => {
            setIsSubmitLoading(false);
          },
        });
      }
    },
    [inputValue, descriptionValue, organizationId, isSubmitLoading, platform]
  );

  const handleOpenDrawer = useCallback<NonNullable<DrawerProps['onOpen']>>(() => {
    setInputValue('');
    setDescriptionValue('');
    drawerProps?.onOpen?.();
  }, [drawerProps]);

  const handleCloseDrawer = useCallback<NonNullable<DrawerProps['onClose']>>(() => {
    setHasDataError(false);
    setHasError(false);
    setHasFileError(false);
    setHasPlatformError(false);
    setPlatform(null);
  }, []);
  return (
    <Drawer
      {...drawerProps}
      removeWhenClosed
      className={classnames(styles.drawer, drawerProps?.className)}
      rootKey="custom-list-create-via-csv"
      onOpen={handleOpenDrawer}
      onClose={handleCloseDrawer}
    >
      <div className={styles.contentRoot}>
        <div>
          <TextPreset
            className={styles.titleTextPreset}
            header={
              <Text
                type="d2"
                msg={
                  listId
                    ? 'custom_list_create_modal.import_title'
                    : 'custom_list_create_modal.title'
                }
              />
            }
          />
          {listId ? null : (
            <>
              <InputPreset>
                <Input
                  bordered
                  forceLabelShow
                  name="customListName"
                  dataTest="input:customListName"
                  error={hasError}
                  value={inputValue}
                  className={styles.input}
                  labelMsg="custom_list_create_modal.input.label"
                  placeholderMsg="custom_list_create_modal.input.placeholder"
                  onChange={handleInputValueChange}
                  onResetValue={handleInputValueReset}
                />
              </InputPreset>
              <InputPreset>
                <Textarea
                  bordered
                  forceLabelShow
                  dataTest="input:customListDescription"
                  value={descriptionValue}
                  className={styles.textarea}
                  labelMsg="custom_list_create_modal.description.label"
                  handleChange={handleDescriptionValueChange}
                />
              </InputPreset>
            </>
          )}

          <CustomListUploadPlatformSelect
            platform={platform}
            supportedPlatformList={supportedPlatformList}
            setPlatform={setPlatform}
          />
          <div className={styles.requirements}>
            <Text type="md" msg="invite_outreach_creators_modal.csv_requirements" />
            <img srcSet={`${exampleImg} 1x, ${example2xImg} 2x`} className={styles.example} />
          </div>
        </div>
        <div>
          {hasFileError && (
            <Text
              className={styles.errorTextPreset}
              type="md"
              msg="custom_list_create_modal.file_error"
            />
          )}
          {hasDataError && (
            <Text
              className={styles.errorTextPreset}
              type="md"
              msg="custom_list_create_modal.data_error"
            />
          )}
          {hasPlatformError && (
            <Text
              className={styles.errorTextPreset}
              type="md"
              msg="custom_list_create_modal.platform_error"
            />
          )}
          <ButtonPreset className={styles.submitButtonWrapper}>
            <ImportCsvFile disabled={!isFormValid} onCompleted={handleCompleteParse}>
              <Button
                fluid
                color="black"
                dataTest="button:createCustomList"
                className={styles.submitButton}
                disabled={!isFormValid}
                loading={isSubmitLoading}
                msg="custom_list_create_modal.upload_csv_button"
              />
            </ImportCsvFile>
          </ButtonPreset>
        </div>
      </div>
    </Drawer>
  );
};

export default CustomListUploadCSVDrawer;
export { CustomListUploadCSVDrawer };

// types

export type { Props as CustomListCreateDrawerProps };

type DrawerProps = React.ComponentProps<typeof Drawer>;
type InputProps = React.ComponentProps<typeof Input>;
type HandleInputValueChange = NonNullable<InputProps['onChange']>;
type HandleInputValueReset = NonNullable<InputProps['onResetValue']>;
