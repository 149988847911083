/**
 * @generated SignedSource<<1b58706b3720d0fed64a0a1b6833ee93>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Notifications_MarkInAppNotificationsAsReadInput = {
  clientMutationId?: string | null | undefined;
  ids: ReadonlyArray<string>;
};
export type MarkInAppNotificationsAsReadMutation$variables = {
  input: Notifications_MarkInAppNotificationsAsReadInput;
};
export type MarkInAppNotificationsAsReadMutation$data = {
  readonly notifications: {
    readonly markInAppNotificationsAsRead: {
      readonly clientMutationId: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type MarkInAppNotificationsAsReadMutation = {
  response: MarkInAppNotificationsAsReadMutation$data;
  variables: MarkInAppNotificationsAsReadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Notifications_Mutation",
    "kind": "LinkedField",
    "name": "notifications",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "Notifications_MarkInAppNotificationsAsReadPayload",
        "kind": "LinkedField",
        "name": "markInAppNotificationsAsRead",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientMutationId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MarkInAppNotificationsAsReadMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MarkInAppNotificationsAsReadMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e7544de64bdbb973f756597bc06b3c17",
    "id": null,
    "metadata": {},
    "name": "MarkInAppNotificationsAsReadMutation",
    "operationKind": "mutation",
    "text": "mutation MarkInAppNotificationsAsReadMutation(\n  $input: Notifications_MarkInAppNotificationsAsReadInput!\n) {\n  notifications {\n    markInAppNotificationsAsRead(input: $input) {\n      clientMutationId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "37e0941a4894da35c7e6a52566c3404c";

export default node;
