import React from 'react';
import compact from 'lodash/compact';
import find from 'lodash/find';

import styles from './DiscoverySearchForm.pcss';

import ls from 'Util/localStorage';
import {
  ARRAY,
  RANGE,
  SINGLE_VALUE,
  BOOLEAN,
  RADIO,
  CHECKBOX,
  AUDIENCE_GROUP,
  CREATOR_GROUP,
  SELECT,
  AUTOCOMPLETE,
  TEXT_INPUT,
  INSTAGRAM,
  RU,
  TEXT,
  TIKTOK,
  DATE,
  USD,
  INFLUENCER,
  FACEBOOK,
  CUSTOM,
} from 'Constants/general';
import PrimaryCategoryCheckbox from 'Molecules/PrimaryCategoryCheckbox/PrimaryCategoryCheckbox';
import CityList from 'Components/CityList/CityList';
import Icon from 'Atoms/Icon/Icon';
import IconNew from 'Components/ui/Icon/Icon';
import Checkbox from 'Atoms/Checkbox/Checkbox';
import { sortCountries } from 'Util/dataFormatter.js';
import { createShortNumber } from 'Util/numberFormatter';
import { PERCENTAGE_OPTIONS } from 'Constants/data';
import badgeResponsiveImg from 'Images/icons/badge-responsive.svg';
import badgeRisingImg from 'Images/icons/badge-rising.svg';
import badgeTopRatedImg from 'Images/icons/badge-top-rated.svg';
import badgeUGCExpertImg from 'Images/icons/badge-UGC-premium.svg';

const ownedType = [
  { msg: 'search_section.form.owned', id: true },
  { msg: 'search_section.form.not_owned', id: false },
];

export const resettableParams = {
  countries: [],
  interests: [],
  ageRanges: [],
  audienceGender: null,
  followersFrom: null,
  followersTo: null,
  realFollowersFrom: null,
  realFollowersTo: null,
  contentCreator: null,
};

export const youtubeResettableParams = ({ admin }) => {
  const noAdminParams = admin ? {} : { categories: [], interests: [] };

  return {
    ...resettableParams,
    hashtags: [],
    creatorGender: null,
    audienceEthnicities: [],
    creatorEthnicities: [],
    engagementRateFrom: null,
    engagementRateTo: null,
    ...noAdminParams,
  };
};

export const tiktokResettableParams = ({ admin }) => {
  const noAdminParams = admin ? {} : { categories: [], interests: [] };

  return {
    hashtags: [],
    creatorGender: null,
    audienceCities: [],
    cities: [],
    ...noAdminParams,
  };
};

export const onlyYoutubeParams = {
  viewsPerPostTo: null,
  viewsPerPostFrom: null,
  viewsMedianFrom: null,
  viewsMedianTo: null,
};

export const defaultParams = {
  textQuery: null,
  hashtags: [],
  cities: [],
  creatorCountries: [],
  audienceCities: [],
  creatorGender: null,
  categories: [],
  creatorTypes: null,
  audienceEthnicities: [],
  creatorEthnicities: [],
  postPriceFrom: null,
  postPriceTo: null,
  engagementRateFrom: null,
  engagementRateTo: null,
  creatorBirthdateFrom: null,
  creatorBirthdateTo: null,
  reviewedAtFrom: null,
  reviewedAtTo: null,
  campaignsCompletionsCountFrom: null,
  campaignsCompletionsCountTo: null,
  excludeBadges: null,
  strictlyByPrimaryCategory: null,
  employed: null,
  badges: [],
  owned: null,
  creatorLanguages: [],
  creatorAnimals: [],
  creatorHairTypes: [],
  creatorBodyTypes: [],
  creatorTags: [],
  storiesReachFrom: null,
  storiesReachTo: null,
  postsReachFrom: null,
  postsReachTo: null,
  reelsPlaysMedianFrom: null,
  reelsPlaysMedianTo: null,
  interestIds: [],
  audienceInterestPercentage: null,
  includeAmazonStorefrontLink: [],
  withTiktokShopValue: [],
  onlyCreatorsWorkedWith: [],
  experienced: [],
  highAverageRating: [],
  reliable: [],
  favorite: [],
  customLists: [],
  ...resettableParams,
  ...onlyYoutubeParams,
};

export const aminDefaultParams = {
  contentCreator: null,
  active: null,
  paidSocialEnabled: null,
  includeHidden: null,
  acceptsBarter: null,
};

export const paramsKit = compact([
  !window.isApproval ? { type: TEXT, key: 'creatorType', group: 'creator' } : null,
  { type: ARRAY, key: 'countries', additionalKey: 'audienceCountryPercentage', group: 'audience' },
  { type: ARRAY, key: 'creatorCountries', group: 'creator' },
  { type: ARRAY, key: 'cities', group: 'creator' },
  {
    type: ARRAY,
    key: 'audienceCities',
    additionalKey: 'audienceCityPercentage',
    group: 'audience',
  },
  { type: ARRAY, key: 'creatorLanguages', group: 'creator' },
  // { type: ARRAY, key: 'interests', group: 'audience' },
  { type: ARRAY, key: 'categories', group: 'creator' },
  { type: ARRAY, key: 'ageRanges', additionalKey: 'audienceAgeRangePercentage', group: 'audience' },
  { type: ARRAY, key: 'interests', additionalKey: 'audienceInterestPercentage', group: 'audience' },
  { type: ARRAY, key: 'audienceEthnicities', group: 'audience' },
  { type: ARRAY, key: 'creatorEthnicities', group: 'creator' },
  { type: ARRAY, key: 'creatorAnimals', group: 'creator' },
  { type: ARRAY, key: 'creatorHairTypes', group: 'creator' },
  { type: ARRAY, key: 'creatorBodyTypes', group: 'creator' },
  { type: ARRAY, key: 'creatorTags', group: 'creator' },
  {
    type: SINGLE_VALUE,
    key: 'audienceGender',
    additionalKey: 'audienceGenderPercentage',
    group: 'audience',
  },
  { type: SINGLE_VALUE, key: 'creatorGender', group: 'creator' },
  { type: RANGE, key: 'followers', group: 'creator' },
  { type: RANGE, key: 'realFollowers', group: 'creator' },
  { type: ARRAY, key: 'badges' },
  { type: RANGE, key: 'postPrice', group: 'creator' },
  { type: RANGE, key: 'engagementRate', group: 'creator' },
  { type: RANGE, key: 'viewsPerPost', group: 'creator' },
  { type: RANGE, key: 'viewsMedian', group: 'creator' },
  { type: RANGE, key: 'reviewedAt', group: 'creator', format: 'date' },
  { type: RANGE, key: 'campaignsCompletionsCount', group: 'creator' },
  { type: RANGE, key: 'creatorBirthdate', group: 'creator', reverse: true },
  { type: ARRAY, key: 'includeAmazonStorefrontLink', group: 'creator' },
  { type: ARRAY, key: 'withTiktokShopValue', group: 'creator' },
  { type: ARRAY, key: 'onlyCreatorsWorkedWith', group: 'creator' },
  { type: ARRAY, key: 'experienced', group: 'creator' },
  { type: ARRAY, key: 'highAverageRating', group: 'creator' },
  { type: ARRAY, key: 'reliable', group: 'creator' },
  { type: ARRAY, key: 'favorite', group: 'creator' },
  { type: ARRAY, key: 'customLists', group: 'creator' },
  { type: BOOLEAN, key: 'excludeBadges', group: 'creator' },
  { type: BOOLEAN, key: 'employed', group: 'creator' },
  { type: BOOLEAN, key: 'contentCreator', group: 'creator' },
  { type: SINGLE_VALUE, key: 'owned', group: 'creator' },
  { type: BOOLEAN, key: 'active', group: 'creator' },
  { type: BOOLEAN, key: 'paidSocialEnabled', group: 'creator' },
  { type: BOOLEAN, key: 'includeHidden', group: 'creator' },
  { type: BOOLEAN, key: 'acceptsBarter', group: 'creator' },
  { type: RANGE, key: 'storiesReach', group: 'creator' },
  { type: RANGE, key: 'reelsPlaysMedian', group: 'creator' },
  { type: RANGE, key: 'postsReach', group: 'creator' },
]);

export const isOnlyInstagramPlatformFilterAvailable = ({ campaignPlatform, campaignType }) => {
  return new Set([INSTAGRAM, FACEBOOK]).has(campaignPlatform) && campaignType === INFLUENCER;
};

export const isOnlyTiktokPlatformFilterAvailable = ({ campaignPlatform, campaignType }) => {
  return new Set([TIKTOK]).has(campaignPlatform) && campaignType === INFLUENCER;
};

export const getCreatorTypesList = ({ currency, campaignPlatform, campaignType }) => {
  const values = compact([
    {
      id: 'All',
      value: 'ALL',
      msg: 'search_section.form.platfor.any',
      leftIcon: <Icon name="cardView" color="black" />,
    },
    { id: INSTAGRAM, value: INSTAGRAM, text: 'Instagram', leftIconName: 'instagramColored' },
    currency === USD
      ? null
      : { id: 'YOUTUBE', value: 'YOUTUBE', text: 'Youtube', leftIconName: 'youtubeColored' },
    { id: TIKTOK, value: TIKTOK, text: 'TikTok', leftIconName: 'tiktokColored' },
  ]);
  if (isOnlyInstagramPlatformFilterAvailable({ campaignPlatform, campaignType })) {
    return values.filter((item) => item.id === INSTAGRAM);
  }

  return values;
};

const getBadgesList = (badges) => {
  return [
    {
      id: find(badges, ['name', 'HIGH_POTENTIAL']).id,
      value: 'HIGH_POTENTIAL',
      msg: 'creator.badge.high_potential',
      icon: <img src={badgeRisingImg} />,
      itemClassName: styles.badgeItem,
    },
    {
      id: find(badges, ['name', 'TOP_RATED']).id,
      value: 'TOP_RATED',
      msg: 'creator.badge.top_rated',
      icon: <img src={badgeTopRatedImg} />,
      itemClassName: styles.badgeItem,
    },
    {
      id: find(badges, ['name', 'UGC_EXPERT']).id,
      value: 'UGC_EXPERT',
      msg: 'creator.badge.ugc_expert',
      icon: <img src={badgeUGCExpertImg} />,
      itemClassName: styles.badgeItem,
    },
    {
      id: find(badges, ['name', 'HIGHLY_RESPONSIVE']).id,
      value: 'HIGHLY_RESPONSIVE',
      msg: 'creator.badge.highly_responsive',
      icon: <img src={badgeResponsiveImg} />,
      itemClassName: styles.badgeItem,
    },
  ];
};

const getNum = (num) => {
  const locale = ls.get('locale');

  if (num < 1000000) {
    return locale === RU ? createShortNumber(num) : `${num / 1000}k`;
  }

  return locale === RU ? '> 1 млн' : '> 1m';
};

const viewsItems = [
  { id: 1000, value: 1000, name: getNum(1000) },
  { id: 5000, value: 5000, name: getNum(5000) },
  { id: 10000, value: 10000, name: getNum(10000) },
  { id: 50000, value: 50000, name: getNum(50000) },
  { id: 100000, value: 100000, name: getNum(100000) },
  { id: 500000, value: 500000, name: getNum(500000) },
  { id: 1000000, value: 1000000, name: getNum(1000000) },
];

export const getData = (data) => {
  const {
    admin,
    creatorTypes,
    ageRanges,
    genders,
    ethnicities,
    countries,
    interests,
    categories,
    followersTo,
    followersFrom,
    realFollowersTo,
    realFollowersFrom,
    postPriceTo,
    postPriceFrom,
    engagementRateFrom,
    engagementRateTo,
    creatorBirthdateFrom,
    creatorBirthdateTo,
    reviewedAtFrom,
    reviewedAtTo,
    campaignsCompletionsCountFrom,
    campaignsCompletionsCountTo,
    isYoutube,
    tags,
    animals,
    hairTypes,
    languages,
    bodyTypes,
    badges,
    strictlyByPrimaryCategory,
    customLists,
  } = data;

  const badgesList = getBadgesList(badges);

  const creatorType = creatorTypes && creatorTypes.length === 1 ? creatorTypes[0] : null;

  const audienceVisible = admin || creatorType;

  const countriesList = sortCountries(countries);

  const isInstagram = creatorType === INSTAGRAM;

  const languagesList = languages.map((item) => {
    return { id: item.id, name: item.name };
  });

  return compact([
    compact([
      {
        isDropdown: true,
        dropDownlabel: 'location',
        dropDownStyle: styles.geoDropdown,
        eventName: 'location',
        contents: compact([
          {
            view: 'toggle',
            group: CREATOR_GROUP,
            items: compact([
              {
                name: 'creatorCountries',
                type: SELECT,
                group: CREATOR_GROUP,
                style: { width: '280px' },
                placeholderMsg: 'search_section.form.location.country.placeholder',
              },
              {
                name: 'cities',
                type: AUTOCOMPLETE,
                group: CREATOR_GROUP,
                inputData: {
                  style: { width: '280px' },
                  placeholderMsg: 'search_section.form.location.city.placeholder',
                },
                queryRequest: <CityList />,
              },
            ]),
          },
          !isYoutube && audienceVisible
            ? {
                view: 'toggle',
                group: AUDIENCE_GROUP,
                items: compact([
                  {
                    name: 'countries',
                    type: SELECT,
                    group: AUDIENCE_GROUP,
                    style: { width: '280px' },
                    placeholderMsg: 'search_section.form.location.country.placeholder',
                    percentageItem: {
                      name: 'audienceCountryPercentage',
                      type: SELECT,
                      isSingle: true,
                      style: { marginTop: '8px' },
                      placeholderMsg: 'search_section.form.percentage.placeholder',
                      items: { audienceCountryPercentage: PERCENTAGE_OPTIONS },
                    },
                  },
                  {
                    name: 'audienceCities',
                    type: AUTOCOMPLETE,
                    group: AUDIENCE_GROUP,
                    inputData: {
                      style: { width: '280px' },
                    },
                    placeholderMsg: 'search_section.form.location.city.placeholder',
                    queryRequest: <CityList />,
                    percentageItem: {
                      name: 'audienceCityPercentage',
                      type: SELECT,
                      isSingle: true,
                      style: { marginTop: '8px' },
                      placeholderMsg: 'search_section.form.percentage.placeholder',
                      items: { audienceCityPercentage: PERCENTAGE_OPTIONS },
                    },
                  },
                ]),
              }
            : null,
        ]),
        items: { countries: countriesList, creatorCountries: countriesList },
      },
      admin
        ? {
            isDropdown: true,
            dropDownlabel: 'categories',
            dropDownStyle: styles.primaryCategoryDropDown,
            eventName: 'categories',
            contents: compact([
              {
                name: 'categories',
                type: SELECT,
                group: CREATOR_GROUP,
                placeholderMsg: 'search_section.form.categories.category.placeholder',
              },
              {
                name: 'strictlyByPrimaryCategory',
                type: CUSTOM,
                customElement: <PrimaryCategoryCheckbox />,
              },
            ]),
            items: { categories, strictlyByPrimaryCategory },
          }
        : {
            isDropdown: true,
            dropDownlabel: 'categories',
            eventName: 'categories',
            contents: compact([
              {
                name: 'categories',
                type: SELECT,
                group: CREATOR_GROUP,
                placeholderMsg: 'search_section.form.categories.category.placeholder',
              },
            ]),
            items: { categories },
          },
      isYoutube
        ? null
        : {
            isDropdown: true,
            dropDownlabel: 'gender',
            dropDownStyle: styles.genderDropdown,
            eventName: 'gender',
            contents: compact([
              { name: 'creatorGender', type: RADIO, group: CREATOR_GROUP },
              audienceVisible
                ? { name: 'audienceGender', type: RADIO, group: AUDIENCE_GROUP }
                : null,
              audienceVisible
                ? {
                    name: 'audienceGenderPercentage',
                    type: SELECT,
                    isSingle: true,
                    style: { marginTop: '8px' },
                    placeholderMsg: 'search_section.form.percentage.placeholder',
                  }
                : null,
            ]),
            items: {
              audienceGender: genders,
              creatorGender: genders,
              audienceGenderPercentage: PERCENTAGE_OPTIONS,
            },
          },
      isYoutube
        ? null
        : {
            isDropdown: true,
            dropDownlabel: 'age',
            dropDownStyle: styles.ageDropdown,
            eventName: 'age',
            contents: [
              {
                name: 'creatorBirthdateTo',
                reverse: true,
                type: TEXT_INPUT,
                group: CREATOR_GROUP,
                placeholderMsg: 'search_section.form.creator_age.from.placeholder',
              },
              {
                name: 'creatorBirthdateFrom',
                reverse: true,
                type: TEXT_INPUT,
                placeholderMsg: 'search_section.form.creator_age.to.placeholder',
              },
              {
                name: 'ageRanges',
                type: SELECT,
                group: AUDIENCE_GROUP,
                placeholderMsg: 'search_section.form.age.placeholder',
              },
              {
                name: 'audienceAgeRangePercentage',
                type: SELECT,
                isSingle: true,
                style: { marginTop: '8px' },
                placeholderMsg: 'search_section.form.percentage.placeholder',
              },
            ],
            items: {
              ageRanges,
              creatorBirthdateFrom,
              creatorBirthdateTo,
              audienceAgeRangePercentage: PERCENTAGE_OPTIONS,
            },
          },
      {
        isDropdown: true,
        dropDownlabel: 'ethnicity',
        eventName: 'ethnicity',
        contents: compact([
          {
            name: 'creatorEthnicities',
            type: CHECKBOX,
            group: CREATOR_GROUP,
            placeholderMsg: 'search_section.form.ethnicity.ethnicity.placeholder',
          },
          {
            name: 'audienceEthnicities',
            type: CHECKBOX,
            group: AUDIENCE_GROUP,
            placeholderMsg: 'search_section.form.ethnicity.ethnicity.placeholder',
          },
        ]),
        items: { audienceEthnicities: ethnicities, creatorEthnicities: ethnicities },
      },
      {
        isDropdown: true,
        dropDownlabel: 'price',
        dropDownPosition: 'left',
        eventName: 'price',
        contents: [
          {
            name: 'postPriceFrom',
            type: TEXT_INPUT,
            group: CREATOR_GROUP,
            placeholderMsg: 'search_section.form.price.from.placeholder',
          },
          {
            name: 'postPriceTo',
            type: TEXT_INPUT,
            placeholderMsg: 'search_section.form.price.to.placeholder',
          },
        ],
        items: { postPriceFrom, postPriceTo },
      },
      !isYoutube
        ? {
            isDropdown: true,
            dropDownlabel: 'er',
            dropDownPosition: 'left',
            eventName: 'er',
            contents: [
              {
                name: 'engagementRateFrom',
                decimal: true,
                type: TEXT_INPUT,
                group: CREATOR_GROUP,
                placeholderMsg: 'search_section.form.er.from.placeholder',
              },
              {
                name: 'engagementRateTo',
                decimal: true,
                type: TEXT_INPUT,
                placeholderMsg: 'search_section.form.er.to.placeholder',
              },
            ],
            items: { engagementRateFrom, engagementRateTo },
          }
        : null,
      isYoutube || creatorType === TIKTOK
        ? {
            isDropdown: true,
            dropDownlabel: 'viewsPerPost',
            eventName: 'viewsPerPost',
            contents: [
              {
                name: 'viewsPerPostFrom',
                type: SELECT,
                isSingle: true,
                group: CREATOR_GROUP,
                placeholderMsg: 'search_section.form.followers.from.placeholder',
              },
              {
                name: 'viewsPerPostTo',
                type: SELECT,
                isSingle: true,
                placeholderMsg: 'search_section.form.followers.to.placeholder',
              },
            ],
            items: { viewsPerPostFrom: viewsItems, viewsPerPostTo: viewsItems },
          }
        : null,
      audienceVisible
        ? {
            isDropdown: true,
            dropDownlabel: 'followers',
            dropDownPosition: 'left',
            eventName: 'followers',
            contents: [
              {
                name: 'followersFrom',
                type: TEXT_INPUT,
                group: CREATOR_GROUP,
                placeholderMsg: 'search_section.form.followers.from.placeholder',
              },
              {
                name: 'followersTo',
                type: TEXT_INPUT,
                placeholderMsg: 'search_section.form.followers.to.placeholder',
              },
            ],
            items: { followersFrom, followersTo },
          }
        : null,
      audienceVisible && isInstagram
        ? {
            isDropdown: true,
            dropDownlabel: 'realFollowers',
            dropDownPosition: 'left',
            eventName: 'realFollowers',
            contents: [
              {
                name: 'realFollowersFrom',
                type: TEXT_INPUT,
                group: CREATOR_GROUP,
                placeholderMsg: 'search_section.form.realFollowers.from.placeholder',
              },
              {
                name: 'realFollowersTo',
                type: TEXT_INPUT,
                placeholderMsg: 'search_section.form.realFollowers.to.placeholder',
              },
            ],
            items: { realFollowersFrom, realFollowersTo },
          }
        : null,
      {
        isDropdown: true,
        dropDownlabel: 'badges',
        dropDownPosition: 'right',
        eventName: 'badges',
        contents: [{ name: 'badges', type: CHECKBOX }],
        items: { badges: badgesList },
      },
    ]),
    compact([
      admin
        ? {
            isDropdown: true,
            dropDownlabel: 'language',
            dropDownPosition: 'right',
            eventName: 'creatorLanguages',
            contents: [
              {
                name: 'creatorLanguages',
                type: SELECT,
                group: CREATOR_GROUP,
                placeholderMsg: 'creator.language.placeholder',
              },
            ],
            items: { creatorLanguages: languagesList },
          }
        : null,
      creatorType === TIKTOK && admin
        ? {
            isDropdown: true,
            dropDownlabel: 'viewsMedian',
            eventName: 'viewsMedian',
            contents: [
              {
                name: 'viewsMedianFrom',
                type: SELECT,
                isSingle: true,
                group: CREATOR_GROUP,
                placeholderMsg: 'search_section.form.followers.from.placeholder',
              },
              {
                name: 'viewsMedianTo',
                type: SELECT,
                isSingle: true,
                placeholderMsg: 'search_section.form.followers.to.placeholder',
              },
            ],
            items: { viewsMedianFrom: viewsItems, viewsMedianTo: viewsItems },
          }
        : null,
      creatorType === INSTAGRAM && admin
        ? {
            isDropdown: true,
            dropDownlabel: 'postsReach',
            eventName: 'postsReach',
            contents: [
              {
                name: 'postsReachFrom',
                type: TEXT_INPUT,
                isSingle: true,
                group: CREATOR_GROUP,
                placeholderMsg: 'search_section.form.followers.from.placeholder',
              },
              {
                name: 'postsReachTo',
                type: TEXT_INPUT,
                isSingle: true,
                placeholderMsg: 'search_section.form.followers.to.placeholder',
              },
            ],
            items: { postsReachFrom: viewsItems, postsReachTo: viewsItems },
          }
        : null,
      creatorType === INSTAGRAM && admin
        ? {
            isDropdown: true,
            dropDownlabel: 'storiesReach',
            eventName: 'storiesReach',
            contents: [
              {
                name: 'storiesReachFrom',
                type: TEXT_INPUT,
                isSingle: true,
                group: CREATOR_GROUP,
                placeholderMsg: 'search_section.form.followers.from.placeholder',
              },
              {
                name: 'storiesReachTo',
                type: TEXT_INPUT,
                isSingle: true,
                placeholderMsg: 'search_section.form.followers.to.placeholder',
              },
            ],
            items: { storiesReachFrom: viewsItems, storiesReachTo: viewsItems },
          }
        : null,
      creatorType === INSTAGRAM && admin
        ? {
            isDropdown: true,
            dropDownlabel: 'reelsPlaysMedian',
            eventName: 'reelsPlaysMedian',
            contents: [
              {
                name: 'reelsPlaysMedianFrom',
                type: TEXT_INPUT,
                isSingle: true,
                group: CREATOR_GROUP,
                placeholderMsg: 'search_section.form.followers.from.placeholder',
              },
              {
                name: 'reelsPlaysMedianTo',
                type: TEXT_INPUT,
                isSingle: true,
                placeholderMsg: 'search_section.form.followers.to.placeholder',
              },
            ],
            items: { reelsPlaysMedianFrom: viewsItems, reelsPlaysMedianTo: viewsItems },
          }
        : null,
      admin
        ? {
            isDropdown: true,
            dropDownlabel: 'reviewedAt',
            dropDownPosition: 'right',
            eventName: 'reviewedAt',
            contents: [
              { name: 'reviewedAtFrom', type: DATE, group: CREATOR_GROUP },
              { name: 'reviewedAtTo', type: DATE },
            ],
            items: { reviewedAtFrom, reviewedAtTo },
          }
        : null,
      creatorType === INSTAGRAM && admin
        ? {
            isDropdown: true,
            dropDownlabel: 'interest',
            dropDownStyle: styles.ageDropdown,
            eventName: 'interest',
            contents: [
              {
                name: 'interests',
                type: SELECT,
                group: AUDIENCE_GROUP,
                placeholderMsg: 'search_section.form.age.placeholder',
              },
              {
                name: 'audienceInterestPercentage',
                type: SELECT,
                isSingle: true,
                style: { marginTop: '8px' },
                placeholderMsg: 'search_section.form.percentage.placeholder',
              },
            ],
            items: { interests, audienceInterestPercentage: PERCENTAGE_OPTIONS },
          }
        : null,
    ]),
    drodownGroup2(
      admin,
      campaignsCompletionsCountFrom,
      campaignsCompletionsCountTo,
      tags,
      hairTypes,
      animals,
      ownedType,
      bodyTypes
    ),
    drodownGroup3(customLists, admin),
  ]);
};

const drodownGroup2 = (
  admin,
  campaignsCompletionsCountFrom,
  campaignsCompletionsCountTo,
  tags,
  hairTypes,
  animals,
  ownedType,
  bodyTypes
) => {
  const amazonStorefrontList = [
    {
      id: 'STOREFRONT',
      primaryMsg: 'search_section.form.includeAmazonStorefrontLink',
      msg: 'search_section.form.include_amazon_link',
      itemClassName: styles.amazonFilter,
    },
  ];

  const list = compact([
    admin
      ? {
          isDropdown: true,
          dropDownlabel: 'sign_up',
          dropDownPosition: 'right',
          eventName: 'sign_up',
          contents: [{ name: 'owned', type: RADIO, group: CREATOR_GROUP }],
          items: { owned: ownedType },
        }
      : null,
    admin
      ? {
          isDropdown: true,
          dropDownlabel: 'body',
          dropDownPosition: 'right',
          eventName: 'body',
          contents: [{ name: 'creatorBodyTypes', type: CHECKBOX, group: CREATOR_GROUP }],
          items: { creatorBodyTypes: bodyTypes },
        }
      : null,
    admin
      ? {
          isDropdown: true,
          dropDownlabel: 'hair',
          dropDownPosition: 'right',
          eventName: 'hair',
          contents: [{ name: 'creatorHairTypes', type: CHECKBOX, group: CREATOR_GROUP }],
          items: { creatorHairTypes: hairTypes },
        }
      : null,
    admin
      ? {
          isDropdown: true,
          dropDownlabel: 'animals',
          dropDownPosition: 'right',
          eventName: 'animals',
          contents: [{ name: 'creatorAnimals', type: CHECKBOX, group: CREATOR_GROUP }],
          items: { creatorAnimals: animals },
        }
      : null,
    admin
      ? {
          isDropdown: true,
          dropDownlabel: 'campaignsCompletionsCount',
          dropDownPosition: 'right',
          eventName: 'campaignsCompletionsCount',
          contents: [
            {
              name: 'campaignsCompletionsCountFrom',
              type: TEXT_INPUT,
              group: CREATOR_GROUP,
              placeholderMsg: 'search_section.form.campaignsCompletionsCount.from.placeholder',
            },
            {
              name: 'campaignsCompletionsCountTo',
              type: TEXT_INPUT,
              placeholderMsg: 'search_section.form.campaignsCompletionsCount.to.placeholder',
            },
          ],
          items: { campaignsCompletionsCountFrom, campaignsCompletionsCountTo },
        }
      : null,
    admin
      ? {
          isDropdown: true,
          dropDownlabel: 'tags',
          dropDownPosition: 'right',
          eventName: 'tags',
          contents: [{ name: 'creatorTags', type: CHECKBOX, group: CREATOR_GROUP }],
          items: { creatorTags: tags },
        }
      : null,
  ]);

  return list.length > 0 ? list : null;
};

const drodownGroup3 = (customLists, admin) => {
  const amazonStorefrontList = [
    {
      id: 'STOREFRONT',
      primaryMsg: 'search_section.form.includeAmazonStorefrontLink',
      msg: 'search_section.form.include_amazon_link',
      itemClassName: styles.amazonFilter,
    },
  ];

  const withTiktokShopList = [
    {
      id: 'TIKTOK_SHOP',
      primaryMsg: 'search_section.form.withTiktokShop.title',
      msg: 'search_section.form.withTiktokShop.item',
      itemClassName: styles.tiktokShop,
    },
  ];

  const list = compact([
    {
      isDropdown: true,
      dropDownlabel: 'includeAmazonStorefrontLink',
      dropDownPosition: 'right',
      eventName: 'includeAmazonStorefrontLink',
      contents: [{ name: 'includeAmazonStorefrontLink', type: CHECKBOX }],
      items: { includeAmazonStorefrontLink: amazonStorefrontList },
    },
    {
      isDropdown: true,
      dropDownlabel: 'withTiktokShop.title',
      dropDownPosition: 'right',
      eventName: 'withTiktokShop',
      contents: [{ name: 'withTiktokShopValue', type: CHECKBOX }],
      items: { withTiktokShopValue: withTiktokShopList },
    },
    {
      isDropdown: true,
      dropDownlabel: 'onlyCreatorsWorkedWith',
      dropDownPosition: 'left',
      eventName: 'onlyCreatorsWorkedWith',
      contents: [{ name: 'onlyCreatorsWorkedWith', type: CHECKBOX }],
      items: {
        onlyCreatorsWorkedWith: [
          {
            id: 'ONLY_CREATORS_WORKED_WITH',
            primaryMsg: 'search_section.form.onlyCreatorsWorkedWith',
            msg: 'search_section.form.only_creators_worked_with',
            itemClassName: styles.creatorsWorkedWithFilter,
          },
        ],
      },
    },
    admin
      ? {
          isDropdown: true,
          dropDownlabel: 'experience',
          dropDownPosition: 'left',
          eventName: 'experience',
          dropDownStyle: styles.experienceContainer,
          contents: [
            { name: 'experienced', type: CHECKBOX },
            { name: 'highAverageRating', type: CHECKBOX },
            { name: 'reliable', type: CHECKBOX },
          ],
          items: {
            experienced: [
              {
                id: 'experienced',
                msg: 'search_section.form.experienced',
                itemClassName: styles.creatorsWorkedWithFilter,
              },
            ],
            highAverageRating: [
              {
                id: 'highAverageRating',
                msg: 'search_section.form.high_average_rating',
                itemClassName: styles.creatorsWorkedWithFilter,
              },
            ],
            reliable: [
              {
                id: 'reliable',
                msg: 'search_section.form.reliable',
                itemClassName: styles.creatorsWorkedWithFilter,
              },
            ],
          },
        }
      : null,
    // {
    //   isDropdown: true,
    //   dropDownlabel: 'customLists',
    //   dropDownPosition: 'left',
    //   eventName: 'customLists',
    //   dropDownStyle: styles.customListsContainer,
    //   contents: [
    //     { name: 'favorite', type: CHECKBOX },
    //     { name: 'customLists', type: CHECKBOX },
    //   ],
    //   items: {
    //     customLists: customLists,
    //     favorite: [
    //       {
    //         id: 'FAVORITE',
    //         primaryMsg: 'search_section.form.favorite',
    //         msg: 'search_section.form.favorites',
    //         icon: <IconNew name="Heart" />,
    //         iconPosition: 'right',
    //       },
    //     ],
    //   },
    // },
  ]);

  return list.length > 0 ? list : null;
};

export const createDropDownItems = (data) => {
  const { admin } = data;

  const adminItems = admin
    ? [
        {
          id: 'acceptsBarter',
          name: 'acceptsBarter',
          posibleValue: true,
          textData: {
            textData: { msg: 'search_section.form.acceptsBarter' },
            rightIcon: (
              <div className={styles.barter}>
                <Icon size="sm" color="white" name="barter" />
              </div>
            ),
          },
        },
        {
          id: 'employed',
          name: 'employed',
          posibleValue: true,
          textData: {
            textData: { msg: 'search_section.form.employed' },
          },
        },
        {
          id: 'contentCreator',
          name: 'contentCreator',
          posibleValue: true,
          textData: {
            textData: { msg: 'search_section.form.contentCreator' },
          },
        },
        {
          id: 'active',
          name: 'active',
          posibleValue: true,
          textData: { textData: { msg: 'search_section.form.active.title' } },
        },
        {
          id: 'paidSocialEnabled',
          name: 'paidSocialEnabled',
          posibleValue: true,
          textData: { textData: { msg: 'search_section.form.paidSocialEnabled' } },
        },
        {
          id: 'includeHidden',
          name: 'includeHidden',
          posibleValue: true,
          textData: { textData: { msg: 'search_section.form.includeHidden' } },
        },
      ]
    : [];

  const items = compact([
    {
      id: 'excludeBadges',
      name: 'excludeBadges',
      posibleValue: true,
      textData: {
        textData: { msg: 'search_section.form.exclude_badges' },
      },
    },
    ...adminItems,
  ]);

  return items.map((item) => {
    const { name, posibleValue, textData } = item;
    const isChecked = data[name] === posibleValue;
    const icon = <Checkbox active={!!isChecked} />;

    return { ...item, textData: { ...textData, leftIcon: icon } };
  });
};
