import React, { useContext, useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import track, { useTracking } from 'react-tracking';

import styles from './HireStage.pcss';

import { amplitude } from 'Helpers/amplitude';
import archiveProject from 'Api/mutations/ArchiveProject.Mutation';
import TopupBalanceContainer from 'Modal/advertiser/TopupBalance/TopupBalanceContainer/TopupBalanceContainer';
import MaxHiredCreatorsExceededDrawer from 'Modal/advertiser/MaxHiredCreatorsExceeded/MaxHiredCreatorsExceeded';
import launchProject from 'Mutations/LaunchProject.Mutation';
import { getOnboardingData } from 'Util/onboardingData';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { ProjectActionsQuery$data } from 'GraphTypes/ProjectActionsQuery.graphql';
import { ProjectInfoContext } from 'Page/common/Chat/Dialog/ProjectInfo/ProjectInfo.Context';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { HireStage_project$key } from 'GraphTypes/HireStage_project.graphql';
import Icon from 'Components/ui/Icon/Icon';
import { useTikTokShopAuth } from 'Hooks/useTikTokShopAuth';
import { FeatureFlagsContext } from 'Containers/FeatureFlags/FeatureFlags.Context';

interface Props {
  project: HireStage_project$key;
  currentUser?: ProjectActionsQuery$data['currentUser'];
  isAdmin?: boolean;
}

const HireStage: React.FC<Props> = (props) => {
  const { project, currentUser, isAdmin } = props;
  const { openDrawer } = useContext(DrawerContext);
  const { authorize: authorizeTiktokShop } = useTikTokShopAuth();
  const { hasFeatureFlag } = useContext(FeatureFlagsContext);

  const [isTopupVisible, setTopupVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const subscription = currentUser?.organization?.subscription;

  const canUsePaidSocial = subscription?.canUsePaidSocial;

  const {
    isMaxHiredCreatorsExceeded,
    subscription: { maxHiredCreators, planId },
  } = useContext(ProjectInfoContext);

  const tracking = useTracking();

  const data = useFragment<HireStage_project$key>(
    graphql`
      fragment HireStage_project on Project {
        id
        paidSocial
        creator {
          type
          id
          username
          ... on InstagramCreator {
            paidSocialEnabled
            user {
              profilePictureUrl
            }
          }
          ... on YoutubeCreator {
            channel {
              name
              thumbnailUrl
            }
          }
          ... on TiktokCreator {
            user {
              name
              avatar {
                secureUrl
              }
            }
          }
          profile {
            estimatedPrice
            currency
          }
          ownership {
            owner {
              contractorProfile {
                tiktokShopLinked
              }
            }
          }
        }
        price
        currency
        campaign {
          type
          useCase
          paymentType
          brief {
            ... on V2Brief {
              tiktokShop_ShopId
              tiktokShop_ProductId
            }
          }
          completion {
            id
          }
          organization {
            tiktokShopLinked
            paymentAccount {
              balance
              currency
            }
            subscription {
              transactionFee
              paused
            }
          }
        }
        archivationByAdvertiser: archivation(side: ADVERTISER) {
          id
        }
        archivationByContractor: archivation(side: CONTRACTOR) {
          id
        }
      }
    `,
    project
  );

  if (!data) return null;

  const projectId = data.id;
  const campaign = data.campaign;
  const paymentAccount = data.campaign.organization?.paymentAccount;
  const paidSocial = data.paidSocial && canUsePaidSocial;
  const creator = data.creator;
  const creatorId = creator?.id;
  const paidSocialEnabled = creator?.paidSocialEnabled;
  const currency = data.currency;
  const price = Number(data.price);
  const isPlanPaused = Boolean(data.campaign.organization?.subscription?.paused);
  const isCampaignWithTiktokShop = data.campaign.useCase === 'TIKTOK_SHOP';
  const isTTShopAuthorized = data.campaign.organization?.tiktokShopLinked;
  const isCreatorTTShopAuthorized =
    data.creator?.ownership?.owner.contractorProfile?.tiktokShopLinked;

  const balance = Number(paymentAccount?.balance);
  const needTopup = balance < price && currency;

  const { finalAmount } = getOnboardingData(price, balance, 'USD');

  const handleOnboardClick = () => {
    if (isPlanPaused) return;
    tracking.trackEvent({ event: 'onboard_click' });

    if (isMaxHiredCreatorsExceeded) {
      openDrawer('max-hired-creators-exceeded');
      return;
    }

    if (hasFeatureFlag('tiktok_shop')) {
      if (isCampaignWithTiktokShop && isCreatorTTShopAuthorized) {
        if (isTTShopAuthorized) {
          if (campaign.brief?.tiktokShop_ShopId) {
            openDrawer(`project-product-delivery-${projectId}`);
          } else {
            openDrawer('tt-shop-product-list');
          }
        } else {
          authorizeTiktokShop();
        }
        return;
      }
    }
    if (needTopup) {
      setTopupVisible(true);
    } else {
      handleHireCreator();
    }
  };

  const handleHireCreator = (callback?: any) => {
    setLoading(true);
    launchProject({ projectId }, callback || handleLaunchSuccess);
  };

  const handleTopupDone = () => {
    setTopupVisible(true);
    handleHireCreator();
  };

  const handleLaunchSuccess = () => {
    setLoading(false);
    showConfirmModal();
  };

  const handleArchiveClick = () => {
    amplitude.sendEvent<104>({
      id: '104',
      category: 'campaign',
      name: 'creator_hide',
      param: {
        creator_id: creatorId || '',
        source: 'popup-chat',
      },
    });
    archiveProject({ projectId, isAdmin });
  };

  const showConfirmModal = () => {
    openDrawer(`project-draft-deadline-${projectId}`);
  };

  const archivation = data.archivationByAdvertiser;
  const archivationByContractor = data.archivationByContractor;
  const campaignCompletion = data.campaign.completion;

  const onboardDisabled = !!(archivation || campaignCompletion || archivationByContractor);
  const psDisabled = paidSocial && !paidSocialEnabled;

  let tooltipMsg = undefined;

  if (archivation) {
    tooltipMsg = 'project.stepper.onboarding.advertiser.archived';
  } else if (campaignCompletion) {
    tooltipMsg = 'project.stepper.onboarding.advertiser.campaign_completed';
  } else if (psDisabled) {
    tooltipMsg = 'project.stepper.onboarding.advertiser.ps.failed';
  } else if (isPlanPaused) {
    tooltipMsg = 'project.stepper.onboarding.advertiser.hire_disabled_for_pause_plan';
  }

  const canHire = !(onboardDisabled || psDisabled || isPlanPaused);

  const onBoardBtnMsg = needTopup
    ? 'project.stepper.onboarding.advertiser.topup_hire'
    : 'chat.in_modal.action.hire';

  const handleTopupClose = () => {
    setTopupVisible(false);
  };

  if (isTopupVisible) {
    return (
      <>
        <TopupBalanceContainer
          amount={finalAmount}
          successCallback={handleTopupDone}
          purpose="TOP_UP_FOR_HIRING"
          className={styles.topup}
        />
        <div className={styles.backButton} onClick={handleTopupClose}>
          <Icon name="Arrow-small-left" />
        </div>
      </>
    );
  }

  return (
    <>
      <Tooltip id="onboard" tooltipMsg={tooltipMsg} delayShow={0} place="top">
        <AlterButton
          msg={onBoardBtnMsg}
          icon={canHire ? 'Add-plus' : undefined}
          loading={loading}
          disabled={psDisabled || onboardDisabled || !canHire}
          type="black"
          onClick={handleOnboardClick}
        />
      </Tooltip>
      {!archivation && (
        <AlterButton
          msg="chat.in_modal.action.hide"
          icon="Eye-hidden"
          type="grey"
          onClick={handleArchiveClick}
        />
      )}
      <MaxHiredCreatorsExceededDrawer
        attach={{
          planId,
          maxHiredCreators,
        }}
      />
    </>
  );
};

const HireStageWrap = track({ element: 'onboard' })(HireStage);

export default HireStageWrap;
