import React from 'react';
import classNames from 'classnames';

import styles from './SequenceItem.pcss';

import Text from 'Components/ui/Text/Text';
import { SequenceStep } from '../SequenceEditor';
import Icon from 'Components/ui/Icon/Icon';
import Tag from 'Components/ui/Tag/Tag';
import AlterButton from 'Components/ui/AlterButton/AlterButton';

interface Props {
  data: SequenceStep;
  onSelect: (index: number) => void;
  onRemove: (index: number) => void;
  isActive: boolean;
  index: number;
  isLastInList: boolean;
  error: boolean;
}

const SequenceItem: React.FC<Props> = (props) => {
  const { data, index, isActive, onSelect, onRemove, isLastInList, error } = props;

  const handleSelectClick = () => {
    onSelect(index);
  };

  const handleRemoveClick = () => {
    onRemove(index);
  };

  const waitWidget = (
    <div className={styles.root}>
      <div className={styles.title}>
        <div className={styles.label}>
          <Icon name="Time-clock" />
        </div>
        <div className={styles.delayEditor}>
          <Text
            msg={'invite_outreach_creators_modal.sequence.delay'}
            formatValues={{ count: data.delayInDays }}
          />
        </div>
      </div>
    </div>
  );

  const sendTimeTag =
    typeof data.startsAtHour === 'object' ? (
      <Tag
        icon="Time-clock"
        needMargin={false}
        color="grey"
        text={`Send at ${data?.startsAtHour?.getHours() || '00'}:00`}
      />
    ) : null;

  return (
    <>
      {typeof data.delayInDays === 'number' && waitWidget}
      <div className={styles.root} onClick={handleSelectClick}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            <div className={styles.label}>
              <Icon name="Mail" />
            </div>
            <Text text={'Email message'} />
          </div>
          {!isLastInList && <AlterButton icon="Close-small" onClick={handleRemoveClick} />}
        </div>
        <div
          className={classNames(styles.item, { [styles.active]: isActive, [styles.error]: error })}
        >
          <div className={styles.mailInfo}>
            {data.template?.subject && (
              <Tag icon="Edit" needMargin={false} color="grey" text={data.template?.subject} />
            )}
            <Text
              type="label"
              className={styles.bodyText}
              text={data.template?.htmlText || 'Empty message'}
            />
            {sendTimeTag}
          </div>
        </div>
      </div>
    </>
  );
};

export default SequenceItem;
