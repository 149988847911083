/**
 * @generated SignedSource<<96a8b9a5e8a93aa4265c33204d67af5e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type UnviewedInAppNotificationCountUpdatedSubscription$variables = Record<PropertyKey, never>;
export type UnviewedInAppNotificationCountUpdatedSubscription$data = {
  readonly unviewedInAppNotificationCountUpdated: {
    readonly user: {
      readonly unviewedCount: {
        readonly totalCount: number;
      } | null | undefined;
    } | null | undefined;
  };
};
export type UnviewedInAppNotificationCountUpdatedSubscription = {
  response: UnviewedInAppNotificationCountUpdatedSubscription$data;
  variables: UnviewedInAppNotificationCountUpdatedSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": "unviewedCount",
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1
    },
    {
      "kind": "Literal",
      "name": "viewed",
      "value": false
    }
  ],
  "concreteType": "InAppNotificationConnection",
  "kind": "LinkedField",
  "name": "inAppNotifications",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": "inAppNotifications(first:1,viewed:false)"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UnviewedInAppNotificationCountUpdatedSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UnviewedInAppNotificationCountUpdatedPayload",
        "kind": "LinkedField",
        "name": "unviewedInAppNotificationCountUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UnviewedInAppNotificationCountUpdatedSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UnviewedInAppNotificationCountUpdatedPayload",
        "kind": "LinkedField",
        "name": "unviewedInAppNotificationCountUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bc7a917e857afd5ab4d74c46c7f2b28d",
    "id": null,
    "metadata": {},
    "name": "UnviewedInAppNotificationCountUpdatedSubscription",
    "operationKind": "subscription",
    "text": "subscription UnviewedInAppNotificationCountUpdatedSubscription {\n  unviewedInAppNotificationCountUpdated {\n    user {\n      unviewedCount: inAppNotifications(first: 1, viewed: false) {\n        totalCount\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "10e5538d7a59fa9b57216ff8f71a2bc2";

export default node;
