/**
 * @generated SignedSource<<4a089bbecb3fca8e9b9a9732db474de1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CreatorType = "CONTENT" | "INSTAGRAM" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type ProjectCreatorRatingQuery$variables = {
  creatorId: string;
};
export type ProjectCreatorRatingQuery$data = {
  readonly creator: {
    readonly id: string;
    readonly perfectForAdsCampaigns: boolean;
    readonly type: CreatorType;
  } | null | undefined;
  readonly currentUser: {
    readonly organization: {
      readonly counters: {
        readonly activatedCreatorAdsCampaigns: number;
        readonly activatedUgcInstagramCampaigns: number;
        readonly activatedUgcTiktokCampaigns: number;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type ProjectCreatorRatingQuery = {
  response: ProjectCreatorRatingQuery$data;
  variables: ProjectCreatorRatingQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "creatorId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "creatorId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "perfectForAdsCampaigns",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "OrganizationCounters",
  "kind": "LinkedField",
  "name": "counters",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activatedCreatorAdsCampaigns",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activatedUgcInstagramCampaigns",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activatedUgcTiktokCampaigns",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectCreatorRatingQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "creator",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectCreatorRatingQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "creator",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "08dc03eb6873def8a2a282c446d4f289",
    "id": null,
    "metadata": {},
    "name": "ProjectCreatorRatingQuery",
    "operationKind": "query",
    "text": "query ProjectCreatorRatingQuery(\n  $creatorId: ID!\n) {\n  creator(id: $creatorId) {\n    __typename\n    id\n    type\n    perfectForAdsCampaigns\n  }\n  currentUser {\n    organization {\n      counters {\n        activatedCreatorAdsCampaigns\n        activatedUgcInstagramCampaigns\n        activatedUgcTiktokCampaigns\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "cfc2088a5a4566997d7841e1cb369cfb";

export default node;
