import React, { FC, useMemo } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Link } from 'react-router-dom';

import styles from './OutreachHeader.pcss';

import Text from 'Components/ui/Text/Text';
import { OutreachHeaderQuery as QueryType } from 'GraphTypes/OutreachHeaderQuery.graphql';

interface Props {
  campaignId: string;
  showPercentage: boolean;
}

const emptyCounters = {
  reachedCount: 0,
  openedCount: 0,
  clickedCount: 0,
  repliedCount: 0,
  appliedCount: 0,
  hiredCount: 0,
  totalCreators: 0,
};

const OutreachHeader: FC<Props> = ({ campaignId, showPercentage }) => {
  const data = useLazyLoadQuery<QueryType>(
    graphql`
      query OutreachHeaderQuery($campaignId: ID!) {
        campaign(id: $campaignId) {
          outreachBatches {
            edges {
              node {
                totalCreators: sequences {
                  totalCount
                }
                reachedCount: sequences(outreached: true) {
                  totalCount
                }
                openedCount: sequences(opened: true) {
                  totalCount
                }
                clickedCount: sequences(clicked: true) {
                  totalCount
                }
                repliedCount: sequences(replied: true) {
                  totalCount
                }
                appliedCount: sequences(applied: true) {
                  totalCount
                }
                hiredCount: sequences(hired: true) {
                  totalCount
                }
              }
            }
          }
        }
      }
    `,
    { campaignId }
  );

  if (!data.campaign) return null;

  const countersData = useMemo(() => {
    if (!data.campaign?.outreachBatches.edges) {
      return emptyCounters;
    }
    return data.campaign?.outreachBatches.edges.reduce((counters, data) => {
      console.log(data?.node?.totalCreators.totalCount, counters.totalCreators);
      return {
        reachedCount: (counters.reachedCount += data?.node?.reachedCount.totalCount || 0),
        openedCount: (counters.openedCount += data?.node?.openedCount.totalCount || 0),
        clickedCount: (counters.clickedCount += data?.node?.clickedCount.totalCount || 0),
        repliedCount: (counters.repliedCount += data?.node?.repliedCount.totalCount || 0),
        appliedCount: (counters.appliedCount += data?.node?.appliedCount.totalCount || 0),
        hiredCount: (counters.hiredCount += data?.node?.hiredCount.totalCount || 0),
        totalCreators: (counters.totalCreators += data?.node?.totalCreators.totalCount || 0),
      };
    }, emptyCounters);
  }, [data.campaign.outreachBatches.edges?.length]);

  const {
    hiredCount,
    appliedCount,
    reachedCount,
    openedCount,
    clickedCount,
    repliedCount,
    totalCreators,
  } = countersData;

  const calcMetricValue = (value: number | undefined) => {
    if (value === undefined) {
      return showPercentage ? '0%' : '0';
    }
    return showPercentage ? ((value / totalCreators) * 100).toFixed(2) + '%' : value;
  };

  return (
    <div className={styles.root}>
      <div className={styles.titleContainer}>
        <Text type="md" text={totalCreators} />
        <Text msg="campaign.outreach.column_title.all" type="label" className={styles.counter} />
      </div>
      <div className={styles.titleContainer}>
        <Text type="md" text={`${showPercentage ? calcMetricValue(reachedCount) : reachedCount}`} />
        <Text
          msg="campaign.outreach.column_title.reached"
          type="label"
          className={styles.counter}
        />
      </div>
      <div className={styles.titleContainer}>
        <Text type="md" text={`${showPercentage ? calcMetricValue(openedCount) : openedCount}`} />
        <Text msg="campaign.outreach.column_title.opened" type="label" className={styles.counter} />
      </div>
      <div className={styles.titleContainer}>
        <Text type="md" text={`${showPercentage ? calcMetricValue(clickedCount) : clickedCount}`} />
        <Text
          msg="campaign.outreach.column_title.clicked"
          type="label"
          className={styles.counter}
        />
      </div>
      <div className={styles.titleContainer}>
        <Text type="md" text={`${showPercentage ? calcMetricValue(repliedCount) : repliedCount}`} />
        <Text
          msg="campaign.outreach.column_title.replied"
          type="label"
          className={styles.counter}
        />
      </div>
      <div className={styles.titleContainer}>
        {appliedCount > 0 ? (
          <Link to={`/campaigns/${campaignId}/applicants`}>
            <Text
              type="md"
              text={`${showPercentage ? calcMetricValue(appliedCount) : appliedCount}`}
              className={styles.purple}
            />
          </Link>
        ) : (
          <Text
            type="md"
            text={`${showPercentage ? calcMetricValue(appliedCount) : appliedCount}`}
          />
        )}
        <Text
          msg="campaign.outreach.column_title.applied"
          type="label"
          className={styles.counter}
        />
      </div>
      <div className={styles.titleContainer}>
        {hiredCount > 0 ? (
          <Link to={`/campaigns/${campaignId}/creators`}>
            <Text
              type="md"
              text={`${showPercentage ? calcMetricValue(hiredCount) : hiredCount}`}
              className={styles.purple}
            />
          </Link>
        ) : (
          <Text type="md" text={`${showPercentage ? calcMetricValue(hiredCount) : hiredCount}`} />
        )}
        <Text msg="campaign.outreach.column_title.hired" type="label" className={styles.counter} />
      </div>
    </div>
  );
};

export default OutreachHeader;
