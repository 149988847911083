import React, { useEffect, Fragment, useState } from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import track from 'react-tracking';
import { useParams } from 'react-router-dom';

import styles from './Mediaplan.css';
import SearchSection from './SearchSection/SearchSection';
import MediaplanSection from './MediaplanSection/MediaplanSection';
import LeaveBanner from './LeaveBanner/LeaveBanner';

import environment from 'Api/Environment';
import UnlockCreator from 'Molecules/UnlockCreator/UnlockCreator';
import Page from 'Templates/Page/Page';
import Spinner from 'Atoms/Spinner/Spinner';
import { MediaplanQuery as QueryType } from 'GraphTypes/MediaplanQuery.graphql';
import { YOUTUBE, CONTENT_CREATION } from 'Constants/general';
import { trackTime as custifyTrackTime } from 'Util/custify';
import { amplitude } from 'Helpers/amplitude';

const MediaplanQuery = graphql`
  query MediaplanQuery($campaignId: ID!) {
    campaign(id: $campaignId) {
      id
      name
      type
      platform
      briefSubmission {
        id
      }
      searchQuery {
        includeAmazonStorefrontLink
        favorite
        onlyCreatorsWorkedWith
        withTiktokShop
        creatorCountries {
          id
          name
        }
        experienced
        highAverageRating
        reliable
        audienceCities {
          id
          name
        }
        countries {
          id
          name
        }
        cities {
          name
          id
        }
        audienceCountryPercentage
        customLists {
          id
          name
        }
        creatorAnimals {
          id
          name
        }
        ageRanges {
          id
          name
        }
        audienceAgeRangePercentage
        audienceCities {
          id
          name
        }
        categories {
          id
          name
        }
        creatorCountries {
          id
          name
        }
        creatorGender {
          id
          name
        }
        audienceCityPercentage
        audienceInterestPercentage
        audienceCountryPercentage
        audienceEthnicities {
          id
          name
        }
        creatorEthnicities {
          id
          name
        }
        postPriceFrom
        postPriceTo
        engagementRateFrom
        engagementRateTo
        followersFrom
        followersTo
        realFollowersFrom
        realFollowersTo
        creatorLanguages {
          id
          name
        }
        postsReachFrom
        postsReachTo
        storiesReachFrom
        storiesReachTo
        reelsPlaysMedianFrom
        reelsPlaysMedianTo
        reviewedAtFrom
        reviewedAtTo
        audienceInterestPercentage
        interests {
          id
          name
        }
        creatorBodyTypes {
          id
          name
        }
        creatorHairTypes {
          id
          name
        }
        campaignsCompletionsCountFrom
        campaignsCompletionsCountTo
        tags {
          name
          id
        }
        audienceGender {
          id
          name
        }
        audienceGenderPercentage
      }
      organization {
        newClient
        subscription {
          paused
        }
      }
    }
    currentUser {
      admin
      organization {
        id
        currency
      }
    }
  }
`;

interface Props {
  campaignId?: string;
}

const Mediaplan: React.FC<Props> = (props) => {
  const params = useParams<{ id: string }>();

  const [leaveBannerVisible, setLeaveBannerVisible] = useState(false);
  const [unlockBannerVisible, setUnlockBannerVisible] = useState(false);

  const campaignId = params.id || props.campaignId;

  if (!campaignId) return null;

  useEffect(() => {
    // window.addEventListener('beforeunload', handleBeforeUnload);

    custifyTrackTime('Mediaplan');
    amplitude.sendEvent({
      id: '58',
      category: 'pageview',
      name: 'creator_marketplace',
    });

    return () => {
      _ctrack.stopTrackTime();
      // window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleLeaveBannerVisibleChange = (value: boolean) => {
    setLeaveBannerVisible(value);
    if (value) {
      setUnlockBannerVisible(false);
    }
  };

  const showUnlockCreatorBanner = () => {
    setUnlockBannerVisible(true);
  };

  const handleVisibiltyUnlockChange = (value: boolean) => {
    setUnlockBannerVisible(value);
  };

  return (
    <Page className={styles.root}>
      <QueryRenderer<QueryType>
        environment={environment}
        query={MediaplanQuery}
        variables={{ campaignId }}
        render={({ props: queryProps }) => {
          if (!queryProps) return <Spinner style={{ margin: '40px auto 0' }} />;

          const admin = queryProps.currentUser?.admin;
          const currency = queryProps.currentUser?.organization?.currency;
          const organizationId = queryProps.currentUser?.organization?.id;
          const newClient = queryProps.campaign?.organization?.newClient;
          const paused = queryProps.campaign?.organization?.subscription?.paused;

          const campaign = queryProps.campaign;
          const campaignType = campaign?.type;
          const hasSubmission = Boolean(campaign?.briefSubmission?.id);
          const campaignPlatform = campaign?.platform;

          const hasContentCreation = campaignType === CONTENT_CREATION;
          const isYoutube = campaignPlatform === YOUTUBE;

          return (
            <Fragment>
              <SearchSection
                admin={admin}
                campaign={campaign}
                currency={currency}
                campaignId={campaignId}
                isYoutube={isYoutube}
                organizationId={organizationId}
                hasContentCreation={hasContentCreation}
                className={styles.searchSection}
                showUnlockCreatorBanner={showUnlockCreatorBanner}
              />
              <MediaplanSection
                admin={admin}
                hasSubmission={hasSubmission}
                className={styles.mediaplanSection}
                campaignId={campaignId}
                showUnlockCreatorBanner={showUnlockCreatorBanner}
              />
              {newClient && (
                <LeaveBanner
                  visible={leaveBannerVisible}
                  onLeaveBannerShow={handleLeaveBannerVisibleChange}
                />
              )}
              {!leaveBannerVisible && (
                <UnlockCreator
                  visible={unlockBannerVisible}
                  paused={paused}
                  onVisibilityChange={handleVisibiltyUnlockChange}
                />
              )}
            </Fragment>
          );
        }}
      />
    </Page>
  );
};

export default track(
  {
    page: 'find_creators',
  },
  { dispatchOnMount: true }
)(Mediaplan);
