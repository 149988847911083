/**
 * @generated SignedSource<<26bcc0a0c7f7210ae5253f4703bd366f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Notifications_MarkAllInAppNotificationsAsReadInput = {
  clientMutationId?: string | null | undefined;
  upToTimestamp: any;
};
export type MarkAllInAppNotificationsAsReadMutation$variables = {
  input: Notifications_MarkAllInAppNotificationsAsReadInput;
};
export type MarkAllInAppNotificationsAsReadMutation$data = {
  readonly notifications: {
    readonly markAllInAppNotificationsAsRead: {
      readonly notifications: ReadonlyArray<{
        readonly read: boolean;
      }> | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type MarkAllInAppNotificationsAsReadMutation = {
  response: MarkAllInAppNotificationsAsReadMutation$data;
  variables: MarkAllInAppNotificationsAsReadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "read",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MarkAllInAppNotificationsAsReadMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Notifications_Mutation",
        "kind": "LinkedField",
        "name": "notifications",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Notifications_MarkAllInAppNotificationsAsReadPayload",
            "kind": "LinkedField",
            "name": "markAllInAppNotificationsAsRead",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "notifications",
                "plural": true,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MarkAllInAppNotificationsAsReadMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Notifications_Mutation",
        "kind": "LinkedField",
        "name": "notifications",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Notifications_MarkAllInAppNotificationsAsReadPayload",
            "kind": "LinkedField",
            "name": "markAllInAppNotificationsAsRead",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "notifications",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "940dbfa83fee6f2a549cb42db6551a59",
    "id": null,
    "metadata": {},
    "name": "MarkAllInAppNotificationsAsReadMutation",
    "operationKind": "mutation",
    "text": "mutation MarkAllInAppNotificationsAsReadMutation(\n  $input: Notifications_MarkAllInAppNotificationsAsReadInput!\n) {\n  notifications {\n    markAllInAppNotificationsAsRead(input: $input) {\n      notifications {\n        __typename\n        read\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c62e60c94005aa4987cb4790b46fa32b";

export default node;
