import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import CampaignInfo from '../CampaignInfo/CampaignInfo';
import CampaignTabs from '../CampaignTabs/CampaignTabs';
import CampaignContent from '../CampaignContent/CampaignContent';
import InviteCreators from '../InviteCreators/InviteCreators';
import ReviewStub from '../ReviewStub/ReviewStub';

import styles from './CampaignContainer.pcss';

import MaxLicensedCreatorsExceededDrawer from 'Modal/advertiser/MaxLicensedCreatorsExceeded/MaxLicensedCreatorsExceeded';
import Toggle from 'Components/ui/Toggle/Toggle';
import { getCampaignTypeLink } from 'Util/links';
import { AWAITING_LIST, BRIEF, CREATORS, COMPLETED, OUTREACH } from 'Constants/general';
import { CampaignQuery$data } from 'GraphTypes/CampaignQuery.graphql';

interface Props {
  admin: boolean;
  email?: string;
  organizationId?: string;
  campaign: CampaignQuery$data['campaign'];
  campaignsCount?: number;
  publishingRequired?: boolean;
  defaultType?: string;
  user: CampaignQuery$data['currentUser'];
  creator: NonNullable<CampaignQuery$data['campaign']>['creator'];
}

const CampaignContainer: React.FC<Props> = (props) => {
  const {
    campaign,
    admin,
    user,
    creator,
    campaignsCount,
    publishingRequired,
    defaultType,
    email,
    organizationId,
  } = props;
  const { id: campaignId = '', type } = useParams<{ id: string; type?: string }>();
  const navigate = useNavigate();

  const defaultSection = type ? type : defaultType;

  const [isAdminView, setAdminView] = useState(admin);
  const [section, setSection] = useState(defaultSection);
  const [currentUser, setCurrentUser] = useState(user);

  const hasAcceptance = !!(campaign?.briefSubmission && campaign.briefSubmission.acceptance);
  const isCompleted = !!campaign?.completion;
  const campaignType = campaign?.type;
  const campaignPlatform = campaign?.platform;

  const planId = currentUser?.organization?.subscription?.planId;
  const maxBcaConnections =
    currentUser?.organization?.subscription?.effectiveLimits.maxBcaConnections;

  const isFirstCampaign = campaignsCount === 1;

  useEffect(() => {
    return () => {
      document.title = 'Insense';
    };
  }, []);

  useEffect(() => {
    if (!type) return;
    if (type !== section) {
      setSection(type);
    }
  }, [type]);

  useEffect(() => {
    const newCurrentUser = isAdminView ? user : creator;
    setCurrentUser(newCurrentUser);
  }, [creator]);

  const getRedirectLink = () => {
    let path = BRIEF;

    if (hasAcceptance) {
      path = CREATORS;
    } else if (currentUser?.organization?.subscription?.canBrowseCreators) {
      path = AWAITING_LIST;
    }

    return getCampaignTypeLink({ campaignId, path });
  };

  useEffect(() => {
    if (!currentUser?.organization?.subscription?.canBrowseCreators) {
      if (type === AWAITING_LIST) {
        setSection(CREATORS);
      }
    }
  }, [currentUser?.organization?.subscription?.canBrowseCreators]);

  useEffect(() => {
    const newLink = getCampaignTypeLink({ campaignId, path: section });
    navigate(newLink, { replace: true });
  }, [section]);

  if (!type) {
    const link = getRedirectLink();
    navigate(link);
    return null;
  }

  const handleAdminViewChange = () => {
    const newAdminViewValue = !isAdminView;
    setAdminView(newAdminViewValue);
    const newUserValue = newAdminViewValue ? user : creator;
    setCurrentUser(newUserValue);
  };

  const handleSectionChange = (newSection: string) => {
    setSection(newSection);
  };

  const adminView = !!currentUser?.admin;

  return (
    <div className={styles.root}>
      <Helmet>{campaign?.name && <title>{campaign.name}</title>}</Helmet>
      <CampaignInfo
        campaign={campaign}
        isAdminView={isAdminView}
        adminView={adminView}
        isAdmin={admin}
        currentUser={currentUser}
        hasAcceptance={hasAcceptance}
        className={styles.info}
      />
      <div className={styles.tabsWrap}>
        <div className={styles.tabsElements}>
          {campaignId && campaign && !campaign.completion?.id && (
            <InviteCreators
              section={section}
              campaignId={campaignId}
              campaign={campaign}
              admin={admin}
              onSectionChange={handleSectionChange}
            />
          )}
          <CampaignTabs
            section={section}
            campaignId={campaignId}
            isAdminView={adminView}
            currentUser={currentUser}
            hasAcceptance={hasAcceptance}
            isCompleted={isCompleted}
            publishingRequired={publishingRequired}
            onSectionChange={handleSectionChange}
            // className={styles.tabs}
          />
        </div>
        {admin && (
          <div className={styles.viewToggle}>
            <Toggle
              on={!isAdminView}
              id="clientView"
              tooltipMsg="campaign.client_view"
              onChange={handleAdminViewChange}
              className={styles.toggle}
            />
          </div>
        )}
      </div>

      <CampaignContent
        campaignId={campaignId}
        currentUser={currentUser}
        isAdminView={adminView}
        isCompleted={isCompleted}
        section={section}
        campaignType={campaignType}
        campaignPlatform={campaignPlatform}
        campaign={campaign}
        publishingRequired={publishingRequired}
        email={email}
        organizationId={organizationId}
      />
      {!hasAcceptance && !adminView && <ReviewStub isFirstCampaign={isFirstCampaign} />}
      {planId && (
        <MaxLicensedCreatorsExceededDrawer
          attach={{
            planId,
            maxLicensedCreators: Number(maxBcaConnections),
          }}
        />
      )}
    </div>
  );
};

export default CampaignContainer;
