import { commitMutation, graphql } from 'react-relay';
import { SelectorStoreUpdater } from 'relay-runtime/lib/store/RelayStoreTypes';
import { ConnectionHandler } from 'relay-runtime';

import {
  AddCreatorToFavoritesMutation,
  AddCreatorToFavoritesMutation$data,
  AddCreatorToFavoritesInput,
  AddCreatorToFavoritesMutation$variables,
} from 'GraphTypes/AddCreatorToFavoritesMutation.graphql';
import environment from 'Api/Environment';

const mutation = graphql`
  mutation AddCreatorToFavoritesMutation($input: AddCreatorToFavoritesInput!) {
    addCreatorToFavorites(input: $input) {
      clientMutationId
    }
  }
`;

type FnType = (data: object) => void;

export default (
  data: AddCreatorToFavoritesInput,
  resolve?: (response: AddCreatorToFavoritesMutation$data) => void,
  reject?: FnType
) => {
  const { creatorId, organizationId } = data;
  const variables: AddCreatorToFavoritesMutation$variables = {
    input: {
      ...data,
    },
  };

  const updater: SelectorStoreUpdater<AddCreatorToFavoritesMutation$data> = (store) => {
    const updateCampaignPage = async () => {
      const isCampaignPage = window.location.pathname.startsWith('/campaigns');
      if (isCampaignPage) {
        if (!organizationId) return;
        const organization = store.get(organizationId);
        if (!organization) return;
        const searchCreatorsConnection = ConnectionHandler.getConnection(
          organization,
          'SearchResults_searchCreators'
        );
        if (!searchCreatorsConnection) return;
        const edges = searchCreatorsConnection.getLinkedRecords('edges');
        if (!edges) return;
        const nodes = edges.map((edge) => edge.getLinkedRecord('node'));
        const node = nodes.find(
          (node) => node?.getLinkedRecord('creator')?.getValue('id') === creatorId
        );
        if (!node) return;
        node.setValue(true, 'favorite');
      }
    };

    const updateCreatorById = async () => {
      const creator = store.get(creatorId);
      if (creator) {
        creator.setValue(true, 'favorite');
      }
    };

    updateCampaignPage();
    updateCreatorById();
  };

  commitMutation<AddCreatorToFavoritesMutation>(environment, {
    mutation,
    variables,
    updater,
    optimisticUpdater: updater,
    onCompleted: (response, errors) => {
      if (errors && reject) {
        reject(errors);

        return;
      }
      if (response && resolve) {
        resolve(response);
      }
    },
    onError: (error) => {
      if (reject) {
        reject(error);
      }
    },
  });
};

export type {
  AddCreatorToFavoritesMutation,
  AddCreatorToFavoritesMutation$data,
  AddCreatorToFavoritesInput,
  AddCreatorToFavoritesMutation$variables,
};
