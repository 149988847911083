import { graphql, requestSubscription } from 'react-relay';

import environment from 'Api/Environment';

const subscription = graphql`
  subscription UnviewedInAppNotificationCountUpdatedSubscription {
    unviewedInAppNotificationCountUpdated {
      user {
        unviewedCount: inAppNotifications(first: 1, viewed: false) {
          totalCount
        }
      }
    }
  }
`;

export default () => {
  requestSubscription(environment, {
    subscription,
    variables: {},
  });
};
