import { SetupIntent, StripeError, PaymentIntent } from '@stripe/stripe-js';

import { Currency } from './enums';
import { ButtonType } from './common';

import { TextType } from 'Types/common';
import { ShippingCarrier } from 'GraphTypes/StartProductShipmentMutation.graphql';
import { BriefOfferReactionQuery$data } from 'GraphTypes/BriefOfferReactionQuery.graphql';
import { MediaType as ExportMediaType } from 'GraphTypes/ExportContentToFacebookMutation.graphql';
import { PreviewProrationMutation$data } from 'GraphTypes/PreviewProrationMutation.graphql';
import { ConfirmNewPlanQuery$data } from 'GraphTypes/ConfirmNewPlanQuery.graphql';
import { PlanId } from 'GraphTypes/BriefQuery.graphql';

export enum modalName {
  ADD_CREATOR_PROFILE = 'ADD_CREATOR_PROFILE',
  ADD_INSTAGRAM_PROFILE = 'ADD_INSTAGRAM_PROFILE',
  ADD_TIKTOK_PROFILE = 'ADD_TIKTOK_PROFILE',
  ACCEPT_OFFER = 'ACCEPT_OFFER',
  ACTIVATE_PS = 'ACTIVATE_PS',
  BARTER_ACCEPTS_BLACK_LIST = 'BARTER_ACCEPTS_BLACK_LIST',
  BOOK_A_DEMO_TO_SUBSCRIBE = 'BOOK_A_DEMO_TO_SUBSCRIBE',
  BRIEF_OFFER_REACTION = 'BRIEF_OFFER_REACTION',
  CAMPAIGN_FOR_NEW_DEAL = 'CAMPAIGN_FOR_NEW_DEAL',
  CHOOSE_PLAN = 'CHOOSE_PLAN',
  CONNECT_PS = 'CONNECT_PS',
  CLOSE_CAMPAIGN_REQUEST = 'CLOSE_CAMPAIGN_REQUEST',
  CREATOR_DECLINE_A_DEAL = 'CREATOR_DECLINE_A_DEAL',
  CREATOR_DISCONNECT = 'CREATOR_DISCONNECT',
  CREATOR_PROFILE_CONFIRMATION = 'CREATOR_PROFILE_CONFIRMATION',
  CREATOR_PROFILE_EDITOR = 'CREATOR_PROFILE_EDITOR',
  CREATOR_PROFILE_REVIEWING = 'CREATOR_PROFILE_REVIEWING',
  CONFIRM_NEW_PLAN = 'CONFIRM_NEW_PLAN',
  DELETE_BRIEF_CREATIVE = 'DELETE_BRIEF_CREATIVE',
  DELETE_CAMPAIGN = 'DELETE_CAMPAIGN',
  DOWNLOAD_APP = 'DOWNLOAD_APP',
  EXIT_CONFIRMATION = 'EXIT_CONFIRMATION',
  EXTRA_PAYMENT = 'EXTRA_PAYMENT',
  IMAGE_CROPPER = 'IMAGE_CROPPER',
  INSTAGRAM_BUSINESS_ACCOUNTS = 'INSTAGRAM_BUSINESS_ACCOUNTS',
  FB_ADS_MANAGER = 'FB_ADS_MANAGER',
  MEDIA = 'MEDIA',
  MESSAGE = 'message',
  ONBOARDING_VIDEO = 'ONBOARDING_VIDEO',
  POTENTIAL_PARTICIPANT = 'POTENTIAL_PARTICIPANT',
  PRODUCT_SHIPMENT_DETAILS = 'PRODUCT_SHIPMENT_DETAILS',
  PROJECT_PS_ACTIVATION_INFO = 'PROJECT_PS_ACTIVATION_INFO',
  PUBLICATION_LINK_REQUEST = 'PUBLICATION_LINK_REQUEST',
  PS_INFO = 'PS_INFO',
  REJECT_OFFER = 'REJECT_OFFER',
  RESERVED_PROFILE = 'RESERVED_PROFILE',
  START_NEW_DEAL = 'START_NEW_DEAL',
  SHOPIFY_AUTH = 'SHOPIFY_AUTH',
  SHOPIFY_PRODUCTS = 'SHOPIFY_PRODUCTS',
  SIMPLE = 'SIMPLE',
  TIKTOK_ADVERTISERS = 'TIKTOK_ADVERTISERS',
  TRANSFER_USER_TO_ORGANIZATION = 'TRANSFER_USER_TO_ORGANIZATION',
  TOP_UP_BALANCE = 'TOP_UP_BALANCE',
  WITHDRAWAL_REQUEST = 'WITHDRAWAL_REQUEST',
}

export interface AddCreatorProfileType {
  closeDisabled?: boolean;
  refetch?: () => void;
  platforms?: string[];
  needAcceptOffer?: boolean;
  listingId?: string;
}

export interface AddInstagramProfileType {
  refetch?: () => void;
  callback?: () => void;
  hasBack?: boolean;
  fullscreen?: boolean;
  username?: string;
  needAcceptOffer?: boolean;
  offerId?: string;
  closeDisabled?: boolean;
  creatorId?: string;
}

export interface AddTiktokProfileType {
  username?: string;
  needAcceptOffer?: boolean;
  offerId?: string;
  listingId?: string;
  creatorId?: string;
  refetch?: () => void;
}

export interface ActivatePsType {
  socialIdentityId: string;
  projectId: string;
}

export interface AcceptOfferType {
  projectId?: string;
  creator?: any;
  offerCover?: string;
  projectOfferId?: string;
  listingId?: string;
  creatorId?: string;
}

export interface ArchiveProjectReasonType {
  projectId: string;
  campaignId?: string;
  creatorsStages?: string;
  archivedStages?: string;
  callback?: () => void;
}

export interface BarterAcceptsBlackListType {
  campaignId: string;
  callback?: () => void;
}

export interface BriefOfferReactionType {
  projectId: string;
  offerId: string;
  currency?: string;
  isRejection?: boolean;
}

export interface CampaignLicensingType {
  campaignId?: string;
  projectId?: string;
  projectOfferId?: string;
  listingId?: string;
  token?: string;
  onCloseClick?: () => void;
}

export interface CampaignForNewDealType {
  creatorId: string;
  campaignId?: string;
}

export interface CloseCampaignRequestType {
  campaignId: string;
}

export interface ContentApprovalCreativeType {
  creativeId: string;
}

export interface ConnectPsType {
  psDays?: number;
  onlyView?: boolean;
  apply?: boolean;
  psBca?: boolean;
  username: string;
  projectId: string;
  listingId: string;
}

export interface CreatorDeclineDealType {
  projectId: string;
  autoDecline?: boolean;
}

export interface CreatorDisconnectType {
  creatorId: string;
  username: string;
  email: string;
}

export interface CreatorProfileConfirmationType {
  offerId: string;
  defaultError?: string;
  creator: NonNullable<BriefOfferReactionQuery$data['projectForOffer']>['creator'];
  needAcceptOffer?: boolean;
  refetch?: () => void;
}

export interface CreatorProfileEditorType {
  id?: string;
  className?: string;
  disableRedirect?: boolean;
  onDoneClick?: (params?: { onCloseModal?: () => void }) => void;
}

export interface CreatorProfileReviewingType {
  profileUsername?: string;
  offerCover?: string;
  isSimpleControls?: boolean;
}

export interface ConfirmNewPlanType {
  onBack?: () => void;
  successCallback?: () => void;
  paymentMethod?: { stripeId?: string; brand?: string; last4?: string };
  prorationData: PreviewProrationMutation$data;
  errorMsg?: Error | StripeError;
}

export interface DeleteBriefCreativeType {
  briefId: string;
  briefCreativeId: string;
  callback?: () => void;
}

export interface DeleteCampaignType {
  campaignId: string;
  organizationId: string;
}

export interface ExitConfirmationType {
  location: { pathname: string };
  modalCustomContent?: () => JSX.Element;
  actionCallback?: () => void;
  actionLeaveCallback?: () => void;
  titles?: {
    popupTitle?: { msg?: string; text?: string };
    buttonLeaveTitle?: { msg?: string; text?: string };
    buttonActionTitle?: { msg?: string; text?: string };
  };
}

export interface ReimbursementPaymentType {
  projectId: string;
  balance?: number;
}

export interface FbAdsManagerType {
  mediaUrls?: string[];
  fileUrl?: string;
  socialIdentityId?: string;
  type: ExportMediaType;
  independent?: boolean;
  callback: (id: string) => void;
}

export interface ImageCropperType {
  fileSrc: string;
  actionMsg: string;
  callback: (src: string) => void;
}

export interface MediaType {
  thumbnailUrl?: string;
  url?: string;
  caption?: string | null;
  videoUrl?: string;
  type?: string;
}

export interface PotentialParticipantType {
  listingId: string;
}

export interface ProductShipmentDetailsType {
  projectId: string;
  carrier?: ShippingCarrier;
  trackingNumber?: string;
}

export interface ProjectPsActivationInfoType {
  projectId: string;
}

export interface PublicationLinkRequestType {
  projectId: string;
}

export interface ReferalLinkAmazonType {
  organizationId: string;
}

export interface MaxLaunchedCampaignsExceededType {
  maxLaunchedCampaigns: number;
  planId: PlanId;
}

export interface MaxHiredCreatorsExceededType {
  maxHiredCreators: number | null;
  planId: PlanId | null;
}

export interface MaxLicensedCreatorsExceededType {
  maxLicensedCreators: number | null;
  planId: PlanId | null;
}

export interface MaxActiveBrandsExceededType {
  maxActiveBrands: number;
  planId: PlanId;
}

export interface RejectOfferType {
  offerId: string;
  projectId: string;
  isBarter?: boolean;
  profileId?: string;
  showResultModal?: boolean;
  confirmProfile?: boolean;
}

export interface ReservedProfileType {
  callback?: () => void;
  projectId: string;
}

export interface StartNewDealType {
  campaignId: string;
  creatorId: string;
  projectId?: string;
  source?: 'creators_space';
  onStartNewCampaignButtonClick?: () => void;
  onAddToCampaignButtonClick?: () => void;
}

export interface ShopifyAuthType {
  briefId: string;
  callback: () => void;
}

export interface ShopifyProductsType {
  briefId: string;
  productIds?: string[];
}

export interface SimpleType {
  iconSrc?: string;
  titleMsg?: string;
  descrMsg?: string;
  descrValues?: object;
  disclaimerMsg?: string;
  buttons?: JSX.Element[];
  buttonData?: ButtonType;
  fullSize?: boolean;
  closeDisable?: boolean;
  type?: string;
}

export interface TiktokAdvertisersType {
  mediaUrls: string[];
  type: ExportMediaType;
  independent?: boolean;
  callback: (id: string) => void;
}

export interface TransferUserToOrganizationType {
  userId: string;
}

export interface WithdrawalRequestType {
  id: string;
  setCurrentTransactionProcess: (value: boolean) => void;
}

interface onSetModalType {
  onSetModal(name: modalName.ADD_CREATOR_PROFILE, attach: AddCreatorProfileType): void;

  onSetModal(name: modalName.ADD_INSTAGRAM_PROFILE, attach: AddInstagramProfileType): void;

  onSetModal(name: modalName.ADD_TIKTOK_PROFILE, attach: AddTiktokProfileType): void;

  onSetModal(name: modalName.ACCEPT_OFFER, attach: AcceptOfferType): void;

  onSetModal(name: modalName.ACTIVATE_PS, attach: ActivatePsType): void;

  onSetModal(name: modalName.BARTER_ACCEPTS_BLACK_LIST, attach: BarterAcceptsBlackListType): void;

  onSetModal(name: modalName.BRIEF_OFFER_REACTION, attach: BriefOfferReactionType): void;

  onSetModal(name: modalName.CAMPAIGN_FOR_NEW_DEAL, attach: CampaignForNewDealType): void;

  onSetModal(name: modalName.CLOSE_CAMPAIGN_REQUEST, attach: CloseCampaignRequestType): void;

  onSetModal(name: modalName.CONNECT_PS, attach: ConnectPsType): void;

  onSetModal(name: modalName.CREATOR_DECLINE_A_DEAL, attach: CreatorDeclineDealType): void;

  onSetModal(name: modalName.CREATOR_DISCONNECT, attach: CreatorDisconnectType): void;

  onSetModal(
    name: modalName.CREATOR_PROFILE_CONFIRMATION,
    attach: CreatorProfileConfirmationType
  ): void;

  onSetModal(name: modalName.CREATOR_PROFILE_REVIEWING, attach: CreatorProfileReviewingType): void;

  onSetModal(name: modalName.CONFIRM_NEW_PLAN, attach: ConfirmNewPlanType): void;

  onSetModal(name: modalName.DELETE_BRIEF_CREATIVE, attach: DeleteBriefCreativeType): void;

  onSetModal(name: modalName.DELETE_CAMPAIGN, attach: DeleteCampaignType): void;

  onSetModal(name: modalName.DOWNLOAD_APP): void;

  onSetModal(name: modalName.EXIT_CONFIRMATION, attach: ExitConfirmationType): void;

  onSetModal(name: modalName.IMAGE_CROPPER, attach: ImageCropperType): void;

  onSetModal(name: modalName.INSTAGRAM_BUSINESS_ACCOUNTS): void;

  onSetModal(name: modalName.FB_ADS_MANAGER, attach: FbAdsManagerType): void;

  onSetModal(name: modalName.MESSAGE): void;

  onSetModal(name: modalName.MEDIA, attach: MediaType): void;

  onSetModal(name: modalName.POTENTIAL_PARTICIPANT, attach: PotentialParticipantType): void;

  onSetModal(name: modalName.PRODUCT_SHIPMENT_DETAILS, attach: ProductShipmentDetailsType): void;

  onSetModal(name: modalName.PROJECT_PS_ACTIVATION_INFO, attach: ProjectPsActivationInfoType): void;

  onSetModal(name: modalName.PS_INFO): void;

  onSetModal(name: modalName.PUBLICATION_LINK_REQUEST, attach: PublicationLinkRequestType): void;

  onSetModal(name: modalName.REJECT_OFFER, attach: RejectOfferType): void;

  onSetModal(name: modalName.RESERVED_PROFILE, attach: ReservedProfileType): void;

  onSetModal(name: modalName.SHOPIFY_AUTH, attach: ShopifyAuthType): void;

  onSetModal(name: modalName.SHOPIFY_PRODUCTS, attach: ShopifyProductsType): void;

  onSetModal(name: modalName.SIMPLE, attach: SimpleType): void;

  onSetModal(name: modalName.START_NEW_DEAL, attach: StartNewDealType): void;

  onSetModal(
    name: modalName.TRANSFER_USER_TO_ORGANIZATION,
    attach: TransferUserToOrganizationType
  ): void;

  onSetModal(name: modalName.WITHDRAWAL_REQUEST, attach: WithdrawalRequestType): void;
}

export interface ModalType {
  onSetModal: onSetModalType['onSetModal'];
  onCloseModal: () => void;
  onTogglePreloader: (value?: boolean) => void;
}
