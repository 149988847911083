import React, { FC, useContext } from 'react';
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import styles from './OutreachBatch.pcss';
import BatchStatus from './BatchStatus/BatchStatus';

import ErrorsDrawer from '../ErrorsDrawer/ErrorsDrawer';

import colors from 'Styles/vars/colors.css';
import { amplitude } from 'Helpers/amplitude';
import createDate from 'Util/dateCreator';
import Text from 'Components/ui/Text/Text';
import Spinner from 'Atoms/Spinner/Spinner';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Icon from 'Components/ui/Icon/Icon';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { OutreachBatch_outreachBatch$key } from 'GraphTypes/OutreachBatch_outreachBatch.graphql';
import { OutreachBatchQuery } from 'GraphTypes/OutreachBatchQuery.graphql';
import AddGmailAccountButton from '../../OutreachBatchesControls/AddGmailAccountButton/AddGmailAccountButton';

interface Props {
  batch?: OutreachBatch_outreachBatch$key | null;
  campaignId: string;
  showPercentage: boolean;
}

const OutreachBatch: FC<Props> = ({ batch, campaignId, showPercentage }) => {
  const { openDrawer } = useContext(DrawerContext);

  const fragment = useFragment(
    graphql`
      fragment OutreachBatch_outreachBatch on OutreachBatch {
        id
        state
        transport
        createdAt
        gmailAuthorization {
          id
          reauthenticationRequired
        }
        sequences {
          totalCount
        }
        sequenceBlueprint {
          name
          steps {
            totalCount
          }
        }
      }
    `,
    batch
  );

  if (!fragment) return null;

  const batchId = fragment.id;
  const createdAt = fragment?.createdAt;
  const totalCreators = fragment.sequences?.totalCount || 0;
  const messagesCount = fragment?.sequenceBlueprint?.steps?.totalCount;
  const transport = fragment.transport;
  const needGmailReauth = Boolean(fragment.gmailAuthorization?.reauthenticationRequired);
  const usedGmailAuthId = fragment.gmailAuthorization?.id;
  const onErrorsClick = () => openDrawer(`invite-outreach-errors-${fragment?.id}`);

  const data = useLazyLoadQuery<OutreachBatchQuery>(
    graphql`
      query OutreachBatchQuery($batchId: ID!) {
        outreachBatch(id: $batchId) {
          reachedCount: sequences(outreached: true) {
            totalCount
          }
          openedCount: sequences(opened: true) {
            totalCount
          }
          clickedCount: sequences(clicked: true) {
            totalCount
          }
          repliedCount: sequences(replied: true) {
            totalCount
          }
          appliedCount: sequences(applied: true) {
            totalCount
          }
          hiredCount: sequences(hired: true) {
            totalCount
          }
        }
      }
    `,
    { batchId: batchId || '' }
  );

  const date = createDate(createdAt);
  const time = createDate(createdAt, 'h:mm a');
  const rows = fragment.creatorImport?.rows?.edges?.map((edge) => edge?.node);
  const errors = rows
    ?.filter((row) => !!row?.error)
    .map((item) => {
      return {
        email: item?.email || '',
        error: item?.error || 'UNKNOWN_ERROR',
        username: item?.username || '',
      };
    });
  const errorsCount = errors?.length || 0;

  const calcMetricValue = (value: number | undefined) => {
    if (value === undefined || !totalCreators) {
      return showPercentage ? '0%' : '0';
    }
    return showPercentage ? Number((value / totalCreators).toFixed(2)) * 100 + '%' : value;
  };

  const hiredCount = Number(data?.outreachBatch?.hiredCount.totalCount);
  const appliedCount = Number(data?.outreachBatch?.appliedCount.totalCount);
  const repliedCount = Number(data?.outreachBatch?.repliedCount.totalCount);

  const isCompleted = fragment.state === 'ACTIVE';

  return (
    <div className={styles.root}>
      <div className={styles.dataContainer}>
        <div className={classnames(styles.title, styles.details)}>
          <Icon name="Check" />
          <div>
            <div className={styles.downloadContainer}>
              <Text type="md" text={fragment.sequenceBlueprint?.name || 'Invitations sent'} />
            </div>
            <Text
              msg="campaign.outreach.date"
              formatValues={{ date: date || '', time: time || '' }}
              type="label"
              className={styles.grey}
            />
          </div>
        </div>
        <div className={styles.names}>
          <Text
            type="md"
            msg={transport === 'GMAIL' ? 'campaign.outreach.gmail' : 'campaign.outreach.email'}
          />
          <Text
            msg="campaign.outreach.steps_count"
            formatValues={{ count: messagesCount }}
            type="label"
            className={styles.grey}
          />
        </div>
        {needGmailReauth && (
          <AddGmailAccountButton
            buttonProps={{
              msg: 'campaign.outreach.reauth',
              leftElement: <Icon name={'Warning-error'} />,
              className: styles.errors,
              icon: null,
              type: 'white',
            }}
            authIdForReauth={usedGmailAuthId}
          />
        )}
        <div className={styles.controls}>
          {errorsCount > 0 && (
            <AlterButton
              msg={'campaign.outreach.errors'}
              leftElement={<Icon name={'Warning-error'} />}
              counter={errorsCount}
              className={styles.errors}
              onClick={onErrorsClick}
            />
          )}
        </div>
      </div>
      <div className={styles.dataContainer}>
        <div className={styles.subtitleContainer}>
          <Text type="md" text={totalCreators.toString()} />
          <Text msg="campaign.outreach.column_title.all" type="label" className={styles.grey} />
        </div>
        <div className={styles.subtitleContainer}>
          <Text
            type="md"
            text={`${calcMetricValue(data?.outreachBatch?.reachedCount.totalCount)}`}
          />
          <Text msg="campaign.outreach.column_title.reached" type="label" className={styles.grey} />
        </div>
        <div className={styles.subtitleContainer}>
          <Text
            type="md"
            text={`${calcMetricValue(data?.outreachBatch?.openedCount.totalCount)}`}
          />
          <Text msg="campaign.outreach.column_title.opened" type="label" className={styles.grey} />
        </div>
        <div className={styles.subtitleContainer}>
          <Text
            type="md"
            text={`${calcMetricValue(data?.outreachBatch?.clickedCount.totalCount)}`}
          />
          <Text msg="campaign.outreach.column_title.clicked" type="label" className={styles.grey} />
        </div>
        <div className={styles.subtitleContainer}>
          {repliedCount > 0 ? (
            <Link to={`/campaigns/${campaignId}/awaiting-list`}>
              <Text
                type="md"
                text={`${showPercentage ? calcMetricValue(repliedCount) : repliedCount}`}
              />
            </Link>
          ) : (
            <Text
              type="md"
              text={`${showPercentage ? calcMetricValue(repliedCount) : repliedCount}`}
            />
          )}
          <Text msg="campaign.outreach.column_title.replied" type="label" className={styles.grey} />
        </div>
        <div className={styles.subtitleContainer}>
          {appliedCount > 0 ? (
            <Link to={`/campaigns/${campaignId}/applicants`}>
              <Text
                type="md"
                text={`${showPercentage ? calcMetricValue(appliedCount) : appliedCount}`}
                className={styles.purple}
              />
            </Link>
          ) : (
            <Text
              type="md"
              text={`${showPercentage ? calcMetricValue(appliedCount) : appliedCount}`}
            />
          )}
          <Text msg="campaign.outreach.column_title.applied" type="label" className={styles.grey} />
        </div>
        <div className={styles.subtitleContainer}>
          {hiredCount > 0 ? (
            <Link to={`/campaigns/${campaignId}/creators`}>
              <Text
                type="md"
                text={`${showPercentage ? calcMetricValue(hiredCount) : hiredCount}`}
                className={styles.purple}
              />
            </Link>
          ) : (
            <Text type="md" text={`${showPercentage ? calcMetricValue(hiredCount) : hiredCount}`} />
          )}
          <Text msg="campaign.outreach.column_title.hired" type="label" className={styles.grey} />
        </div>
      </div>
      {errors && <ErrorsDrawer batchId={batchId || 'notAnId'} errors={errors} />}
    </div>
  );
};

export default OutreachBatch;
