import React, { useContext, useEffect, useRef } from 'react';
import { graphql, PreloadedQuery, usePaginationFragment, usePreloadedQuery } from 'react-relay';

import styles from './DiscoverySearchResults.pcss';
import List from './List/List';
import AdminAdditionalControls from './AdminAdditionalControls/AdminAdditionalControls';

import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { amplitude } from 'Helpers/amplitude';
import { DiscoverySearchResults_organization$key } from 'GraphTypes/DiscoverySearchResults_organization.graphql';
import { DiscoverySearchResultsContainerQuery } from './DiscoverySearchResultsContainer';
import { DiscoverySearchResultsContainerQuery as ContainerQuery } from 'GraphTypes/DiscoverySearchResultsContainerQuery.graphql';
import { DiscoveryCreatorsContext } from 'Page/advertiser/Discovery/DiscoveryCreatorsContext';
import LoaderHandlerWithHooks from 'Organisms/LoaderHandler/NewLoaderHandler';

interface Props {
  queryReference: PreloadedQuery<ContainerQuery, Record<string, unknown>>;
  preloader: JSX.Element;
}

const COUNT = 24;
const SCROLL_OFFSET = 100;

const DiscoverySearchResults: React.FC<Props> = (props) => {
  const { queryReference, preloader } = props;

  const {
    params: searchParams,
    admin: isAdmin,
    setCounterData,
  } = useContext(DiscoveryCreatorsContext);
  const loadingList = useRef<HTMLDivElement | null>(null);

  const data = usePreloadedQuery<ContainerQuery>(
    DiscoverySearchResultsContainerQuery,
    queryReference
  );

  const currentUser = data.currentUser;
  const canUsePaidSocial = currentUser?.organization?.subscription?.canUsePaidSocial;
  const isChurned = currentUser?.organization?.churned;
  const isNewClient = currentUser?.organization?.newClient;
  const isPlanPaused = Boolean(currentUser?.organization?.subscription?.paused);

  const isBlockedView = !isAdmin && (isChurned || isNewClient || isPlanPaused);

  const fragmentData = usePaginationFragment<
    ContainerQuery,
    DiscoverySearchResults_organization$key
  >(
    graphql`
      fragment DiscoverySearchResults_organization on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 24 }
        cursor: { type: "String" }
        query: { type: "SearchQueryInput!" }
        organizationId: { type: "ID!" }
      )
      @refetchable(queryName: "DiscoverySearchResultPaginationList") {
        organization(id: $organizationId) {
          searchCreators(first: $count, after: $cursor, query: $query)
            @connection(key: "DiscoverySearchResults_searchCreators", filters: []) {
            edges {
              node {
                creator {
                  id
                }
                project {
                  id
                }
                ...DiscoverySearchResult_result
              }
            }
            totalCount
            pageInfo {
              hasNextPage
              endCursor
            }
          }
        }
      }
    `,
    data
  );

  useEffect(() => {
    setCounterData({
      loaded: fragmentData.data.organization?.searchCreators?.edges?.length || 0,
      total: fragmentData.data.organization?.searchCreators?.totalCount || 0,
    });
  }, [
    fragmentData.data.organization?.searchCreators?.edges?.length,
    fragmentData.data.organization?.searchCreators?.totalCount,
  ]);

  const creatorTypes = searchParams?.creatorTypes;
  const creatorType = creatorTypes?.length === 1 ? creatorTypes[0] : null;
  const searchCreators = fragmentData.data.organization?.searchCreators;

  const items = Array.from(searchCreators?.edges || []);

  const handleShowMoreClick = () => {
    // amplitude.sendEvent<362>({
    //   id: '362',
    //   category: 'marketplace',
    //   name: 'show_more_click',
    //   param: undefined,
    // });
  };

  return (
    <div className={styles.root} ref={loadingList} id="discoveryList">
      {/* <div className={styles.header}>
        <Suspense fallback={null}>
          <CreatorsCount />
        </Suspense>
      </div> */}
      <LoaderHandlerWithHooks
        relay={fragmentData}
        items={items}
        count={COUNT}
        offset={SCROLL_OFFSET}
        containerRef={loadingList}
      >
        <List
          searchCreators={searchCreators}
          className={styles.list}
          creatorType={creatorType}
          canUsePaidSocial={canUsePaidSocial}
          isBlockedView={isBlockedView}
        />
      </LoaderHandlerWithHooks>
      {isBlockedView && fragmentData.hasNext && (
        <div className={styles.showMore} onClick={handleShowMoreClick}>
          <AlterButton
            type="black"
            icon="Lock"
            iconPosition="left"
            msg="browse_creators.blocked.show_more"
          />
        </div>
      )}
    </div>
  );
};

export default DiscoverySearchResults;
