/**
 * @generated SignedSource<<f71acafae3f9f4cec1a76b2b033a004e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Currency = "CAD" | "RUB" | "USD" | "%future added value";
export type AdminCreativeLibraryContainerQuery$variables = Record<PropertyKey, never>;
export type AdminCreativeLibraryContainerQuery$data = {
  readonly brandCategories: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }> | null | undefined;
  readonly currentUser: {
    readonly admin: boolean;
    readonly organization: {
      readonly currency: Currency;
    } | null | undefined;
  } | null | undefined;
};
export type AdminCreativeLibraryContainerQuery = {
  response: AdminCreativeLibraryContainerQuery$data;
  variables: AdminCreativeLibraryContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "admin",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "BrandCategory",
  "kind": "LinkedField",
  "name": "brandCategories",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminCreativeLibraryContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v3/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AdminCreativeLibraryContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v3/*: any*/)
    ]
  },
  "params": {
    "cacheID": "2d7f155e58bde628fd8e8f092040d2db",
    "id": null,
    "metadata": {},
    "name": "AdminCreativeLibraryContainerQuery",
    "operationKind": "query",
    "text": "query AdminCreativeLibraryContainerQuery {\n  currentUser {\n    organization {\n      currency\n      id\n    }\n    admin\n    id\n  }\n  brandCategories {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "f85017ddbc6cb35070e9f7465384d04c";

export default node;
