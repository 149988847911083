import { graphql, useMutation } from 'react-relay';

import { MarkAllInAppNotificationsAsViewedMutation } from 'GraphTypes/MarkAllInAppNotificationsAsViewedMutation.graphql';
import type { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const MarkAllInAppNotificationsAsViewedMutation = graphql`
  mutation MarkAllInAppNotificationsAsViewedMutation(
    $input: Notifications_MarkAllInAppNotificationsAsViewedInput!
  ) {
    notifications {
      markAllInAppNotificationsAsViewed(input: $input) {
        clientMutationId
      }
    }
  }
`;

export const useMarkAllInAppNotificationsAsViewedMutation: BaseMutation<
  MarkAllInAppNotificationsAsViewedMutation
> = (config) => {
  const [markAllInAppNotificationsAsViewedQuery, loading] =
    useMutation<MarkAllInAppNotificationsAsViewedMutation>(
      MarkAllInAppNotificationsAsViewedMutation
    );

  const markAllInAppNotificationsAsViewedCaller: BaseMutationExecFn<
    MarkAllInAppNotificationsAsViewedMutation
  > = (execConfig) => {
    markAllInAppNotificationsAsViewedQuery({
      ...config,
      ...execConfig,
    });
  };
  return [markAllInAppNotificationsAsViewedCaller, loading];
};
